import { Component, OnInit } from '@angular/core';
import {GenericService} from "../services/generic/generic.service";

@Component({
  selector: 'app-history-window-changes',
  templateUrl: './history-window-changes.component.html',
  styleUrls: ['./history-window-changes.component.scss']
})
export class HistoryWindowChangesComponent implements OnInit {

  public groupedChanges = [];

  constructor(public genericService : GenericService) { }

  ngOnInit(): void {
    let changes = this.genericService.windowChanges[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']]
    this.groupedChanges = this.groupByIdChange(changes);
    console.log(this.groupedChanges);  // Aquí puedes ver el resultado agrupado en la consola.
  }

  groupByIdChange(changes: any[]): any[] {
    // Paso 1: Agrupar por id_change
    const grouped = changes.reduce((acc, change) => {
      const idChange = change.id_change;
      
      // Formatear la fecha si es necesario
      change.date_change_formated = this.formatDate(change.date_change);
      
      // Verificar si ya existe un grupo para este id_change
      if (!acc[idChange]) {
        acc[idChange] = [];
      }
      
      // Añadir el cambio al grupo correspondiente
      acc[idChange].push(change);
      
      return acc;
    }, {} as { [key: number]: any[] });
  
    // Paso 2: Convertir el objeto agrupado a un array y ordenar los cambios dentro de cada grupo por date_change
    const groupedArray = Object.keys(grouped).map(key => ({
      id_change: Number(key),
      changes: grouped[key].sort((a, b) => new Date(b.date_change).getTime() - new Date(a.date_change).getTime()) // Ordenar por date_change
    }));
  
    // Paso 3: Ordenar los grupos por id_change de mayor a menor
    groupedArray.sort((a, b) => b.id_change - a.id_change);
  
    return groupedArray;
  }
  

  formatDate(date: string): string | null {
    if (date != "" && date !== null && date !== undefined) {
      const d = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: undefined,
        hour12: false,
      };
  
      // Usar la función `toLocaleString` para formatear la fecha
      return d.toLocaleString('en-GB', options); // Formato europeo (día-mes-año)
    }
    return null;
  }
}
