import { Component, Inject, OnInit } from "@angular/core";
import { ON_OFF_ANIMATION } from "../animations/on-off.animation";
import { DialogComponent } from "../generic/components/dialog/dialog.component";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../services/auth/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageDialogComponent } from "../utils/message-dialog/message-dialog.component";
import { EndpointService } from "../services/generic/endpoint.service";
@Component({
  selector: 'swap',
  templateUrl: './swap-account.component.html',
  styleUrls: ['./swap-account.component.scss'],
  animations: [ON_OFF_ANIMATION]
})

export class SwapAccountComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private endpointService: EndpointService) { }

  ngOnInit(): void {
    // console.log(this.authService.accountsGeneric , "this.authService.accountsGenericthis.authService.accountsGeneric..")
    this.dialogRef.disableClose = true;
  }

  selectOption(i) {
    if (this.authService.accountsGeneric[i].active) {
      this.snackBar.open('Esta cuenta ya está activa', 'X', {
        duration: 3000, panelClass: ['red-snackbar']
      });
    } else {
      this.dialogRef.close({ cancel: false, index: i });
    }
  }

  closePopup() {
    this.dialogRef.close({ cancel: true, reload: false });
  }

  deleteAccount(i) {
    let text = this.authService.accountsGeneric[i].nombre;
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: '¿Seguro que quieres abandonar ' + text + '?',
        labelButton1: 'No',
        labelButton2: 'Sí'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      let text = this.authService.accountsGeneric[i].nombre;

      if (this.authService.accountsGeneric[i].idInmobiliaria > 0) {
        this.endpointService.adminCountInmo(this.authService.accountsGeneric[i].userId).subscribe(data => {
          if (data['response'][0].num === 1) {
            this.areYoureSure(i, text);
          } else {
            this.byeAccount(i, false);
          }
        });
      } else {
        this.byeAccount(i, false);
      }
    });
  }

  private selectNewAccount() {
    window.location.reload();
    this.dialogRef.close({ cancel: true, reload: true });
  }

  areYoureSure(i, text) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: 'Parece que eres el único administrador ' + text + '. Si abandonas ahora, quedará como inactivo.' +
          ' ¿Seguro que quieres continuar?',
        labelButton1: 'No',
        labelButton2: 'Si'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {
        this.byeAccount(i, true);
      }
    });
  }

  byeAccount(i, inactive) {
    // TODO JOAN: Falta fer un endpoint per treure l'usuari de la marca

    if (this.authService.accountsGeneric[i].idInmobiliaria > 0) {
      this.endpointService.cancelInmoAccount(this.authService.userId, this.authService.accountsGeneric[i].idInmobiliaria, inactive).subscribe(data => {
        this.selectNewAccount();
      });
    }

    if (this.authService.accountsGeneric[i].id_grupo > 0) {
      this.endpointService.cancelGroupAccount(this.authService.userId, this.authService.accountsGeneric[i].id_grupo, inactive).subscribe(data => {
        this.selectNewAccount();
      });
    }
  }
}
