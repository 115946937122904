import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/internal/operators";
import { Values } from "../../values/values";

@Injectable()
export class EndpointClientService {
  private URL = Values.SERVER_URL_CLIENT;

  private reqPattern = Values.API_REQ_PATTERN;

  private observable = null;
  //used for caching some backend requests

  // Joan private userInfoResponse = null;

  constructor(private http: HttpClient) {}

  public insertPedidoStaticRestaurant(arrayProductos, productsPriceCounter, idEmpresa) {
    let data = JSON.stringify({arrayProductos, productsPriceCounter, idEmpresa});

    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "insert-pedido-static-restaurant";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public mergePedidosRestaurant(arrayPedidos) {
    let data = JSON.stringify({arrayPedidos});

    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "merge-pedidos-restaurant";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public getRestMesas(idCompany, idSala, from, fechaInit, horaInit, fechaFin, horaFin) {
    let data = JSON.stringify({idCompany, idSala, from, fechaInit, horaInit, fechaFin, horaFin});
    let requestURL = 
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-mesas";          
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printRestTicket(idTicket) {
    let data = JSON.stringify({idTicket});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-ticket";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printComanda(idPedido) {
    let data = JSON.stringify({idPedido});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-comanda";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printComandaCancelados(idPedido) {
    let data = JSON.stringify({idPedido});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-comanda-cancelados";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printMovimiento(idMovimiento) {
    let data = JSON.stringify({idMovimiento});
    let requestURL =
    this.URL +
    "restaurant" +
    this.reqPattern +
    "get-rest-movimiento";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public openCash(idEmpresa) {
    let data = JSON.stringify({idEmpresa});
    let requestURL =
    this.URL +
    "restaurant" +
    this.reqPattern +
    "get-rest-cash-printer";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printCierreCaja(idCierre) {
    let data = JSON.stringify({idCierre});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-cierre-caja";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public printCierreCajaSnapshot(idCierreCajaSnapshot) {
    let data = JSON.stringify({idCierreCajaSnapshot});
    
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-cierre-caja-snapshot";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printPedido(idPedido) {
    let data = JSON.stringify({idPedido});
    
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "get-rest-pedido";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public insertTicketPedido(idPedido, idPaymentMethod, propina, idEmpresa, idCreador, importe_pagado, importe_ticket) {
    let data = JSON.stringify({idPedido, idPaymentMethod, propina, idEmpresa, idCreador, importe_pagado, importe_ticket});

    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "insert-ticket-pedido";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public anadirProductoDirecto(idPedido, idProducto) {
    let data = JSON.stringify({idPedido, idProducto});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "anadir-producto-directo-restaurant";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public productosEstadoCincoDirecto(idPedido) {
    let data = JSON.stringify({idPedido});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "productos-estado-cinco-directo";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public movePedidoRestaurant(pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino) {
    let data = JSON.stringify({pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino});

    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "move-pedido-restaurant";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printActLudicTicket(idTicket) {
    let data = JSON.stringify({idTicket});
    let requestURL =
      this.URL +
      "act-ludic" +
      this.reqPattern +
      "get-act-ludic-ticket";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getPropertyLocation(idProperty) {
    let body = JSON.stringify({ idProperty });
    let requestURL =
      this.URL +
      "property" +
      this.reqPattern +
      "get-property-location";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getClientLocation(idClient) {
    let body = JSON.stringify({ idClient });
    let requestURL =
      this.URL +
      "clients" +
      this.reqPattern +
      "get-client-location";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public checkOcupacionMesaReservasRestaurante(idMesa, fecha, hora, idEmpresa) {
    let data = JSON.stringify({idMesa, fecha, hora, idEmpresa});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "check-ocupacion-mesa";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public checkOcupacionUbiReservasRestaurante(idUbicacion, fecha, hora, idEmpresa) {
    let data = JSON.stringify({idUbicacion, fecha, hora, idEmpresa});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "check-ocupacion-mesa";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public duplicarEstancia(idEstancia) {
    let requestURL = 
      this.URL +
      "hotels" +
      this.reqPattern +
      "duplicate-accommodation";
    let data = JSON.stringify({ idEstancia: idEstancia });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public marcharProductosPedido(idPedido) {
    let data = JSON.stringify({idPedido});
    let requestURL =
      this.URL +
      "restaurant" +
      this.reqPattern +
      "marchar-productos-pedido-restaurant";
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public addToCheckoutRestaurant(idPedidoProducto, cantidadTotal, importeTotal, cantidadCobro) {
    let data = JSON.stringify({idPedidoProducto, cantidadTotal, importeTotal, cantidadCobro});

    let requestURL =
    this.URL +
    "restaurant" +
    this.reqPattern +
    "add-to-checkout-restaurant";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public archiveAllStatusDemandaFromViviendaCustom(idVivienda, motivoCancelacion) {
    let body = JSON.stringify({ idVivienda, motivoCancelacion });
    let requestURL = this.URL + "property" + this.reqPattern + "archive-all-demandas-from-vivienda-custom";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public publicar_portales_viviendas(
    idInmo,
    portales: any,
    propertiesPortals: any,
    action = null
  ) {
    const portals = Array.isArray(portales) ? portales : [portales];
    let body = {};
    if (action != null) {
      body = JSON.stringify({ idInmo, portals, propertiesPortals });
    } else {
      body = JSON.stringify({ idInmo, portals, propertiesPortals, action });
    }
    let requestURL =
      this.URL +
      "exporters" +
      this.reqPattern +
      "publicar-portales-vivienda";
    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public updateViviendaConfigPortalesMassive(idPortal, data) {
    let body = JSON.stringify({ idPortal, data });
    let requestURL =
      this.URL +
      "exporters" +
      this.reqPattern +
      "update-vivienda-config-portales-massive";

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getPortalsByInmoId(idInmo: number) {
    let requestURL =
      this.URL +
      "portal" +
      this.reqPattern +
      "getPortalsByInmoId";
    requestURL += "&idInmo=" + idInmo;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getViviendasInmoByPortalId(idInmo: number, idPortal: number, $idUser) {
    let body = JSON.stringify({ idInmo, idPortal, $idUser });
    let requestURL =
      this.URL +
      "portal" +
      this.reqPattern +
      "getViviendasInmoByPortalId";
    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public getInmoPortal(idInmo: number, idPortal: number) {
    let body = JSON.stringify({ idInmo, idPortal });
    let requestURL =
      this.URL +
      "portal" +
      this.reqPattern +
      "getInmoPortal";
    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public duplicarInmueble(idInmueble) {
    let requestURL = 
      this.URL +
      "property" +
      this.reqPattern +
      "duplicate-vivienda";
    let data = JSON.stringify({ id: idInmueble });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public cambiarEmpresa(id, clienteOVivienda, idEmpresa, idEmpresaOld) {
    let requestURL =
      this.URL +
      "property" +
      this.reqPattern +
      "change-company";
    let data = JSON.stringify({ id, cliente_o_vivienda: clienteOVivienda, id_empresa: idEmpresa, id_empresa_old: idEmpresaOld });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getImagesProperty(idProperty) {
    let requestURL =
      "https://movin.cloud/backend_client/index.php?resource=" +
      // this.URL +
      "property" +
      this.reqPattern +
      "get-images-property";
    let data = JSON.stringify({ idProperty });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public updateImagesProperty(images: any) {
    let body = JSON.stringify({ images });
    let requestURL =
      "https://movin.cloud/backend_client/index.php?resource=" +
      // this.URL +
      "property" +
      this.reqPattern +
      "update-images-property";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public duplicarInteres(idInteres) {
    let requestURL = 
      this.URL +
      "property" +
      this.reqPattern +
      "duplicate-matching";
    let data = JSON.stringify({ id: idInteres });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getCategories(id, accountId, accountType, isOrphan) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "get-categories";
    let body = JSON.stringify({ id, accountId, accountType, isOrphan });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public addNewCategoryType(
    calendarCategory,
    idProfile,
    accountId,
    accountType
  ) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "add-new-category-type";
    let body = JSON.stringify({
      idProfile,
      calendarCategory,
      accountId,
      accountType,
    });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateCategoryType(calendarCategory, idProfile, idType) {
    const idValue = "&idProfile=";
    const reqParam = idProfile;
    const idValue2 = "&idType=";
    const reqParam2 = idType;
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "update-category-type";
    requestURL += idValue + reqParam;
    requestURL += idValue2 + reqParam2;

    let body = JSON.stringify({ calendarCategory });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public deleteCategoryType(idProfile, idType) {
    const idValue = "&idProfile=";
    const reqParam = idProfile;
    const idValue2 = "&idType=";
    const reqParam2 = idType;
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "delete-category-type";
    requestURL += idValue + reqParam;
    requestURL += idValue2 + reqParam2;
    return this.http.post(requestURL, null).pipe(map((response) => response));
  }

  public getCalendarActivities(
    idEmpresa,
    idUser: number,
    elementsToFilter,
    dateFilter,
    showShareCalendar
  ) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "get-calendar-activities";
    requestURL += "&idEmpresa=" + idEmpresa;
    requestURL += "&idUser=" + idUser;
    const body = JSON.stringify({
      elementsToFilter,
      dateFilter,
      showShareCalendar,
    });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getCalendarActivitiesByCategories(idUser: number, category: number) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "get-calendar-activities-by-category";
    requestURL += "&idUser=" + idUser;
    requestURL += "&category=" + category;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public deleteCalendarActivity(idActivity) {
    const nameValue = "&idActivity=";
    const reqParam1 = idActivity;
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "delete-calendar-activity";
    requestURL += nameValue + reqParam1;
    return this.http.post(requestURL, null).pipe(map((response) => response));
  }

  public updateCalendarActivity(calendarEvent, idActivity) {
    const nameValue = "&idActivity=";
    const reqParam1 = idActivity;
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "update-calendar-activity";

    requestURL += nameValue + reqParam1;
    let body = JSON.stringify({ calendarEvent });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateCalendarActivityCategory(
    newActivityCategory,
    oldActivityCategory
  ) {
    const nameValue = "&newActivityCategory=";
    const reqParam1 = newActivityCategory;
    const nameValue2 = "&oldActivityCategory=";
    const reqParam2 = oldActivityCategory;
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "update-calendar-activity-category";

    requestURL += nameValue + reqParam1;
    requestURL += nameValue2 + reqParam2;
    return this.http.post(requestURL, null).pipe(map((response) => response));
  }

  public updateActivityState(activity, state) {
    let body = JSON.stringify({ idActivity: activity, state: state });
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "update-activity-state";
    console.log("test", requestURL);
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public cancelActivityState(activity, state, motivo) {
    let body = JSON.stringify({
      idActivity: activity,
      state: state,
      motivo: motivo,
    });
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "cancel-activity-state";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public postponeActivity(calendarEvent, idActivity) {
    const nameValue = "&idActivity=";
    const reqParam1 = idActivity;
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "postpone-activity";
    requestURL += nameValue + reqParam1;
    let body = JSON.stringify({ calendarEvent });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getAllGroups(idUser) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "get-all-groups";
    // requestURL += '&idInmo=' + idInmo;
    requestURL += "&idUser=" + idUser;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public insertGroup(groupInfo) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "insert-group-users";
    let body = JSON.stringify({ groupInfo });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public deleteGroup(idGroup) {
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "delete-group-users";
    requestURL += "&idGroup=" + idGroup;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public updateUserGroup(idGroup, groupInfo) {
    const body = JSON.stringify({ idGroup, groupInfo });
    const requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "update-user-group";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getPendingActivities(empresaId, userId) {
    let body = JSON.stringify({ empresaId, userId });
    let requestURL =
      this.URL +
      "activities" +
      this.reqPattern +
      "get-pending-activities";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

}

export interface ResponseInterface {
  errorCode: number;
  errorMessage: string;
  response: any;
}
