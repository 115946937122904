import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { CustomEvent} from "../../../../../activities/objects/custom-event.component";
import {Estado} from "../../agenda-custom.component";
import {MatSort} from "@angular/material/sort";
import {id} from "date-fns/locale";
import {EndpointService} from "../../../../../services/generic/endpoint.service";
import {EndpointClientService} from "../../../../../services-client/endpoint-client.service";
import {MessageDialogComponent} from "../../../../../utils/message-dialog/message-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PostponeEvent} from "../postpone-event/postpone-event";
import {MotivoCancelacion} from "../motivo-cancelacion/motivo-cancelacion";

@Component({
  selector: 'bottom-sheet-finished-events',
  templateUrl: 'bottom-sheet-finished-events.html',
  styleUrls: ['bottom-sheet-finished-events.scss']
})
export class BottomSheetFinishedEvents implements OnInit {
  displayedColumns: string[] = ['name', 'dateStart', 'dateEnd', 'state', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  // public options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minutes: 'numeric'};
  public options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'} as const;
  public inputFilter: string;
  public loaded: boolean;
  public PENDING_DATA: PendingEvents[] = [];
  estados: Estado[] = [
    {id: 1, estado: 'Abierta'},
    {id: 2, estado: 'Terminada'},
    {id: 3, estado: 'Cancelada'},
  ];

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetFinishedEvents>,
              private endpointService: EndpointService,
              private endpointClientService: EndpointClientService,
              public dialog: MatDialog,
              private _bottomSheet: MatBottomSheet,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: CustomEvent[]) {
  }

  updateDataSource(idEvent?: number) {
    const index = this.dataSource.data.findIndex(e => e.id === idEvent);
    this.dataSource.data.splice(index, 1);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilter(this.inputFilter);
  }

  ngOnInit() {
    this.loaded = false;
    this.inputFilter = '';
    let promise = new Promise<void>((resolve, reject) => {
      this.data.forEach(e => {
        let estado = this.estados.filter(est => est.id === e.estado)[0].estado;
        this.PENDING_DATA.push({id: e.id, name: e.title, dateStart: e.start.toLocaleString([], this.options), dateEnd: e.end.toLocaleString([], this.options), state: estado});
      });
      resolve();
    });

    promise.then(e => {
      this.dataSource = new MatTableDataSource<PendingEvents>(this.PENDING_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      this.loaded = true;
    });

    //EL PROBLEMA ES QUE NOOO ESTÁ DENTRO DE UN SUBSCRIBE. PROBAR CON PROMISES

  }


  onNoClick(): void {
    this._bottomSheetRef.dismiss();
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  closeBottomSheet(){
    this._bottomSheetRef.dismiss();
  }

  setEventFinished(element: any) {
    this.endpointClientService.updateActivityState(element.id, 2).subscribe(d => {
      if (d['errorCode'] === 0) {
        this.updateDataSource(element.id);
      }
    });
  }

  setEventCancelled(element: any) {
    const dialogRef = this.dialog.open(MotivoCancelacion, {
      data: element
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        if(d.state === true) {
          this.endpointClientService.cancelActivityState(element.id, 3, d.motivo).subscribe(d1 => {
            if (d1['errorCode'] === 0) {
              this.updateDataSource(element.id);
            }
          });
        }
        else {}
      }
    });

  }

  postponeEvent(element: any) {
    const dialogRef = this.dialog.open(PostponeEvent, {
      data: element
    });
    dialogRef.afterClosed().subscribe(d => {
      if(d === true) {
        this.updateDataSource(element.id);
      }
      else {
      }
    });
  }
}

export interface PendingEvents {
  id: number
  name: string;
  dateStart: string;
  dateEnd: string;
  state: string;
}
