import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import {EndpointService} from '../../services/generic/endpoint.service';
import {Strings} from '../../../strings/strings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public user: string = null;
  public type = null; //type = 1 --> regular password, pasword for the user / type = 2 --> password for matching
  public Strings = Strings.LOGIN;

  password1 = true;
  password2 = true;

  @ViewChild("checker1") ckeckerField1: ElementRef;
  @ViewChild("checker2") ckeckerField2: ElementRef;


  constructor(private endpointService: EndpointService,
              private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<SetPasswordComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.user = data.user;
    this.type = data.type;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      pass1: ['', Validators.required],
      pass2: ['', Validators.required]
//
//    'pass1': [this.setPass.password1, [
//        Validators.required,
//        Validators.minLength(9),
//        Validators.maxLength(16),
//        checkNumberValidator(/.*[0-9].*/i),
//        checkSpecialCharacter(/^[\w&.\-]+$/),
//        checkLowerCaseValidator(/[a-z]/),
//        checkUpperCaseValidator(/[A-Z]/)
/*
      ]
    ],
      'pass2': [this.resetPass.password2, [
          Validators.required,
        matchPassword(false)
      ]
    ],
*/
    });
  }

  formErrors = {
    'pass1': '',
    'pass2': ''
  };

  validationMessages = {
    'pass1': {
      'required': 'Introduce una contraseña válida. La contraseña tiene que tener al menos 9 caracteres. <br> ',
      'minlength': 'La contraseña tiene que tener al menos 9 caracteres.  <br>',
      'maxlength': 'La contraseña no puede tener más de 16 caracteres. <br>',
      'forbiddenName': 'Someone named "Bob" cannot be a hero.',
      'checkSpecialCharacter': 'Debe tener un carácter especial. <br>',
      'checkLowerCase': 'Debe tener una letra minúscula. <br>',
      'checkUpperCase': 'Debe tener una letra mayúscula . <br>',
      'checkNumber': 'Al menos debe tener un número.  <br>'
    },
    'pass2': {
      'required': 'Introduce una contraseña válida. ',
      'matchPassword': 'Las contraseñas deben ser iguales. '
    }
  };

  public submitNewPassword(): void {
    if (this.formGroup.value['pass1'] === this.formGroup.value['pass2']){
      if (this.type === 1){
        this.endpointService.setNewPassword(
          this.user, this.formGroup.value['pass1']).subscribe(data => {
            if (data['errorCode'] !== 0) {
              this.snackBar.open('¡Vaya! Parece que ha habido un problema al actualizar la contraseña','X',{
                duration: 3000, panelClass: ['red-snackbar']
              });
            }
            else {
              this.snackBar.open('La contraseña ha sido cambiada correctamente','X',{
                duration: 3000, panelClass: ['green-snackbar']
              });
              this.dialogRef.close({change_successfully: true, pass: this.formGroup.value['pass1']});
            }
          }
        )
      } else if   (this.type === 2){
        this.endpointService.updateMatchingPassword(
          this.user, this.formGroup.value['pass1']).subscribe(data => {
            if (data['errorCode'] !== 0) {
              this.snackBar.open('¡Vaya! Parece que ha habido un problema al actualizar la contraseña','X',{
                duration: 3000, panelClass: ['red-snackbar']
              });
            }
            else {
              this.snackBar.open('La contraseña ha sido cambiada correctamente','X',{
                duration: 3000, panelClass: ['green-snackbar']
              });
              this.dialogRef.close();
            }
          }
        )
      }

    } else {

      this.ckeckerField1.nativeElement.focus();
      this.ckeckerField1.nativeElement.blur();

      this.ckeckerField2.nativeElement.focus();
      this.ckeckerField2.nativeElement.blur();

      this.snackBar.open('Las constraseñas no coinciden','X',{
        duration: 2000, panelClass: ['red-snackbar']
      });
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
