import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FunctionsService } from "app/services/generic/functions.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { EndpointService } from "app/services/generic/endpoint.service";
import { EndpointClientService } from "app/services-client/endpoint-client.service";
import { FormControl, UntypedFormControl } from "@angular/forms";

import { AuthService } from "app/services/auth/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { GenericService } from "app/services/generic/generic.service";
import { FunctionsControllerService } from "app/services/generic/functions-controller.service";

@Component({
  selector: "app-events-template-custom",
  templateUrl: "./events-template-custom.component.html",
  styleUrls: ["./events-template-custom.component.css"],
})
export class EventsTemplateCustomComponent implements OnInit, OnDestroy {
  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;
  minDate: Date;
  languagesList = [];
  public checkedPreview = false;
  elementsToReuseObject: any;
  templateElementsArray: any;
  selectedElementToReuse: any;
  status = [];
  format_types = [];
  characters = [];

  fontFamilies = [];

  constructor(
    public functionsService: FunctionsService,
    public functionsControllerService: FunctionsControllerService,
    public genericService: GenericService,
    public endpointService: EndpointService,
    public endpointClientService: EndpointClientService,
    public templateService: TemplateService,
    public authService: AuthService,
    public snackBar: MatSnackBar
  ) {
    this.minDate = new Date();
  }

  disableSelect = new UntypedFormControl(false);
  reuseElementControl = new FormControl<any>('');
  filteredOptions: Observable<any[]>;
  public idPlantillaReference = 0;
  public htlmToShow: string;
  public loaded = false;

  panelOpenState = false;
  public subjectObject: any;
  public onSaveEvent = true;
  public idEmpresa: any;
  public idUser: any;

  ngOnInit(): void {
    this.templateService.saveReuse = false;
    this.templateService.selectReuse = false;
    this.templateService.showResult = false;
    this.templateService.currentElement = undefined;
    this.templateService.name = "";
    this.templateService.description = "";
    
    this.idEmpresa = this.authService.getIdEmpresa();
    this.idUser = this.authService.getLoggedInUserId();
    this.templateService.tmpId = 0;

    this.templateService.showGridTypes = 0;
    this.templateService.valueEmittedFromChild = 0;

    let idPlantilla = -1;
    if(this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]){
      let routeParams = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
      for(let k in routeParams['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input']) {
        if(routeParams['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input'][k]['id_db'] == 1 && routeParams['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input'][k]['bd_table'] == 'plantillas_emails' && routeParams['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input'][k]['bd_field'] == 'id') {
          idPlantilla = routeParams['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input'][k]['value'];
        }
      }
    }
    
    this.templateService.idPlantilla = idPlantilla;
    this.idPlantillaReference = idPlantilla;
    let idEmpresa = this.authService.getIdCompany();
    if(this.structure['componentCustomParams']) {
      this.templateService.currentLanguage = this.structure['componentCustomParams']['idLanguage'];
      this.templateService.arrayElementsPlantilla = this.structure['componentCustomParams']['arrayElementsPlantilla'];
      this.templateService.arrayElementsPlantillaSubject = this.structure['componentCustomParams']['arrayElementsPlantillaSubject'];
      this.templateService.plantillaInfo = this.structure['componentCustomParams']['plantillaInfo'];
      this.templateService.arrayElementsPlantillaRecord = this.structure['componentCustomParams']['arrayElementsPlantillaRecord'];
      this.templateService.arrayElementsTipo = this.structure['componentCustomParams']['arrayElementsTipo'];
      this.elementsToReuseObject = this.structure['componentCustomParams']['elementsToReuseObject'];
      this.status = this.structure['componentCustomParams']['status'];
      this.characters = this.structure['componentCustomParams']['characters'];
      this.format_types = this.structure['componentCustomParams']['format_types'];
      this.fontFamilies = this.structure['componentCustomParams']['fontFamilies'];
      this.languagesList = this.structure['componentCustomParams']['languagesList'];
      this.templateService.addElementIdsTMP();
      this.selectLanguage();
      this.initializeFilteredOptions();
      this.loaded = true;
    } else {
      this.endpointService.getElementsPlantilla(idPlantilla, idEmpresa).subscribe(async (data) => {
        this.templateService.arrayElementsPlantilla = await data["response"]["structure"];
        this.languagesList = data["response"]["idiomas"];
        this.templateService.arrayElementsPlantillaSubject = await data["response"]["subjects"];
        this.templateService.plantillaInfo = await data["response"]["plantillaInfo"];
        this.templateService.addElementIdsTMP();
        this.subjectObject = this.templateService.arrayElementsPlantillaSubject[0];
        this.templateService.currentRecordIndex = this.templateService.arrayElementsPlantillaRecord.push(JSON.parse(JSON.stringify(this.templateService.arrayElementsPlantilla))) - 1;
        this.templateService.arrayElementsTipo = await data["response"]["tipos"];
        this.elementsToReuseObject = await data["response"]["reuseElements"];
        this.status = await data["response"]["status"];
        this.characters = await data["response"]["characters"];
        this.format_types = await data["response"]["format_types"];
        this.fontFamilies = await data["response"]["fontFamilies"];
  
        if (this.elementsToReuseObject !== null) {
          let object = {id: 12, nombre_frontend: "Reuse element", icon: "on_device_training", code: ""};
          this.templateService.arrayElementsTipo.push(object);
        }
        this.initializeFilteredOptions();
        this.loaded = true;
        this.templateService.currentLanguage = this.templateService.arrayElementsPlantillaSubject[0]?.id_idioma ? this.templateService.arrayElementsPlantillaSubject[0]?.id_idioma : 1;
        this.selectLanguage();
      });
    }
  }

  ngOnDestroy(): void {
    this.structure['componentCustomParams'] = {
      idLanguage: this.templateService.currentLanguage,
      arrayElementsPlantilla: this.templateService.arrayElementsPlantilla,
      arrayElementsPlantillaSubject: this.templateService.arrayElementsPlantillaSubject,
      plantillaInfo: this.templateService.plantillaInfo,
      arrayElementsPlantillaRecord: this.templateService.arrayElementsPlantillaRecord,
      arrayElementsTipo: this.templateService.arrayElementsTipo,
      elementsToReuseObject: this.elementsToReuseObject,
      status: this.status,
      characters: this.characters,
      format_types: this.format_types,
      fontFamilies: this.fontFamilies,
      languagesList: this.languagesList
    }
    this.templateService.arrayElementsPlantilla = [];
    this.templateService.arrayElementsTipo = [];
    this.templateService.arrayElementsPlantillaSubject = [];
    this.templateService.plantillaInfo = {};
    this.templateService.arrayElementsPlantillaRecord = [];
  }

  initializeFilteredOptions() {
    this.filteredOptions = this.reuseElementControl.valueChanges.pipe(startWith(''), map(value => {
      const name = typeof value === 'string' ? value : value.nombre;
      return name ? this._filter(name as string) : this.elementsToReuseObject.slice();
      }),
    );
  }

  openSnackBar(msg, duration, panelClass = ["green-snackbar"]) {
    this.snackBar.open(msg, "x", {duration: duration, panelClass: panelClass});
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.elementsToReuseObject.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  displayFn(type): string {
    return type && type.nombre ? type.nombre : '';
  }

  selectLanguage() {
    for(let i in this.templateService.arrayElementsPlantillaSubject) {
      if(this.templateService.arrayElementsPlantillaSubject[i].id_idioma == this.templateService.currentLanguage) {
        this.subjectObject = this.templateService.arrayElementsPlantillaSubject[i];
        break;
      }
    }

    if(this.templateService.arrayElementsPlantilla[0] && this.templateService.arrayElementsPlantilla[0]["children"]) this.changeLanguageTree(this.templateService.arrayElementsPlantilla[0]["children"]);
    this.changeLanguageElementsToReuse();
    console.log(this.templateService.arrayElementsPlantilla, "tteeext", this.templateService.currentLanguage)
  }

  saveElementReuse(element) {
    let plantillaEmails = {
      nombre: this.templateService.name,
      descripcion: this.templateService.description,
      id_empresa: this.idEmpresa,
      id_creador: this.idUser,
    };
    this.templateService.currentElement = element;
    this.endpointService.insertEmailReuseElement([this.templateService.currentElement], [plantillaEmails], this.templateService.arrayElementsPlantillaSubject).subscribe(async (data) => {
      this.templateService.saveReuse = false;
      this.templateService.selectReuse = false;
      this.templateService.showResult = false;
      this.templateService.currentElement = undefined;
      this.templateService.name = "";
      this.templateService.description = "";
      if (data["response"]["errorCode"] !== 0) {
        this.openSnackBar("Elemento reutilizable guardado correctamente. Para que aparezca, deberás recargar la página.", 7000, ["green-snackbar"]);
        console.log(data);
      } else {
        this.openSnackBar("Something went wrong", 7000, ["red-snackbar"]);
      }
    });
  }

  changeLanguageTree(array) {
    for (let key in array) {      
      if (array[key]["emails_elements_idioma"] != undefined) {
        let changed = false;
        for (let key2 in array[key]["emails_elements_idioma"]) {
          if (array[key]["emails_elements_idioma"][key2]["id_idioma"] == this.templateService.currentLanguage) {
            array[key]["text"] = array[key]["emails_elements_idioma"][key2]["text"];
            array[key]["url"] = array[key]["emails_elements_idioma"][key2]["url"];
            array[key]["html_result"] = array[key]["emails_elements_idioma"][key2]["html_result"];
            console.log("html_result", array[key]["html_result"]);
            changed = true;
            break;
          }
        }

        if (!changed) {
          if(array[key]["emails_elements_idioma"][0]) {
            array[key]["text"] = array[key]["emails_elements_idioma"][0]["text"];
            array[key]["url"] = array[key]["emails_elements_idioma"][0]["url"];
          } else {
            array[key]["text"] = null;
            array[key]["url"] = null;
          }
          let languageData = {
            id_element: array[key]["id"],
            id_idioma: this.templateService.currentLanguage,
            text: array[key]["text"],
            url: array[key]["url"],
            html_result: array[key]["html_result"],
            mode_type: "insert",
          };
          
          array[key]["emails_elements_idioma"].push(languageData);
        }

        this.templateService.substituteTagsHTML(array[key]["element_info"]['code'], array[key]);
      }
      if (array[key]["children"] != undefined) this.changeLanguageTree(array[key]["children"]);
    }
  }

  changeLanguageElementsToReuse() {
    for(let i in this.elementsToReuseObject) {
      this.changeLanguageTree(this.elementsToReuseObject[i]['structure']);
    }
  }

  addChipCode(htmlCode: string, type: number) {
    if (type === 0) {
      this.templateService.currentElement['text'] = htmlCode;
      this.modifyElement("text");
    } else if (type === 1) {
      this.templateService.currentElement['url'] = htmlCode;
      this.modifyElement("url");
    }
  }

  addSubjectChip(htmlCode: string) {
    this.subjectObject.asunto = htmlCode;
    this.updateSubject(htmlCode);
  }

  public insertReuseElement(element) {
    this.templateService.selectedElementToReuse = element['structure'];
    let first = !(this.templateService.templateElementsArrayElementReuseSelected && this.templateService.templateElementsArrayElementReuseSelected.length && this.templateService.templateElementsArrayElementReuseSelected.length > 0);
    this.templateService.addElement(this.templateService.oldElementReuseSelected, element['structure'][0].element_info, first, true, this.templateService.idParentElementReuseSelected, this.templateService.templateElementsArrayElementReuseSelected, true);
    this.templateService.selectReuse = false;
    this.templateService.showResult = false;
    this.reuseElementControl = new FormControl<any>('');
  }

  public saveValueInsertReuseElement(element) {
    this.selectedElementToReuse = element;
  }

  public addGridElement(columnType) {
    let tipo, code;

    for (let t of this.templateService.arrayElementsTipo) {
      if (t["id"] == 3) {
        tipo = t;
        code = t["code"];
      }
    }

    this.templateService.valueEmittedFromChild = tipo["id"];

    let push = this.templateService.setDefaultValues(tipo, "grid", this.templateService.orderGrid, this.templateService.currentTmpParent, columnType);

    this.templateService.currentTemplateElementsArray.splice(this.templateService.indexToInsertGrid, 0, push);

    this.templateService.currentElement = push;

    if (code) this.templateService.substituteTagsHTML(code);

    this.templateService.orderChange();

    this.templateService.showGridTypes = 0;
    this.templateService.orderGrid = 0;
    this.templateService.currentTemplateElementsArray = [];

    console.log("arrayElementos", this.templateService.arrayElementsPlantilla);

    if (this.templateService.currentRecordIndex !== this.templateService.arrayElementsPlantillaRecord.length - 1) {
      this.templateService.arrayElementsPlantillaRecord.length = this.templateService.currentRecordIndex;
    }
    this.templateService.currentRecordIndex = this.templateService.arrayElementsPlantillaRecord.push(JSON.parse(JSON.stringify(this.templateService.arrayElementsPlantilla))) - 1;

    console.log(this.templateService.arrayElementsPlantilla,"arrayElementsPlantilla");
  }

  updateSubject(subject: any) {
    let id_idioma = this.templateService.currentLanguage;
    for (let key in this.templateService.arrayElementsPlantillaSubject) {
      if (this.templateService.arrayElementsPlantillaSubject[key]["id_idioma"] == id_idioma) {
        this.templateService.arrayElementsPlantillaSubject[key]["asunto"] = subject;
        if (this.templateService.arrayElementsPlantillaSubject[key]["mode_type"] == "insert") {
          this.templateService.arrayElementsPlantillaSubject[key]["mode_type"] = "insert";
        } else {
          this.templateService.arrayElementsPlantillaSubject[key]["mode_type"] = "update";
        }
      }
      if (this.templateService.arrayElementsPlantillaSubject[key]["id_idioma"] != id_idioma) {
        let result = this.templateService.arrayElementsPlantillaSubject.some(
          (item) => {
            return item.id_idioma == id_idioma;
          }
        );
        if (!result) {
          let data = {
            id_plantilla: this.templateService.idPlantilla,
            id_idioma: id_idioma,
            asunto: subject,
            fecha_modificacion: null,
            cabecera: "null",
            contenido: "null",
            pie_de_pagina: "null",
            traduccion_acabada: 0,
            mode_type: "insert",
          };
          this.templateService.arrayElementsPlantillaSubject.push(data);
        }
      }
    }
    console.log(this.templateService.arrayElementsPlantillaSubject);
  }

  public modifyElement(field) {
    this.templateService.modifyElement(field);
  }

  public redoUndo(action) {
    if (this.templateService.currentRecordIndex >= 0) {
      if (action == "undo") {
        --this.templateService.currentRecordIndex;
        if (this.templateService.arrayElementsPlantillaRecord[this.templateService.currentRecordIndex]) {
          this.templateService.arrayElementsPlantilla = this.templateService.arrayElementsPlantillaRecord[this.templateService.currentRecordIndex];
        } else {
          ++this.templateService.currentRecordIndex;
        }
      } else {
        ++this.templateService.currentRecordIndex;
        if (this.templateService.arrayElementsPlantillaRecord[this.templateService.currentRecordIndex]) {
          this.templateService.arrayElementsPlantilla = this.templateService.arrayElementsPlantillaRecord[this.templateService.currentRecordIndex];
        } else {
          --this.templateService.currentRecordIndex;
        }
      }
    }
  }

  public filterArrayTemplateElements() {
    //console.log(this.templateService.arrayElementsPlantilla, "sdfsafasdasdf");
    let someElementAvailable = false;
    this.templateService.arrayElementsPlantilla[0]["children"].filter(
      function (element) {
        if (element.borrado != 1) someElementAvailable = true;
      }
    );
    return someElementAvailable;
  }

  openSelectImage() {
    let x = {id_function: 5, internal_routing_id_functional_area: 30285, child: []};
    this.functionsControllerService.getFunction(x);
  }

  public closeRightSide() {
    this.templateService.saveReuse = false;
    this.templateService.selectReuse = false;
    this.templateService.showResult = false;
    this.templateService.currentElement = undefined;
    this.templateService.showGridTypes = 0;
  }

}
