<ng-container *ngIf="groupedChanges && groupedChanges.length && groupedChanges.length > 0">
    <div *ngFor="let group of groupedChanges" class="historic-changes">
        <div class="header-changes">
            <span style="font-weight: 500;">{{ group.changes[0].date_change_formated }}</span>
            <span style="font-weight: 300;;">{{ group.changes[0].name_user }}</span>
        </div>
        
        <div class="group-changes">
            <div *ngFor="let changeDetail of group.changes">
        
                <span class="form-change-label" fxLayoutAlign="space-between start">
                    <span style="text-transform: capitalize; font-weight: 500;"> {{ changeDetail.label }} </span>
                </span>
        
                <!-- CONTINGUT DEL CANVI -->
                <p class="form-changedd" fxLayoutAlign="start center" fxLayout="row wrap">
                <span class="form-change-old">
                    <ng-container
                    *ngIf="changeDetail.type !== 'checkbox' && changeDetail.type !== 'slide' && changeDetail.type !== 'password' && changeDetail.type !== '5-stars' && changeDetail.type !== '10-stars' && changeDetail.type !== '3-faces' && changeDetail.type !== '5-faces'">
                    {{ changeDetail.old_value }}
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type === 'checkbox'">
                    <i style="color: green !important;"
                        *ngIf="changeDetail.old_value == true" class="material-icons">
                        check_box
                    </i>
                    <i style="color: red !important;"
                        *ngIf="changeDetail.old_value == false"
                        class="material-icons">
                        check_box_outline_blank
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type === 'slide'">
                    <i style="color: green !important;"
                        *ngIf="changeDetail.old_value == true" class="material-icons">
                        toggle_on
                    </i>
                    <i style="color: red !important;"
                        *ngIf="changeDetail.old_value == false"
                        class="material-icons">
                        toggle_off
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == 'password'">
                    <i class="material-icons" style="color: #FFFFFF !important;">
                        password
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == '5-stars' || changeDetail.type == '10-stars'">
                    {{ changeDetail.old_value }}
                    <i class="material-icons" style="color: #FFFFFF !important;">
                        star
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == '3-faces'">
                    {{ changeDetail.old_value }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray3; let i = index">
                        <i [ngStyle]="{'color': face['color']}"
                        *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][changeDetail.name].old"
                        class="material-icons">
                        {{ face.icon }}
                        </i>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == '5-faces'">
                    {{ changeDetail.old_value }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray5; let i = index">
                        <i [ngStyle]="{'color': face['color']}"
                        *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][changeDetail.name].old"
                        class="material-icons">
                        {{ face.icon }}
                        </i>
                    </ng-container>
                    </ng-container>
                </span>
        
                <span class="material-icons form-changed-icon">trending_flat</span>
        
                <span class="form-change-new">
                    <ng-container
                    *ngIf="changeDetail.type !== 'checkbox' && changeDetail.type !== 'slide' && changeDetail.type !== '5-stars' && changeDetail.type !== '10-stars' && changeDetail.type !== '3-faces' && changeDetail.type !== '5-faces'">
                    {{ changeDetail.value }}
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type === 'checkbox'">
                    <i style="color: green !important;"
                        *ngIf="changeDetail.value == true" class="material-icons">
                        check_box
                    </i>
                    <i style="color: red !important;"
                        *ngIf="changeDetail.value == false"
                        class="material-icons">
                        check_box_outline_blank
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type === 'slide'">
                    <i style="color: green !important;"
                        *ngIf="changeDetail.value == true" class="material-icons">
                        toggle_on
                    </i>
                    <i style="color: red !important;"
                        *ngIf="changeDetail.value == false"
                        class="material-icons">
                        toggle_off
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == '5-stars' || changeDetail.type == '10-stars'">
                    {{ changeDetail.value }}
                    <i class="material-icons" style="color: #FFFFFF !important;">
                        star
                    </i>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == '3-faces'">
                    {{ changeDetail.value }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray3; let i = index">
                        <i [ngStyle]="{'color': face['color']}"
                        *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][changeDetail.name].new"
                        class="material-icons">
                        {{ face.icon }}
                        </i>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngIf="changeDetail.type == '5-faces'">
                    {{ changeDetail.value }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray5; let i = index">
                        <i [ngStyle]="{'color': face['color']}"
                        *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][changeDetail.name].new"
                        class="material-icons">
                        {{ face.icon }}
                        </i>
                    </ng-container>
                    </ng-container>
                </span>
                </p>
            </div>
        </div>
    </div>
</ng-container>
  
<ng-container *ngIf="!(groupedChanges && groupedChanges.length && groupedChanges.length > 0)">
    <p class="message-no-data-historic-generic">Todavía no se han registado cambios en esta ficha</p>
</ng-container>