import {Component, OnInit, ViewChild, Output, EventEmitter, Input} from "@angular/core";
import {ON_OFF_ANIMATION} from "../../animations/on-off.animation";
import {LoginComponent} from "../login.component";
import {DeviceDetectorService} from "ngx-device-detector";
import {GenericService} from "../../services/generic/generic.service";
import {RoutingService} from "../../services/routing/routing.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Values} from "../../../values/values";
import {Router} from "@angular/router";

import {MatDialog} from "@angular/material/dialog";
import {MessageDialogComponent} from "../../utils/message-dialog/message-dialog.component";

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.css'],
})

export class LoginScreenComponent implements OnInit {

  @ViewChild('LoginComponent') public LoginComponent: LoginComponent;

  @Output() onLoginEmitter = new EventEmitter();

  public img: string;

  constructor(public router: Router,
              public snackBar: MatSnackBar,
              public genericService: GenericService,
              public translate: TranslateService,
              public deviceService: DeviceDetectorService,
              public dialog: MatDialog,
              public routingService: RoutingService) {
  }
  
  ngOnInit(): void {
    this.translate.get('web').subscribe((translations) => {
      if (this.genericService.webpSupported) {
        this.img = translations.form.imgWebm;
      } else {
        this.img = translations.form.img;
      }
    });
  }

  recomendar(){
    this.snackBar.open('¡Se ha copiado el link para que puedas facilitárselo por el canal que lo prefieras!', 'X', {
      duration: 5000,
      panelClass: ['green-snackbar']
    });
  }

  registrar(){
    if(this.routingService.redirectionJoinGroup){
      this.routingService.shouldRegister = true;
    }
    this.router.navigate(['#49']);
  }

  public onLogin2() {
///* Joan - Logs per provar
//console.log("onLoginEmitter-2");
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: 'Venimos del login',
        labelButton1: 'OK',
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.onLoginEmitter.emit();
    });
//*/
//    this.onLoginEmitter.emit();
  }
}
