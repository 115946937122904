import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { GenericService } from 'app/services/generic/generic.service';
import { RoutingService } from 'app/services/routing/routing.service';
import { EndpointService } from "../../../services/generic/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import { ConditionsClientService } from 'app/services-client/conditions-client.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy {

  @Input() structure = null;
  @Input() type;
  @Input() dialogId;
  @Input() moduleId;

  public params = null;
  public formGroup = {};
  public loaded = false;
  public emptyDialog = true;
  private monitoreo;

  constructor(
    private endpointService: EndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private routingService:RoutingService,
    public genericService: GenericService,
    public conditionsClientService: ConditionsClientService,
    public authService: AuthService
  ) {
    this.structure = data['structure'];
    this.type = data['type'];
    this.dialogId = data['dialogId'];
    this.moduleId = data['moduleId'];
  }
  //Ahora se tendra que pasar el id de la functional area a cargar en el dialog
  ngOnInit(): void {
    if(this.structure['creatingMode'] == undefined || !this.structure['creatingMode']) {
      this.genericService.fetchDSBStructure(this.structure['id_functional_parent_initial'], this.dialogId, this.structure['id_query'], this.type, this.moduleId, this.structure, this.params).subscribe((data): void => {       
        this.loaded = true;
        this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), this.dialogId, this.dialogId, this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe(data => {
          this.monitoreo = data['response'];
        });
      });
    } else {
      this.loaded = true
    }
  }

  ngOnDestroy() {
    this.genericService.closeFloatingElement(this.monitoreo, this.dialogId, this.structure);
  }

}
