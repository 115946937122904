import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CdkDragDrop, CdkDrag, CdkDragStart, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { AuthService } from 'app/services/auth/auth.service';
import { GenericService } from "app/services/generic/generic.service";
import { FunctionsService } from "app/services/generic/functions.service";
import { EndpointService } from 'app/services/generic/endpoint.service';
import { EndpointClientService } from 'app/services-client/endpoint-client.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-flat-images-custom',
  templateUrl: './flat-images-custom.component.html',
  styleUrls: ['./flat-images-custom.component.css']
})
export class FlatImagesCustomComponent implements OnInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;

  // public genericImagesList: any[] = []
  public containerIndex = 0;
  public previusContainerIndex = 0;

  public loaded = false;

  constructor(
    public authService: AuthService,
    public genericService: GenericService,
    public functionsService: FunctionsService,
    public endpointService: EndpointService,
    public endpointClientService: EndpointClientService
  ) { }

  ngOnInit(): void {
    let idProperty = 0;
    let idFA = this.genericService.findFAWithTableField(1, 'vivienda', 'id', this.structure[this.index]["id_functional_parent_initial_dsb"]);
    if (idFA && idFA['tmp_value']) idProperty = idFA['tmp_value'];
    this.functionsService.arrayImagenes = [];
    this.endpointClientService.getImagesProperty(idProperty).subscribe(data => {
      if (data['response'].length > 0) {
        let imagesArray = data['response'];
        for (let j in imagesArray) {
          imagesArray[j]['nombre_fichero'] = imagesArray[j]['nombre_fichero'] + "?" + new Date().getTime();
        }
        this.functionsService.arrayImagenes = this.splitGenericList([...imagesArray]);
      }
      this.loaded = true;
    });
  }

  splitGenericList(array) {
    const splittedArray = [];
    for (let i = 0; i < array.length; i += 5) {
      splittedArray.push(array.slice(i, i + 5));
    }
    return splittedArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      this.containerIndex = this.functionsService.arrayImagenes.indexOf(event.container.data);
      this.previusContainerIndex = this.functionsService.arrayImagenes.indexOf(event.previousContainer.data);

      if (event.distance.y < 0) { // de abajo hacia arriba
        for (let index = this.containerIndex; index < this.previusContainerIndex; index++) {
          this.functionsService.arrayImagenes[index + 1].unshift(this.functionsService.arrayImagenes[index].at(-1));
          this.functionsService.arrayImagenes[index].pop();
        }
        return;
      }

      if (event.distance.y > 0) {  // de arriba hacia abajo
        for (let index = this.containerIndex; index > this.previusContainerIndex; index--) {
          this.functionsService.arrayImagenes[index - 1].push(this.functionsService.arrayImagenes[index].at(0));
          this.functionsService.arrayImagenes[index].shift();
        }
        return;
      }

    }

  }

  public updatePublish(value, id) {
    for (let array of this.functionsService.arrayImagenes) {
      const image = array.find(item => item.id == id);
      if (image) {
        image.selected = value ? 1 : 0
        return;
      };
    }
  }

  public updatePlano(value, id) {
    for (let array of this.functionsService.arrayImagenes) {
      const image = array.find(item => item.id == id);
      if (image) {
        image.plano = value ? 1 : 0
        return;
      };
    }
  }

  public updateCertificado(value, id) {
    for (let array of this.functionsService.arrayImagenes) {
      const image = array.find(item => item.id == id);
      if (image) {
        image.certificado = value ? 1 : 0
        return;
      };
    }
  }

  public updateDosier(value, id) {
    for (let array of this.functionsService.arrayImagenes) {
      const image = array.find(item => item.id == id);
      if (image) {
        image.dosier = value ? 1 : 0
        return;
      };
    }
  }

  public deleteSelectedImages(value, id) {
    for (let array of this.functionsService.arrayImagenes) {
      const image = array.find(item => item.id == id);
      if (image) {
        image.delete_comodin = value ? 1 : 0
        return;
      };
    }
  }
}