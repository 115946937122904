import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {EndpointService} from "../../../../../services/generic/endpoint.service";
import {EndpointClientService} from "../../../../../services-client/endpoint-client.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../../../services/auth/auth.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Strings} from "../../../../../../strings/strings";
import {Subject} from "rxjs";

@Component({
  selector: 'postpone-event',
  templateUrl: './postpone-event.html',
  styleUrls: ['./postpone-event.scss'],
})
export class PostponeEvent implements OnInit, OnDestroy{
  public strings = Strings.CALENDAR;
  public event: any;
  refresh: Subject<any> = new Subject();
  dateStart: Date;
  dateEnd: Date;

  constructor(private endpointService: EndpointService,
              private endpointClientService: EndpointClientService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private _dialogRef: MatDialogRef<PostponeEvent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.event = data;
              }
  ngOnInit() {}

  ngOnDestroy() {}

  startDateIsInvalid() {
    return (this.dateStart < new Date());
  }

  endDateIsInvalid() {
    return (this.dateEnd < this.dateStart || this.dateStart === null || this.dateStart === undefined);
  }

  getStartDateErrorMessage() {
    return this.startDateIsInvalid() ? this.strings.START_DATE_ERROR_MESSAGE : '';
  }

  getEndDateErrorMessage() {
    return this.endDateIsInvalid() ? this.strings.END_DATE_ERROR_MESSAGE : '';
  }

  postponeActivity() {
    const body = {
      start_date: (this.dateStart === null || this.dateStart === undefined) ? null : this.dateStart.toISOString().slice(0, 19).replace('T', ' '),
      end_date: (this.dateEnd === null || this.dateEnd === undefined) ? null : this.dateEnd.toISOString().slice(0, 19).replace('T', ' ')
    };
    this.endpointClientService.postponeActivity(body, this.event.id).subscribe(d => {
      if (d['errorCode'] === 0) {
        this._dialogRef.close(true);
      }
    });
  }

  onNoClick() {
    this._dialogRef.close(false);
  }
}
