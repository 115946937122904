
<span *ngIf="loaded" id="table-filters-generic" fxFlex="100" fxLayout="row wrap" style="padding-bottom: 3em; overflow: scroll;">
    <span fxFlex="100" style="padding: 1em 1em 0em 1em; background: rgba(var(--primary-color), 0.25);">
        <mat-form-field fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" appearance="outline">
            <mat-label>Nombre vista</mat-label>
            <input matInput [(ngModel)]="this.nameView">
        </mat-form-field>
    </span>
    <mat-checkbox [(checked)]="checkAll" (change)="checkUncheckAll($event.checked)" style="padding: 1em 1em 0 1em;">
        <span style="font-weight: bold; white-space: break-spaces; text-transform: capitalize; font-style: italic;">-- All --</span>  
    </mat-checkbox>
    <span cdkDropList fxLayout="row wrap" fxFlex="100" style="padding: 3px 1em 1em 1em;" (cdkDropListDropped)="drop($event)">
        <span *ngFor="let c of columns; let i = index" fxFlex="100" cdkDrag>
            <mat-icon cdkDragHandle style="color: #000; margin-right: 6px;">open_with</mat-icon>
            <mat-checkbox [(checked)]="c.displayed" (change)="updateValue(c, $event.checked)">
                <span *ngIf="c.label" style="font-weight: 500; white-space: break-spaces; text-transform: capitalize;">{{c.label}}</span>  
            </mat-checkbox>
        </span>
    </span>
</span>
<span class="buttons-table-filter">
    <span class="generic-buttons-blue" style="margin-right: 10px;" (click)="closeBottomSheet()"><button mat-button>Cerrar sin modificar vista</button></span>
    <span class="generic-buttons-blue" style="margin-right: 10px;" (click)="resetFilterTableValues()"><button mat-button>Resetear vista</button></span>
    <span class="generic-buttons-blue-revert" (click)="filterTableValues()"><button mat-button>Aplicar vista</button></span>
</span>