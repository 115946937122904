import { EventEmitter, Injectable, Output } from "@angular/core";
import { Values } from "../../../values/values";

// Serivce
import { RoutingService } from "../routing/routing.service";
import { EndpointService } from "./endpoint.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { JourneyService } from 'app/services/journey/journey.service';
import { RedsysService } from 'app/services/redsys.service';
import { PaycometService } from 'app/services/paycomet.service';
import { PrintTicketService } from 'app/services/generic/custom/print-ticket.service';
//import { GenericClientService } from 'app/services-client/custom-services-client/generic-client.service';

import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import html2canvas from "html2canvas";

// Material
import { MatDialog } from "@angular/material/dialog";

// Component
import { DialogComponent } from "../../generic/components/dialog/dialog.component";
import { SnackbarComponent } from "../../generic/components/snackbar/snackbar.component";
import { AdvicebarComponent } from "../../generic/components/advicebar/advicebar.component";
import { GenericService } from "./generic.service";
import { UserFunctionsService } from "../functions/user-functions.service";

import { MatSnackBar } from "@angular/material/snack-bar";

import { saveAs } from "file-saver";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomSheetComponent } from "app/generic/components/bottom-sheet/bottom-sheet.component";
import { AuthService } from "../auth/auth.service";
import { Location } from "@angular/common";
import { SideNavService } from "app/utils/SideNavService";
import { EMPTY } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";

import { ColorPickerDialog } from "app/activities/subcomponents/color-picker-dialog/color-picker-dialog";
import { setCategories } from "app/activities/objects/category";
import { Router } from "@angular/router";
import { BottomsheetMenuComponent } from "app/generic/components/button/bottomsheet-menu/bottomsheet-menu.component";


@Injectable({
  providedIn: "root",
})
export class FunctionsService {
  public dialogRef = null;
  public snackbarRef = null;
  public advicebarRef = null;
  public route = null;
  public id_route = null;
  public tmpArray = [];
  public removed = [];
  public lastLabel = "";
  public errorMessage = "";

  public bottomSheetRef = null;
  public bottomSheetRefMenu = null;

  public dateToStringDBDate: Date;
  public dateToday: string;
  public minDate: string;
  public functionFinish: boolean;

  public open = false;
  public downloadingStaticPage = false;
  public imageUpload = [];

  public portalConfigured = 0;

  public arrayImagenes = [];

  public arrayConditions = [];
  public arrayTables = [];
  public fieldNames = [];
  public arrayOperators = [];
  public arrayValues = [];
  public fecha_inicio = null;
  public fecha_fin = null;

  public fecha_inicio_reservas = null;
  public fecha_fin_reservas = null;
  public hora_inicio_reservas = null;
  public hora_fin_reservas = null;

  public historyInserts = [];

  public checkoutUrl = "checkout-payment-cart";

  public addConditionFormIsValid: boolean = false;

  @Output() update_category: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() openNoti: EventEmitter<any> = new EventEmitter();
  //@ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  constructor(
    private routingService: RoutingService,
    //private genericClientService: GenericClientService,
    public dialog: MatDialog,
    public _bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
    private endpointService: EndpointService,
    private genericService: GenericService,
    private userFunctionsService: UserFunctionsService,
    private authService: AuthService,
    private location: Location,
    private sidenavService: SideNavService,
    private router: Router,
    public clipboard: Clipboard,
    public templateService: TemplateService,
    public journeyService: JourneyService,
    public redsysService: RedsysService,
    public paycometService: PaycometService,
    public printTicketService: PrintTicketService,
  ) { }

  public openDeferContainer(param, structure) {
    let idFA = this.genericService.findElementWithId(structure["internal_routing"], false, false, true);
    if (idFA["id_functional_type"] == 106) {
      this.genericService.lastDeferContainer = idFA["id_functional_area"];
      idFA["id_functional_status_general"] = 1;
      if ((!this.genericService.staticHTML && this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1]["parent"] === null) || this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1]["parent"] === undefined) this.genericService.breadcrumbs.pop();
      this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1]["defer"] = param;
      this.genericService.breadcrumbs.push({
        url: this.routingService.urlWithoutHashtag.split("/")[1],
        name: structure["label"],
      });
      this.location.replaceState(this.routingService.urlWithoutHashtag + "#" + idFA["id_functional_area"]);
      this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), idFA["id_functional_area"], idFA["id_functional_area"], this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe((data) => {
        idFA["monitoreo"] = data["response"];
      });
    }
  }

  public formsArray(structure, finished, validate = true) {
    let valid = true;
    this.tmpArray = [];
    this.removed = [];
    this.imageUpload = [];
    let useStructure = [];
    let validReq = true;

    useStructure = this.genericService.getAllStructures();
    for (let s in useStructure) {
      if (!this.eachRecursiveBool(useStructure[s], validate, structure["id_functional_parent_initial_dsb"])) {
        if (validate) {
          validReq = false;
          if (this.errorMessage && this.errorMessage != null && this.errorMessage != "") {
            this.genericService.openSnackBar(this.errorMessage, 6000, ["red-snackbar"]);
          } else if (this.lastLabel && this.lastLabel != null && this.lastLabel != "") {
            this.genericService.openSnackBar("Falta completar el campo: " + this.lastLabel, 6000, ["red-snackbar"]);
          } else {
            this.genericService.openSnackBar("Parece que hay campos sin completar", 6000, ["red-snackbar"]);
          }
        }
      }
    }
    return validReq;
  }

  public checkPreviewModeFunction(finished = null) {
    if (this.genericService.previewModeUser) {
      this.genericService.openSnackBar("Debes desactivar el modo previsualización para realizar esta acción.", 6000, ["red-snackbar",]);
      this.genericService.finishFunction(finished);
      return true;
    }
  }

  public pushInfoBackendInsertUpdateForm(data, structure) {
    let id_dsb = -1;
    if(structure['id_functional_parent_initial_dsb']) id_dsb = structure['id_functional_parent_initial_dsb'];
    let insert = {
      data: data,
      structure: structure,
      time: this.formatDate(new Date()),
      id_dsb: id_dsb,
    }
    let id = structure['id_functional_parent_initial'];
    console.log("PUSH INFO BACKEND", insert, id, this.historyInserts)
    if(this.historyInserts[id] === undefined) this.historyInserts[id] = [];
    this.historyInserts[id].push(insert);
  }


  public insertUpdateForm(structure, param, finished, refresh = 0, par = false, dialog = false, type = null, publicar = null, addParamStatic = false, valuesLoaded = false) {
    if (this.checkPreviewModeFunction(finished)) {
      return EMPTY;
    }
    let messageSucced = "Guardado con éxito";
    let fakeStructure = this.cloneVariable(structure);
    if (structure['description'] && structure['description'] != null && structure['description'] != undefined) messageSucced = structure['description'];
    //  this.insertUpdateForm(structure, param, finished, 1, false, false);
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar, valuesLoaded);
    if (v != null) {
      v.subscribe((data) => {
        if(data["extraInfo"]) {
          this.pushInfoBackendInsertUpdateForm(data["extraInfo"], structure);
        }
        this.genericService.finishFunction(finished);
        if (data["errorCode"] == 0 || data["errorCode"] == 3 || data["errorCode"] == 5) {
          if (data["response"] != 0 && data["errorCode"] == 3) {
            if (data["errorMessage"] !== null && data["errorMessage"] !== "") this.genericService.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          } else {
            //Casos generals generic
            if (refresh == 99) { // Para casos donde hay que hacer otras funciones
              if (type == 4) this.printCommandRest(structure, param);
              if (type == 5) {
                this.printCommandRest(structure, param);
                refresh = 1;
              }
              if (type == 6) {
                fakeStructure["id_function"] = 193;
                let obj = { structure: fakeStructure, param: null, finished, getFunction: true };
                this.executeNewFunction(obj);  
                refresh = 1;
              }
              if (type == 7) {
                fakeStructure["id_function"] = 193;
                let obj = { structure: fakeStructure, param: null, finished, getFunction: true };
                this.executeNewFunction(obj);
              }
              if (type == 8) {
                fakeStructure["id_function"] = 193;
                let obj = { structure: fakeStructure, param: null, finished, getFunction: true };
                this.executeNewFunction(obj);
                refresh = 2;
              }
              if (type == 9) {
                this.printRestCierreInserted(data["response"]);
              }
              if (type == 10) {
                this.printRestCierreInserted(data["response"]);
                refresh = 1;
              }
              if (type == 11) {
                this.printRestSnapshotInserted(data["response"]);
              }
              if (type == 12) {
                this.printRestSnapshotInserted(data["response"]);
                refresh = 1;
              }
              if (type == 13) {
                this.printRestMovementInserted(data["response"]);
              }
              if (type == 14) {
                this.printRestMovementInserted(data["response"]);
                refresh = 1;
              }
              if (type == 15) this.printCommandCancelRest(structure, param);
              if (type == 16) {
                this.printCommandCancelRest(structure, param);
                refresh = 1;
              }
              if (type == 17) {
                this.genericFunctionRoutingStatic(structure, param);
              }
              if (type == 18) {
                this.genericFunctionRoutingStatic(structure, param, false, true);
              }
            } else {
              this.dialog.closeAll();
            }
            this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
            if (structure["id_functional_area"] == 7052 && data["response"] > 0) {
              // Solo para el registro
              this.sendSuccedSnackbarInsert({ "errorMessage": "¡Bienvenid@ a 4DMOVIN!" }, messageSucced);
              localStorage.setItem("_i_", data["response"].toString());
              this.genericService.refreshStructure(2);
            } else if (!dialog && structure["internal_routing"] !== null && structure["internal_routing"] !== undefined && publicar != 1 && !this.routingService.shouldOpenExternalWindow) {
              this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
              if (par && !this.genericService.staticHTML) this.saveParams(structure, param);
              if (refresh == 2) this.goPrev(false, structure["internal_routing"], false);
              if (this.genericService.staticHTML) {
                let url = "";
                if (addParamStatic) {
                  let idAdd = -1;
                  if (Array.isArray(data["response"])) {
                    let newValues = data["response"];
                    for (let v in newValues) {
                      if (newValues[v]["bd_table"] && newValues[v]["bd_table"] == structure["bd_table"] && newValues[v]["database"] && newValues[v]["database"] == structure["id_db"] && newValues[v]["bd_field"] && newValues[v]["bd_field"] == structure["bd_field"] && newValues[v]["responseId"]) idAdd = newValues[v]["responseId"];
                      if (idAdd != -1) break;
                    }
                  }
                  if (idAdd != -1) {
                    let idLanguage = this.authService.labelLanguage;
                    let idEmpresa = this.authService.getIdEmpresa();
                    let nombreEmpresa = this.authService.companyGenericName;
                    url = "../" + idLanguage + "/" + nombreEmpresa + "/" + structure['internal_routing'] + "/sth/" + idEmpresa + "-" + this.authService.userId + "&&" + idAdd;
                  }
                }
                if(url != "") {
                  if(type == 1) {
                    window.open(url, '_self');
                  } else {
                    window.open(url);
                  }
                }
                if (refresh == 4) {
                  this.genericService.refreshStructure(0);
                }
              } else {
                if(!structure['id_functional_parent_initial_dsb'] || !(structure['id_functional_parent_initial_dsb'] > 0)) {
                  let id_pantalla = structure['id_functional_parent_initial'];
                  let indexStruct = this.genericService.paramControlVariables[id_pantalla]["indexParam"];
                  if (this.genericService.arrayFunctionalParentsLoaded.hasOwnProperty(id_pantalla)) {
                    this.genericService.arrayFunctionalParentsLoaded[id_pantalla].splice(indexStruct, 1);
                  }
                  if (Array.isArray(data["response"])) {
                    this.genericService.paramControlVariables[id_pantalla]['params'][this.genericService.paramControlVariables[id_pantalla]['indexParam']]['output'] = [];
                    let newValues = data["response"];
                    let params = null;
                    for (let v in newValues) {
                      params = {id_db: newValues[v]["database"], bd_table: newValues[v]["bd_table"], bd_field: newValues[v]["bd_field"], value: newValues[v]["responseId"]};                      
                      this.genericService.paramControlVariables[id_pantalla]['params'][this.genericService.paramControlVariables[id_pantalla]['indexParam']]['output'].push(params);
                    }
                  }
                }
                this.goRouting(structure["internal_routing"], false);
              }
            } else if (!dialog) {
              if (refresh == 1 || refresh == 3) {
                this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
                if (refresh == 1) this.genericService.refreshStructure(0);
                else this.genericService.refreshStructure(3);
                this.genericService.originalInputsValues = [];
              } else if (refresh == 2) {
                this.goPrev(false, structure["internal_routing"], false);
              } else {
                this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
                if (refresh != 4) {
                  this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
                }
                this.genericService.refreshOriginalInputsValues(this.genericService.getAllStructures());
                this.genericService.updateFormFields.next(true);
                this.genericService.updateFormFields.next(false);
                this.genericService.originalInputsValues = [];
              }
            } else if (structure["id_functional_parent_initial_dsb"]) {
              this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
              let idDialogParent = this.genericService.findElementWithId(structure["internal_routing_id_functional_area"], false, false, true);
              let fakeParent = this.cloneVariable(structure);
              fakeParent["child"] = Array(idDialogParent);
              fakeParent["id_function"] = 2; //Type == 1
              if (type == 2) fakeParent["id_function"] = 45;
              if (type == 3) fakeParent["id_function"] = 46;
              if (refresh == 4) {
                this.genericService.refreshStructure(0);
              }
              let obj = { structure: fakeParent, param: null, finished, getFunction: true };
              console.log("fakeParentfakeParent", obj, this.cloneVariable(obj));
              this.executeNewFunction(obj);
            }
          }
        } else if (data["errorCode"] == 1) {
          //INSERT/UPDATE INMUEBLE INCORRECTO
          if (this.genericService.parentStructure[0]["id_functional_parent_initial"] == 17) {
            if (data["response"] == 1) this.focusFA({ child: this.genericService.parentStructure }, 2663);
            if (data["response"] == 2 || data["response"] == 3) this.focusFA({ child: this.genericService.parentStructure }, 4985);
          } else if (this.genericService.parentStructure[0]["id_functional_parent_initial"] == 13) {
            if (data["response"] == 1) this.focusFA({ child: this.genericService.parentStructure }, 5078);
            if (data["response"] == 2 || data["response"] == 3) this.focusFA({ child: this.genericService.parentStructure }, 5060);
          }
          this.genericService.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          this.genericService.finishFunction(finished);
          structure['id_functional_status_general'] = 2;
          setTimeout(() => {
            structure['id_functional_status_general'] = 1;
          }, 500);
        } else if (data["errorCode"] == 2) {
          //INSERT/UPDATE INMUEBLE CORRECTO
          if (data["errorMessage"] !== null && data["errorMessage"] !== "") {
            this.saveParams(structure, param);
            this.addWindowParam(data["errorMessage"], 1, "vivienda", "id", 13, true, "output");
            this.addWindowParam(data["errorMessage"], 1, "vivienda", "id", 17, true, "input", true);
            this.addWindowParam(data["errorMessage"], 1, "vivienda", "id", 17, true, "intern");
            this.sendSuccedSnackbarInsert({ "errorMessage": "Inmueble guardado correctamente.\n Indica en qué portales quieres publicarlo." }, messageSucced);
            this.genericService.updateParamControl();
            this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
            this.dialog.closeAll();
            this.genericService.finishFunction(finished);
            console.log(structure, "structure")
            if (structure["internal_routing"]) this.goRouting(structure["internal_routing"], false);
          }
        } else if (data["errorCode"] == 4) {
          window.open(data["response"], "_self");
          this.genericService.finishFunction(finished);
          this.dialog.closeAll();
        }
        return EMPTY;
      });
    }
  }

  private sendSuccedSnackbarInsert(data, messageSucced, seconds = 6000, red = false) {
    if (data["errorMessage"], data["errorMessage"] !== null && data["errorMessage"] !== "") messageSucced = data["errorMessage"];
    if (red) this.genericService.openSnackBar(messageSucced, seconds, ["red-snackbar",]);
    else this.genericService.openSnackBar(messageSucced, seconds, ["green-snackbar",]);
  }

  public executeNewFunction(obj) {
    this.genericService.executeNewFunction.next(obj);
  }

  focusFA(structure, id) {
    let found = false;
    if (structure) {
      if (structure["id_functional_area"] == id) {
        return true;
      } else {
        let lastChild = null;
        for (let t in structure["child"]) {
          if (!found) found = this.focusFA(structure["child"][t], id);
          if (found) {
            lastChild = t;
            break;
          }
        }
        if (found) {
          if (structure["id_functional_type"] == 96) {
            structure["selectedIndex"] = lastChild;
          }
          if (structure["id_functional_type"] == 7) {
            structure["expansion_start_opened"] = lastChild;
          }
          if (structure["id_functional_type"] == 8) {
            structure["child"][0]["tabActive"] = lastChild;
          }
        }
      }
    }
    return found;
  }

  async goRouting(route, checkRouting, validate = true) {
    if (route) {
      if (route[0] && route[0] == "#") {
        this.routingService.urlHashtag = route.slice(1);
        this.focusFA({ child: this.genericService.parentStructure }, route.slice(1));
        this.genericService.scroll(route.slice(1));
        const url = this.routingService.urlWithoutHashtag;
        this.location.replaceState(url + "#" + this.routingService.urlHashtag);
      } else {
        this.genericService.refreshStructureFinished = true;
        if (validate && this.genericService.getNumChanges() > 0) {
          const status = await this.genericService.openWarningDialog(1, 1);
          if (status) this.genericService.go(route, checkRouting, this.id_route);
        } else {
          this.genericService.go(route, checkRouting, this.id_route);
        }
      }
    } else {
      this.genericService.refreshStructure(0);
    }
  }

  openDialog(structure, type, params = null) {
    let dialogFA = null;
    let internal_routing_id_functional_area = null;
    if (structure['internal_routing_id_functional_area']) {
      internal_routing_id_functional_area = structure['internal_routing_id_functional_area'];
    }

    if (structure["child"]) {
      for (let i in structure["child"]) {
        if (structure["child"][i]["isFloatingElement"] == 1)
          dialogFA = structure["child"][i];
      }
    }

    if (dialogFA === null && internal_routing_id_functional_area !== null) {
      dialogFA = this.genericService.findElementWithId(+structure['internal_routing_id_functional_area'], false, false, true);
      if (dialogFA['id_functional_parent_initial'] != structure['id_functional_parent_initial'] && (structure['refresh_params'] === undefined || structure['refresh_params'] !== 2)) {
        // Si esta en otra pantalla, debe recargar los parametros (por ahora, los casos donde puede llamarse un popup de otra pantalla son: headers y footers)
        this.genericService.getInternParam(dialogFA, null);
      }
    }

    if (dialogFA !== null) this.loadDialogStructure(dialogFA, type, dialogFA["id_functional_parent_initial_dsb"], params, structure['id_functional_parent_initial']);
    else console.log("PARECE QUE NO SE HA ECONTRADO EL DIALOG CON ID_FUNCTIONAL_AREA = ", internal_routing_id_functional_area);
  }

  private loadDialogStructure(structure, type, dialogId, params, moduleId) {
    if (structure !== undefined && structure !== null) {
      if (type == 4 || type == 6) {
        if (!this.genericService.paramControlVariables[moduleId]) {
          this.genericService.paramControlVariables[moduleId] = this.genericService.createNewParamVariable();
        }
        if (!this.genericService.paramControlVariables[moduleId]['params'][this.genericService.paramControlVariables[moduleId]['indexParam']]) {
          this.genericService.paramControlVariables[moduleId]['params'][this.genericService.paramControlVariables[moduleId]['indexParam']] = this.genericService.initializeParams();
        }
        this.genericService.paramControlVariables[moduleId]['params'][this.genericService.paramControlVariables[moduleId]['indexParam']]['intern'] = params;
      }
      if (this.routingService.shouldOpenExternalWindow) structure["class"].push("overAllDialog");
      this.dialog.closeAll();
      
      if(structure["id_functional_type"] == 85) {
        if (structure["close_dialog_out"] == 1) {
          structure["class"] = [structure["class"], "dialog-material-generic-close-out", "dialog-material-generic",];
        } else {
          structure["class"] = [structure["class"], "dialog-material-generic-no-close-out", "dialog-material-generic",];
        }
        this.dialogRef = this.dialog.open(DialogComponent, {
          width: "auto",
          height: "auto",
          panelClass: structure["class"],
          data: {
            structure: structure,
            type: type,
            dialogId: dialogId,
            moduleId: moduleId
          },
          hasBackdrop: structure["close_dialog_out"] == 1,
        });
      }

      if(structure["id_functional_type"] == 158) {
        if(!structure['text-align']) structure['text-align'] = 'center';
        if(!structure['height']) structure['height'] = 'bottom';
        if(!structure['max-lenght']) structure['max-lenght'] = 7;
        if(!structure['class']) structure['class'] = 'white-snackbar';
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: structure['max-lenght'] * 10000000,
          data: {
            structure: structure,
            type: type,
            dialogId: dialogId,
            moduleId: moduleId
          },
          panelClass: structure["class"],
          horizontalPosition: structure['text-align'],
          verticalPosition: structure['height']
        });
      }

      if(structure["id_functional_type"] == 159) {
        this.advicebarRef = {
          data: {
            structure: structure,
            type: type,
            dialogId: dialogId,
            moduleId: moduleId
          },
        };
        this.genericService.addToAdvicebarArray(this.advicebarRef);
      }
    }
  }

  compareArrays(arr1: (string | number)[], arr2: (string | number)[]): boolean {
    // Verifica si ambos son arrays y tienen la misma longitud
    if (Array.isArray(arr1) && Array.isArray(arr2) && arr1.length === arr2.length) {
      // Utiliza every para comparar los elementos
      return arr1.every((valor, indice) => valor === arr2[indice]);
    } else {
      // Si no son arrays o tienen diferente longitud, son diferentes
      return false;
    }
  }

  openBottomSheet(structure: any) {
    let panelClass = [structure["class"]];
    if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetComponent, {
      panelClass: panelClass,
      data: {
        structure: structure["child"],
      },
    });
  }

  closeBottomSheet(strucuture: any) {
    this.bottomSheetRef.dismiss();
  }

  formatDate(date, withSeconds = true) {
    return this.genericService.formatDate(date, withSeconds)
  }

  eachRecursiveBool(obj, validate, idDSB, parentHide = 0): boolean {
    let child = null;
    let formField = 0;
    let database = null;
    let table = null;
    let field = null;
    let value = null;
    let relation = null;
    let form = null;
    let status = true;
    let formValid = true;
    if (this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]]) {
      this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]];
      this.genericService.setStatusToElement(obj, this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]]);
      delete this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]];
    }
    if (obj['child'] && typeof obj['child'] === 'object') child = obj['child'];
    if (obj['form_field']) formField = obj['form_field'];
    if (obj['id_db']) database = obj['id_db'];
    if (obj['bd_table']) table = obj['bd_table'];
    if (obj['bd_field']) field = obj['bd_field'];
    if (obj['tmp_value'] || obj['tmp_value'] === 0) {
      value = obj['tmp_value'];
      if (value === undefined) value = null;
      if ((value == "" || value === undefined) && field == "id_product_mkt") value = 0;
      if (value == "" && field == "id_db") value = null;
    }
    if (obj['id_table_relations']) relation = obj['id_table_relations'];
    if (obj['form']) form = obj['form'];
    if (obj["id_functional_parent_initial_dsb"] != idDSB) {
      status = true;
      if (child) {
        for (let j in child) {
          if ((status && (child[j] && child[j]["id_functional_status_general"] && child[j]["id_functional_status_general"] != 2) || this.hasDSB(child[j]))) status = this.eachRecursiveBool(child[j], validate, idDSB, obj["hide"]);
        }
      }
      return status;
    }

    this.lastLabel = obj["label"];

    if (obj["id_functional_type"] == 15) {
      obj["multiple"] == 0;
      if (!value) value = 0;
    }
    if (obj["id_functional_status_general"] != 3 && parentHide != 1 && obj["hide"] != 1 && form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) formValid = form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]["valid"];
    if (obj["id_functional_type"] != 113 && obj["id_functional_type"] != 146) {
      if (formField == 1 && form !== null && !formValid && (form["value"][obj["id_functional_area"] + "-" + field] === null || form["value"][obj["id_functional_area"] + "-" + field] == "") && obj["id_db"] !== null && obj["bd_table"] !== null) {
        form["controls"][obj["id_functional_area"] + "-" + field]["touched"] = true;
        if (obj["id_functional_type"] == 15 || obj["id_functional_type"] == 12 || obj["id_functional_type"] == 6) obj["invalid"] = true;
        if (status) {
          let validation = true;
          if (form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = formValid;
          else console.log("!!! NO CONTROL !!! => FIELD NO FORM", obj["id_functional_area"])
          if (!validate) validation = true;
          if (obj["bd_table"] !== null) {
            if (obj["id_functional_type"] == 6 && (obj["type"] == "datepicker" || obj["type"] == "datepicker-month" || obj["type"] == "datepicker-year" || obj["type"] == "datetime-local")) {
              let ndate = this.formatDate(value);
              value = ndate;
            }
            if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
              value = value + ":00";
            }
            if (obj["type"] == "email" && value && value != "") {
              if (!this.validateEmail(value) && validate) {
                validation = false;
                this.errorMessage = "El email es inválido";
              } else {
                validation = true;
                this.errorMessage = "";
              }
            }
            // Gestionar el caracter decimal
            if (obj["type"] == "decimal" && value && value != "" && typeof value == "string") {
              value = value.replace(",", ".");
            }
            if (obj["multiple"] == 1) {
              let found = false;
              for (let i in obj["originalValue"]) {
                found = false;
                for (let j in obj["tmp_value"]) {
                  if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                    found = true;
                  }
                }
                if (!found) {
                  this.removed.push({
                    functionalArea: obj["id_functional_area"],
                    database: database,
                    table: table,
                    field: field,
                    value: obj["originalValue"][i],
                    relation: relation,
                    label: obj["label"],
                  });
                }
              }
              if (obj["id_functional_status_general"] == 5 && obj["id_functional_type"] == 12 && obj["type"] == "autocomplete" && (!obj["tmp_value"] || !Array.isArray(obj["tmp_value"]) || obj["tmp_value"].length < 1) && validate) validation = false;
            }
          }
          if (obj["id_functional_type"] == 121) {
            this.extractGeoFields(obj, database, table, relation, validation);
          }
          if (obj["id_functional_type"] != 121 && obj["timestamp"] === 0) {
            // -999
            //if(obj['id_functional_type'] == 12 && value == "") value = null;
            this.tmpArray.push({
              functionalArea: obj["id_functional_area"],
              database: database,
              table: table,
              field: obj["label"],
              value: value,
              relation: relation,
              valid: validation,
              label: obj["label"],
            });
          }
        }
        if (validate) status = false;
      } else if (formField == 1 && status && obj["id_functional_type"] != 113 && obj["id_db"] !== null && obj["bd_table"] !== null) {
        let validation = true;
        if (form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = formValid;
        else console.log("!!! NO CONTROL !!! => FIELD NO FORM", obj["id_functional_area"])
        if (validation && obj["id_functional_status_general"] == 5 && value == 0 && obj["type"] !== "number" && form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = false;
        if (!validate) validation = true;
        if (obj["bd_field"] !== null) {
          if (obj["id_functional_type"] == 6 && (obj["type"] == "datepicker" || obj["type"] == "datepicker-month" || obj["type"] == "datepicker-year" || obj["type"] == "datetime-local")) {
            let ndate = this.formatDate(value);
            value = ndate;
          }
          if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
            value = value + ":00";
          }
          if (obj["type"] == "email" && value && value != "") {
            if (!this.validateEmail(value) && validate) {
              validation = false;
              this.errorMessage = "El email es inválido";
            } else {
              validation = true;
              this.errorMessage = "";
            }
          }
          // Gestionar el caracter decimal
          if (obj["type"] == "decimal" && value && value != "" && typeof value == "string") {
            value = value.replace(",", ".");
          }
          if (obj["multiple"] == 1) {
            if(obj["id_functional_type"] == 121) {
              obj["originalValue"] = obj["tmp_value_init"];
            }
            let found = false;
            for (let i in obj["originalValue"]) {
              found = false;
              for (let j in obj["tmp_value"]) {
                if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                  found = true;
                }
              }
              if (!found) {
                this.removed.push({
                  functionalArea: obj["id_functional_area"],
                  database: database,
                  table: table,
                  field: field,
                  value: obj["originalValue"][i],
                  relation: relation,
                  label: obj["label"],
                });
              }
            }
            if (obj["id_functional_status_general"] == 5 && obj["id_functional_type"] == 12 && obj["type"] == "autocomplete" && (!obj["tmp_value"] || !Array.isArray(obj["tmp_value"]) || obj["tmp_value"].length < 1) && validate) validation = false;
          }
        }
        if (obj["id_functional_type"] == 121) {
          this.extractGeoFields(obj, database, table, relation, validation);
        }

        if (obj["id_functional_type"] != 121 && obj["bd_field"] !== null && obj["timestamp"] === 0) {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: value,
            relation: relation,
            valid: validation,
            label: obj["label"],
          });
        }
        status = true;
        if (!validation && validate) status = false;
      } else if (child && obj["id_functional_type"] != 113) {
        for (let j in child) {
          if (status && ((child[j] && child[j]["id_functional_status_general"] && child[j]["id_functional_status_general"] != 2) || this.hasDSB(child[j]))) status = this.eachRecursiveBool(child[j], validate, idDSB, obj["hide"]);
        }
      }
    }

    if (!status && obj["id_functional_type"] == 7) {
      obj["expansion_start_opened"] = 1;
    }

    if (!status && obj["id_functional_type"] == 95) {
      let fa = this.genericService.findElementWithId(obj["id_functional_area"], true, false, false);
      fa["parent"]["selectedIndex"] = fa["key"];
    }

    if (status && obj["id_functional_type"] == 113) {
      if (obj["fileList"] !== null && obj["fileList"] !== undefined && obj["fileList"].length > 0) {
        console.log("FILELIST", obj["fileList"], obj);
        this.imageUpload.push(obj);
        let p = 0;
        let tempFile = [];
        if (obj["fileList"].length > 1) {
          for (let t in obj["fileList"]) {
            tempFile.push(this.imageUpload.length - 1 + p);
            p++;
          }
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: tempFile,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        } else {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: this.imageUpload.length - 1,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        }
      } else if ((obj["required"] || obj["id_functional_status_general"] == 5) && obj["bd_field"] !== null) {
        console.log("FILELIST error", obj["fileList"], obj);
        if (validate) status = false;
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: "archivos",
          value: value,
          relation: relation,
          valid: false,
          label: obj["label"],
        });
      }
    }

    if (obj["id_functional_type"] == 146) {
      if (obj["tmp_value_signature"].toData().length !== 0) {
        obj["tmp_value"] = obj["tmp_value_signature"].toDataURL();
        value = obj["tmp_value"];
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: field,
          value: value,
          relation: relation,
          valid: true,
          label: obj["label"],
        });
      } else {
        if (validate) status = false;
      }
    }

    if (!validate) return true;
    return status;
  }

  hasDSB(struct) {
    if (struct && struct["child"] && struct["child"] !== null && struct["child"] !== undefined) {
      for (let t in struct["child"]) {
        if (struct["child"][t]["isFloatingElement"] && struct["child"][t]["isFloatingElement"] == 1) return true;
      }
      return false;
    }
  }

  private extractGeoFields(obj, database, table, relation, validation) {
    let geo_country_code = null;
    let geo_admin1_code = null;
    let geo_admin2_code = null;
    let geo_admin3_code = null;
    let geo_admin4_code = null;
    let name = null;
    let fullname = null;

    if (obj["tmp_value"] !== null && obj["tmp_value"] !== undefined) {
      if (!obj["tmp_value"]["country_code"] && !obj["tmp_value"]["admin1_code"] && !obj["tmp_value"]["admin2_code"] && !obj["tmp_value"]["admin3_code"] && !obj["tmp_value"]["admin4_code"] && !obj["tmp_value"]["name"] && !obj["tmp_value"]["fullname"]) return; // Per si ja hi havia un valor i no s'ha tocat el camp, que no ho maxaqui

      if (obj["tmp_value"]["country_code"] !== null && obj["tmp_value"]["country_code"] !== undefined) geo_country_code = obj["tmp_value"]["country_code"];
      if (obj["tmp_value"]["admin1_code"] !== null && obj["tmp_value"]["admin1_code"] !== undefined) geo_admin1_code = obj["tmp_value"]["admin1_code"];
      if (obj["tmp_value"]["admin2_code"] !== null && obj["tmp_value"]["admin2_code"] !== undefined) geo_admin2_code = obj["tmp_value"]["admin2_code"];
      if (obj["tmp_value"]["admin3_code"] !== null && obj["tmp_value"]["admin3_code"] !== undefined) geo_admin3_code = obj["tmp_value"]["admin3_code"];
      if (obj["tmp_value"]["admin4_code"] !== null && obj["tmp_value"]["admin4_code"] !== undefined) geo_admin4_code = obj["tmp_value"]["admin4_code"];
      if (obj["tmp_value"]["name"] !== null && obj["tmp_value"]["name"] !== undefined) name = obj["tmp_value"]["name"];
      if (obj["tmp_value"]["fullname"] !== null && obj["tmp_value"]["fullname"] !== undefined) fullname = obj["tmp_value"]["fullname"];
    }
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_country_code",
      value: geo_country_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin1_code",
      value: geo_admin1_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin2_code",
      value: geo_admin2_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin3_code",
      value: geo_admin3_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin4_code",
      value: geo_admin4_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_name",
      value: name,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_fullname",
      value: fullname,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
  }

  public getTodayDate() {
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let todayDate = day + "-" + month + "-" + year;

    return todayDate;
  }

  //Usar solo como parametro param.
  public applyFilter(filterValue: string, param: any): void {
    param.dataSource.filter = filterValue.trim().toLowerCase();
    if (param.dataSource.paginator) {
      param.dataSource.paginator.firstPage();
    }
  }

  //Gabri: puede recibir una string o un id como parametro y que corresponda a cada pantalla
  //con este id hacer un switch i adaptar aqui la funcion de format date poniendo esa funcion en el switch
  iniAllDates() {
    //getTodayDate
    //setMinDate
    /*const dt = new Date();
    this.dateToStringDBDate.getFullYear()
      + '-' +  ((this.dateToStringDBDate.getMonth() + 1) < 10 ? '0' : '') + (this.dateToStringDBDate.getMonth() + 1)
      + '-' +  (this.dateToStringDBDate.getDate() < 10 ? '0' : '') + this.dateToStringDBDate.getDate();*/
    let today = new Date();

    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.dateToday = day + "-" + month + "-" + year;

    let monthString = month.toString();
    let dayString = day.toString();
    if (day < 10) dayString = "0" + dayString;
    if (month < 10) monthString = "0" + monthString;
    this.minDate = dayString + "-" + monthString + "-" + (year - 100);
  }

  private findCount(current) {
    let max = 0;
    if (current) {
      for (let child in current["child"]) {
        let ext = 0;
        let max_child = this.findCount(current["child"][child]);
        let id = (current["child"][child]["id_functional_area"] + "").split(".");
        if (id.length > 1) ext = Number(id[1]);
        max = Math.max(ext, max_child);
      }
      return max;
    }
  }

  private duplicateChilds(subtree, count, origin_duplicated_id_functional_area, tmp_data = false) {
    if (subtree) {
      for (let key in subtree["child"]) {
        // this.fixElementDuplicated(subtree["child"][key], subtree["child"][key]['idFAParams'], subtree["child"][key]['idFAParamsInterns'], key);
        if (subtree["child"][key]) this.duplicateChilds(subtree["child"][key], count, origin_duplicated_id_functional_area, tmp_data);

        let idFa = subtree["child"][key]["id_functional_area"];
        if (subtree["child"][key]["id_functional_area_original"] && subtree["child"][key]["id_functional_area_original"] !== undefined) idFa = subtree["child"][key]["id_functional_area_original"];
        subtree["child"][key]["validatedForms"] = false;
        subtree["child"][key]["validatedFrontend"] = false;
        subtree["child"][key]['addedNewElementArray'] = false;
        subtree["child"][key]["id_functional_area"] = idFa + "." + count;
        if (subtree["child"][key]["id_query"]) {
          let query = this.cloneVariable(this.genericService.selectOptions[subtree["child"][key]["id_query"]]);
          let idQuery = this.cloneVariable(subtree["child"][key]["id_query"]).toString();
          let idQuery2 = idQuery.substring(0, idQuery.lastIndexOf('.')) + "." + count;
          if (subtree["child"][key]["ngForIdIndex"] && subtree["child"][key]["ngForIdIndex"] > 0) idQuery = idQuery2;
          else idQuery += "." + count;
          subtree["child"][key]["id_query"] = idQuery;
          this.genericService.selectOptions[idQuery] = query;
        }
        subtree["child"][key]["ngForIdIndex"] = count;
        subtree["child"][key]["id_functional_parent"] = subtree["child"][key]["id_functional_parent"] + "." + count;
        subtree["child"][key]["origin_duplicated_id_functional_area"] = origin_duplicated_id_functional_area + "." + count;
        subtree["child"][key]["duplicate_id_functional_area"] = subtree["child"][key]["duplicate_id_functional_area"] + "." + count;
        subtree["child"][key]["duplicated_frontend"] = 1;
        subtree["child"][key]["ngForId"] = origin_duplicated_id_functional_area + "." + count;
        subtree["child"][key]['initializedFormAttribute'] = false;
        subtree["child"][key]['initializedElementFirstTime'] = false;
        if (this.genericService.elementsThatAffectOthers[subtree["child"][key]["id_functional_area"]]) this.genericService.elementsThatAffectOthers[subtree["child"][key]["id_functional_area"]] = this.cloneVariable(this.genericService.elementsThatAffectOthers[subtree["child"][key]["id_functional_area"]]);

        for (let i in this.genericService.affectedElements) {
          for (let j in this.genericService.affectedElements[i]) {
            if (Object.keys(this.genericService.affectedElements[i][j]).includes(subtree["child"][key]["id_functional_area"])) {
              this.genericService.affectedElements[i][subtree["child"][key]["id_functional_area"]] = this.genericService.affectedElements[i][j];
            }
          }
        }
        if (tmp_data == null || tmp_data == false || (subtree["child"][key] && !(subtree["child"][key]["bd_field"] == tmp_data["field"] && subtree["child"][key]["bd_table"] == tmp_data["table"] && subtree["child"][key]["id_db"] == tmp_data["database"]) && (subtree["child"][key]["id_table_relations"] == 0 || subtree["child"][key]["id_table_relations"] == null))) {
          subtree["child"][key]["tmp_value"] = "";
          if (subtree["child"][key]["bd_field"] != "" && subtree["child"][key]["form"] !== undefined && subtree["child"][key]["form"]["controls"] !== undefined) {
            if (subtree["child"][key]["form"]["controls"][subtree["child"][key]["bd_field"]]) {
              subtree["child"][key]["form"]["controls"][subtree["child"][key]["bd_field"]]["value"] = "";
            }
          }
        }
      }
    }
  }

  private findElementWithDb(current, data) {
    if (current && current["child"]) {
      let val = {};
      current = current["child"];
      for (let i in current) {
        if (current[i] && current[i]["bd_field"] == data["field"] && current[i]["bd_table"] == data["table"] && current[i]["id_db"] == data["database"]) {
          return { parent: current, key: i, child: current[i] };
        } else {
          let data2 = this.findElementWithDb(current[i], data);
          if (data2) val = data2;
        }
      }
      return val;
    }
  }

  private setTmpValueToElement(element, data, index, isArray) {
    if (isArray) {
      element["tmp_value"] = data["value"][index];
    } else {
      element["tmp_value"] = data["value"];
    }
  }

  public checkPermissions(structure, finished) {
    if (!structure["id_functional_status_mkt"]) return true;
    const mkt_status = structure["id_functional_status_mkt"];
    switch (mkt_status) {
      case 1:
        //this.genericService.openSnackBar("¡Todo bien!", 2000, ["green-snackbar"]);
        return true;
      //break;
      case 2:
        this.genericService.openSnackBar("¡Vaya, parece que no te quedan unidades!", 2000, [
          "red-snackbar",
        ]);
        this.genericService.finishFunction(finished);
        break;
      case 3:
        this.genericService.openSnackBar(
          "¡Vaya, parece que este producto ha caducado!",
          2000,
          ["red-snackbar"]
        );
        this.genericService.finishFunction(finished);
        break;
      case 4:
        this.genericService.openSnackBar(
          "¡Vaya, parece que no has comprado este producto!",
          2000,
          ["red-snackbar"]
        );
        this.genericService.finishFunction(finished);
        break;
      case 5:
        this.genericService.openSnackBar(
          "¡Vaya, parece que este producto no está disponible!",
          2000,
          ["red-snackbar"]
        );
        this.genericService.finishFunction(finished);
        break;
      case 6:
        this.genericService.openSnackBar(
          "¡Vaya, parece que este producto no está disponible para tu tipo de empresa!",
          2000,
          ["red-snackbar"]
        );
        this.genericService.finishFunction(finished);
        break;
    }

    return false;
  }

  public duplicateElementUnified(structure, finished, hasParameters = false, type = 0) {
    let node, tmp_data;
    let size = 0;
    if (type == 1) size = this.tmpArray.length;
    if (size == 0) size = 1;

    // Controlaremos el size
    for (let i = 0; i < size; ++i) {
      if (this.tmpArray[i]) tmp_data = this.tmpArray[i];
      let length = 1; //Inicialitzem a 1 pel cas de que no tingui parametres
      console.log("tmp_data", this.tmpArray, tmp_data);
      if (hasParameters && tmp_data && tmp_data.value && Array.isArray(tmp_data.value)) {
        length = tmp_data.value.length; // en cas de que sigui form amb parametres s'actualitza el size
      }
      for (let j = 0; j < length; ++j) {
        if (hasParameters && tmp_data) tmp_data["route_param"] = this.routingService.routeParam;
        node = this.genericService.findElementWithId(structure["duplicate_id_functional_area"], true, true, false);
        console.log("node !!!", node, structure["duplicate_id_functional_area"])
        if (node == null) continue;
        let max = this.findCount(node["parent"]) + 1;
        let subtree2 = this.cloneVariable(node["child"]);
        this.fixElementDuplicated(subtree2, subtree2['idFAParams'], subtree2['idFAParamsInterns'], max);
        this.duplicateChilds(subtree2, String(max), structure["duplicate_id_functional_area"]);
        let element;
        if (hasParameters && tmp_data) element = this.findElementWithDb(subtree2, tmp_data);

        console.log(node, "node", structure["duplicate_id_functional_area"], structure);
        node["parent"]["child"][max] = subtree2; // asigno el nou subtree duplicate
        node["parent"]["child"][max]["id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["id_functional_status_general"] = 1;
        node["parent"]["child"][max]["origin_duplicated_id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["duplicate_id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["duplicated_frontend"] = 1;
        node["parent"]["child"][max]["ngForId"] = node["parent"]["child"][max]["id_functional_area"];
        node["parent"]["child"][max]["ngForIdIndex"] = max;
        node["parent"]["child"][max]['validatedForms'] = false;
        node["parent"]["child"][max]['validatedFrontend'] = false;
        node["parent"]["child"][max]['addedNewElementArray'] = false;
        node["parent"]["child"][max]['initializedFormAttribute'] = false;
        node["parent"]["child"][max]['initializedElementFirstTime'] = false;
        //this.genericService.asignFrontend(node["parent"]["child"], node["parent"]["child"][max]['id_functional_parent_initial'], false, node["parent"]['idFAParams'], node["parent"]['idFAParamsInterns']);
        this.genericService.finishLoadedStructure(node["parent"]["child"][max]['id_functional_parent_initial']);

        if (this.genericService.elementsThatAffectOthers[structure["id_functional_area"]]) {
          this.genericService.elementsThatAffectOthers[node["parent"]["child"][max]["id_functional_area"]] = this.cloneVariable(this.genericService.elementsThatAffectOthers[structure["id_functional_area"]]);
        }
        for (let i in this.genericService.affectedElements) {
          for (let j in this.genericService.affectedElements[i]) {
            if (Object.keys(this.genericService.affectedElements[i][j]).includes(structure["duplicate_id_functional_area"])) {
              this.genericService.affectedElements[i][node["parent"]["child"][max]["id_functional_area"]] = this.genericService.affectedElements[i][j];
            }
          }
        }

        if (hasParameters) {
          if (!element || Object.keys(element).length === 0) {
            this.genericService.openSnackBar(
              "No existe ningun elemento con id_db, bd_table, bd_field",
              2000,
              ["red-snackbar"]
            );
          } else {
            this.setTmpValueToElement(element["child"], tmp_data, j, Array.isArray(tmp_data["value"]));
          }

          let value_tmp = tmp_data["value"];
          if (Array.isArray(tmp_data["value"])) value_tmp = tmp_data["value"][j];
          let params = {
            id_functional_area: structure["duplicate_id_functional_area"],
            id_language: this.authService.languageId,
            id_query_language: node["parent"]["child"][max]["id_query_language"],
            onlyOwner: structure["only_owner"] && structure["only_owner"] == 1 && structure["id_owner_field_filter"],
            id_owner_field_filter: structure["id_owner_field_filter"],
            tmp_data: {
              database: tmp_data["database"],
              field: tmp_data["field"],
              relation: tmp_data["relation"],
              table: tmp_data["table"],
              valid: tmp_data["valid"],
              value: value_tmp,
              route_param: tmp_data["route_param"],
            },
          };
          this.endpointService.parseLanguageTag(params).subscribe((data) => {
            let response = data["response"];
            let keys = Object.keys(response);
            keys.forEach((key) => {
              if (key != "id_functional_area") node["parent"]["child"][max][key] = response[key];
            });
            if (Array.isArray(tmp_data["value"])) tmp_data["value"][j] = "";
            else tmp_data["value"] = "";
            this.genericService.finishFunction(finished);
          });
        }

        // Construim de nou els forms del child per no tenir conflictes en les referencies
        node['parent']['validatedForms'] = true;
        this.genericService.initElementInFrontend(node["parent"]["child"][max], max, node["parent"], true);
        if (node["parent"]['id_functional_status_general'] == 2) node["parent"]['id_functional_status_general'] = node["parent"]['id_functional_status_initial'];
        this.genericService.finishFunction(finished);
        if (node && node !== null) {
          //this.updateFormHistory(node["parent"]["child"][max], node["parent"]["id_functional_parent_initial_dsb"], node["parent"], node["parent"]['id_functional_area'], "duplicated", null, null, null, null, null, node["parent"]["child"][max]["label"], node["parent"]["child"][max]["label"], hasParameters);
        }
      }
    }
  }

  private fixElementDuplicated(element, arrayParams, arrayParamsInterns, key) {
    if (this.genericService.checkFields(element)) {
      if (element['param'] && element['param'] == 1) arrayParams.push(element['id_functional_area']);
      if (element['param_intern'] && element['param_intern'] == 1) arrayParamsInterns.push(element['id_functional_area']);
    }
    element['idFAParams'] = arrayParams;
    element['idFAParamsInterns'] = arrayParamsInterns;
    this.genericService.addNewElementArray(element, key);
  }

  public async deleteFunctionalArea(structure) {
    if (structure["ngForId"]) {
      const id = structure["ngForId"];
      if (structure !== null) {
        const confirm = await this.genericService.openWarningDialog(4, 2, structure);
        if (confirm) {
          console.log("structure", structure, id)
          this.genericService.findElementWithIdAndSetStatus(id, 2);
          if (structure["duplicated_frontend"] != 1) {
            let subStructure = structure;
            if (structure["id_functional_type"] == 5) subStructure = this.genericService.findElementWithId(structure["id_functional_parent"], false, false, true);
            let formFields = this.getParamsFA(subStructure);
            this.endpointService.deleteData(formFields).subscribe((data) => {
              this.genericService.openSnackBar(
                data["errorMessage"],
                7000,
                data["errorCode"] == 0 ? ["green-snackbar"] : ["red-snackbar"]
              );
            });
          }
        }
      }
    }
  }

  public insertFormData(structure, param, finished, type, publicar, valuesLoaded = false) {
    let validReq = true;
    if (!valuesLoaded) validReq = this.formsArray(structure, param);
    if (validReq) {
      if (publicar !== null) this.updateStatusVivienda(publicar);
      let dataAcc = this.genericService.getDataAccount();
      let pkWindowParam = null;
      let pkWindowParamTable = null;
      let pkWindowParamFeld = null;
      let initialFa = this.genericService.getActualInitialFA(structure);
      if(this.genericService.paramControlVariables[initialFa] && this.genericService.paramControlVariables[initialFa]['indexParam'] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParam']) pkWindowParam = this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParam'];
      if(this.genericService.paramControlVariables[initialFa] && this.genericService.paramControlVariables[initialFa]['indexParam'] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamTable']) pkWindowParamTable = this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamTable'];
      if(this.genericService.paramControlVariables[initialFa] && this.genericService.paramControlVariables[initialFa]['indexParam'] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamField']) pkWindowParamFeld = this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamField'];
      dataAcc['pkWindowParam'] = pkWindowParam;
      dataAcc['pkWindowParamTable'] = pkWindowParamTable;
      dataAcc['pkWindowParamField'] = pkWindowParamFeld;
      let interns = null;
      interns = this.genericService.getInternParam(structure, []);
      let userChanges = this.genericService.getFormsCurrentPage();
      return this.endpointService.insertFormData(dataAcc, this.tmpArray, this.removed, this.imageUpload, interns, userChanges);
    } else {
      this.genericService.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  public updateStatusVivienda(type) {
    for (let tmp in this.tmpArray) {
      if (this.tmpArray[tmp]["field"] == "estado" && this.tmpArray[tmp]["table"] == "vivienda" && this.tmpArray[tmp]["database"] == 1) {
        if (type == 0) {
          if (this.tmpArray[tmp]["value"] == 4) this.tmpArray[tmp]["value"] = 9;
        }
        else if (type == 1) this.tmpArray[tmp]["value"] = 4;
      }
    }
  }

  public saveParams(structure, tableParams, id_functional_parent_initial = 0) {
    if (id_functional_parent_initial == 0) id_functional_parent_initial = this.routingService.moduleId;
    this.routingService.routeParam = [];
    if (!this.genericService.paramControlVariables[id_functional_parent_initial]) {
      this.genericService.paramControlVariables[id_functional_parent_initial] = this.genericService.createNewParamVariable();
    }
    this.generateNewOutputParam(structure);
    let par = this.genericService.paramControlVariables[id_functional_parent_initial];
    if (!par["params"][par["indexParam"]]['output']) par["params"][par["indexParam"]]['output'] = [];

    if (structure["id_functional_type"] != 3) {
      let tableParams2 = null;
      if (tableParams && tableParams["tmp_param"]) tableParams2 = tableParams["tmp_param"];
      this.genericService.findInterns(structure["idFAParams"], par["params"][par["indexParam"]], tableParams2, structure["id_functional_parent_initial_dsb"], "output");
    } else {
      if (tableParams && tableParams["tmp_param"]) this.genericService.getParamInternTable(tableParams["tmp_param"], par['params'][par["indexParam"]], structure, 'output');
    }
    this.routingService.routeParam = this.genericService.paramControlVariables[id_functional_parent_initial]["params"][this.genericService.paramControlVariables[id_functional_parent_initial]["indexParam"]]["output"];
    this.route = structure["internal_routing"];
    this.id_route = structure["internal_routing_id_functional_area"];
    this.routingService.previousModule = id_functional_parent_initial;
  }

  public saveParamsClientOnly(structure, id_functional_parent_initial = 0) {
    // Sirve solamente para poner en el cliente de tipo empresa a clientes.id
    if (id_functional_parent_initial == 0) id_functional_parent_initial = this.routingService.moduleId;
    this.routingService.routeParam = [];
    if (!this.genericService.paramControlVariables[id_functional_parent_initial]) {
      this.genericService.paramControlVariables[id_functional_parent_initial] = this.genericService.createNewParamVariable();
    }
    this.generateNewOutputParam(structure);
    let par = this.genericService.paramControlVariables[id_functional_parent_initial];
    par["params"][par["indexParam"]]['output'] = [{ id_db: 1, bd_table: "clientes", bd_field: "id", value: structure['tmp_value_multi_chip'] }];
    this.routingService.routeParam = par["params"][par["indexParam"]]["output"];
    this.route = structure["internal_routing"];
    this.id_route = structure["internal_routing_id_functional_area"];
    this.routingService.previousModule = id_functional_parent_initial;
  }

  public duplicateFunctionalAreaDB(structure, finished) {
    this.formsArray(structure, finished);
    let idToDuplicate = this.tmpArray[1]["value"];
    let idParent = this.tmpArray[0]["value"];
    let data = { idToDuplicate, idParent };

    this.endpointService.duplicateFunctionalAreaDB(data).subscribe((data) => {
      this.genericService.finishFunction(finished);
      this.genericService.openSnackBar(
        "Â¡LO TENGO, YA TE LO HE DUPLICADO Y SUBIDO! ;)",
        6000,
        ["green-snackbar"]
      );
    });
  }

  public getInternParamDialog(structure, param) {
    let par = {
      id_pantalla: 0,
      params: {
        [0]: {
          intern: []
        }
      },
      indexParam: 0
    };
    if (structure["id_functional_type"] != 3) {
      let paramTable = null;
      if (param && param["param_intern"]) paramTable = param["param_intern"];
      this.genericService.findInterns(structure["idFAParamsInterns"], par["params"][par["indexParam"]], paramTable, structure["id_functional_parent_initial_dsb"]);
    } else {
      //table
      par["params"][par["indexParam"]]["intern"] = [];
      if (param && param["param_intern"]) this.genericService.getParamInternTable(param["param_intern"], par['params'][par["indexParam"]], structure);
    }
    return par["params"][par["indexParam"]]["intern"];
  }

  public generateNewOutputParam(structure) {
    if (!this.genericService.paramControlVariables[structure['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[structure['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    } else {
      if (!this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'] || !this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'][0]) {
        this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'][0] = this.genericService.initializeParams();
        this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['indexParam'] = 0
      } else {
        let lengthParam = this.genericService.paramControlVariables[structure['indexParam']];
        this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'][lengthParam] = this.genericService.initializeParams();
      }
    }
  }

  findFunctionalArea(structure, id) {
    let str = null;
    if (structure["id_functional_area"] == id) str = structure;
    else {
      if (structure["child"]) {
        for (let e in structure["child"]) {
          if (str === null)
            str = this.findFunctionalArea(structure["child"][e], id);
        }
      }
    }
    return str;
  }

  revertValues(structure) {
    this.genericService.revertDuplicateFromValue(structure["id_functional_parent_initial"], null, structure);
  }

  deleteDataDialog(fa, type, finished) {
    if (this.checkPreviewModeFunction()) return;
    let dialog = this.genericService.findElementWithId(fa["id_functional_parent_initial_dsb"], false, false, true);
    let formFields = this.getParamsFA(dialog);
    this.endpointService.deleteData(formFields).subscribe((data) => {
      this.genericService.openSnackBar(
        data["errorMessage"],
        7000,
        data["errorCode"] == 0 ? ["green-snackbar"] : ["red-snackbar"]
      );
      if (data["errorCode"] == 0) {
        if (type == 0) {
          this.genericService.refreshStructure(0);
        } else if (type == 4) {
          this.genericService.refreshStructure(2);
        } else if (type == 5) {
          this.route = fa["internal_routing"];
          this.id_route = fa["internal_routing_id_functional_area"];
          this.routingService.previousModule = fa["id_functional_parent_initial"];
          this.goRouting(this.route, true);
        } else {
          let resStruct = null;
          let idDialogParent = this.genericService.findElementWithId(fa["internal_routing_id_functional_area"], true, false, true);
          if (type == 1) {
            resStruct = { child: Array(idDialogParent), id_function: 2 };
          }
          if (type == 2) {
            resStruct = { child: Array(idDialogParent), id_function: 45 };
          }
          if (type == 3) {
            resStruct = { child: Array(idDialogParent), id_function: 35 };
          }
          if(resStruct) return {structure: resStruct, param: null, finished, getFunction: true};
        }
      }
    });
  }

  public reloadQueries(structure, param, finished, searchEmpresa = 0) {
    let faWithQueriesToRefresh = structure["internal_routing_id_functional_area"];
    let validReq = this.formsArray(structure, param, false);
    let allData = {
      paramInterns: this.genericService.getInternParam(structure, param),
      actualValueFields: JSON.parse(JSON.stringify(this.tmpArray)),
      dataAccount: this.genericService.getDataAccount(),
      faWithQueriesToRefresh: faWithQueriesToRefresh,
      staticHTML: this.genericService.staticHTML,
    };

    if (searchEmpresa > 0) {
      let actualNgForId = this.getNgForId(structure);

      let idFaVivienda = this.genericService.findFAWithTableField(1, "vivienda", "id_empresa", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      if (idFaVivienda && idFaVivienda['tmp_value']) allData['dataAccount']['idCompanyGeneric'] = idFaVivienda['tmp_value'];

      let idFaClientes = this.genericService.findFAWithTableField(1, "clientes", "id_empresa", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      if (idFaClientes && idFaClientes['tmp_value']) allData['dataAccount']['idCompanyGeneric'] = idFaClientes['tmp_value'];
    }

    this.endpointService.reloadQueries(allData).subscribe((data) => {
      if (data["response"] != null && data["response"] != "") {
        //Refrescamos el service de queries
        for (let key in data["response"]) {
          let idQuery = data["response"][key]["idQuery"];
          if (structure['ngForIdIndex'] && structure['ngForIdIndex'] != -1) {
            idQuery = idQuery + "." + structure['ngForIdIndex'];
          }
          this.genericService.selectOptions[idQuery] = data["response"][key]["result"];
          this.genericService.selectOptionsHeader[idQuery] = data["response"][key]["result"];
          this.genericService.selectOptionsFooter[idQuery] = data["response"][key]["result"];
          this.genericService.selectOptionsChange = !this.genericService.selectOptionsChange;
          this.genericService.selectOptionsChangeEvent.next(this.genericService.selectOptionsChange);
          this.getFunctionalAreaFindElementsWithIdQueryAndAssignValuesLoop(idQuery, data["response"][key]["result"], structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        }
      }
      this.genericService.finishFunction(finished);
    });
  }

  public getFunctionalAreaFindElementsWithIdQueryAndAssignValuesLoop(idQuery, value, idDSB, NGForIndex) {
    if(!NGForIndex) NGForIndex = -1;
    if (idQuery != null && value != null) {
      let current = this.genericService.getAllStructures(1);
      for (let i in current) {
        if (current[i] && current[i]["id_query"] && current[i]["id_query"] == idQuery && current[i]["form_field"] == 1) {
          if((NGForIndex == -1 || NGForIndex == current[i]['duplicate_id_functional_area']) && current[i]["id_functional_parent_initial_dsb"] == idDSB) {
            this.treatFAWhenQuery(current[i], value);
          }
        }
      }
    }
  }

  public treatFAWhenQuery(current, value) {
    if (value.length && value.length == 1 && (value[0]["value"] || value[0]["value"] === 0) && value[0]["value"] != -1) {
      if (!current['tmp_value'] || value[0]["value"] != current['tmp_value']) this.assignValueFunctionalArea(current, value[0]["value"]);
    } else if (current["tmp_value"] && current["multiple"] !== 1) {
      let founded = false;
      for (let i in value) {
        if (current["tmp_value"] == value[i]["value"]) {
          founded = true;
          break;
        }
      }
      let currentValue = current["form"]["controls"][current["id_functional_area"] + "-" + current["bd_field"]];
      if (!founded && currentValue != -1 && current['tmp_value'] != -1) {
        this.assignValueFunctionalArea(current, -1);
      }
    } else if ((current["tmp_value_init"] || current["tmp_value_init"] === 0) && current["multiple"] !== 1) {
      let foundedInit = false;
      for (let i in value) {
        if (current["tmp_value_init"] == value[i]["value"]) {
          foundedInit = true;
          break;
        }
      }
      if (!foundedInit && current['tmp_value_init'] != -1) this.assignValueFunctionalArea(current, -1);
      else this.assignValueFunctionalArea(current, current["tmp_value_init"]);
    }

    if (current["id_functional_type"] == 12 || current["id_functional_type"] == 14) {
      if (value.length && value.length > 0) {
        let status = current["id_functional_status_initial"];
        let hide = current["hide_initial"];
        if (this.authService.checkUserIsDeveloping == 1) {
          hide = 0;
        }
        current["id_functional_status_general"] = status;
        current["hide"] = hide;
        this.genericService.updateStatusFormControl(current, status);
        this.changeHide0(current);
      } else {
        current["id_functional_status_general"] = 2;
        current["hide"] = 1;
      }
    } else { // Hay que hacerlo para recargar el componente
      current["refreshing"] = true;
    }
  }

  private getParamsFA(dialog) {
    let params = [];
    params = this.getFormFields(dialog);
    let formFields = [];
    for (let g in params) {
      let formField = {
        database: params[g]["id_db"],
        table: params[g]["bd_table"],
        field: params[g]["bd_field"],
        value: params[g]["tmp_value"],
        relation: 0,
        valid: true,
      };
      formFields.push(formField);
    }
    return formFields;
  }

  getFormFields(subStructure) {
    let aux = [];
    if (subStructure) {
      if (subStructure["form_field"] == 1) aux.push(subStructure);
      if (subStructure["child"])
        for (let w in subStructure["child"])
          aux = aux.concat(this.getFormFields(subStructure["child"][w]));
    }
    return aux;
  }

  validateEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  calculateNewImporteTarifas(structure) {
    let cantidad = 0;
    let precio = 0;
    let importeTotal = 0;
    let idFATarifa = this.genericService.findFAWithTableField(1, 'act_ludic_prereservas', 'id_tarifa', structure['id_functional_parent_initial_dsb']);
    let idFACantidad = this.genericService.findFAWithTableField(1, 'act_ludic_prereservas', 'num_ninos', structure['id_functional_parent_initial_dsb']);
    if (idFACantidad && idFACantidad['tmp_value']) cantidad = idFACantidad['tmp_value'];
    if (idFATarifa) {
      let result = this.genericService.getRegsitrySelectionFromFA(idFATarifa);
      if (result['importe']) precio = result['importe'];
    }
    importeTotal = cantidad * precio;
    importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
    let idFA = this.genericService.findFAWithTableField(1, "act_ludic_prereservas", "total_tarifas", structure["id_functional_parent_initial_dsb"]);
    console.log("DANI2: ", cantidad, precio, importeTotal, idFA);
    this.assignValueFunctionalArea(idFA, importeTotal);
  }

  addFieldToAnotherField(structure) {
    let actualNgForId = this.getNgForId(structure);
    let idFaInitial = this.cloneVariable(structure);
    let valToChangeM = idFaInitial['internal_routing'].split(",");
    for (let i in valToChangeM) {
      let valToChange = valToChangeM[i];
      let splited0Bar = valToChange.split("/");
      // El primer valor de la barra "/" es el campo que se busca para rellenar
      // El segundo valor de la barra "/" es el campo del cual se coje el valor. Si no hay barra es porque el valor se coje del mismo idFa, ya que es un formField
      if (splited0Bar[1] !== undefined) { // Se hace por si hay que cojer el campo de un botón, que no es el mismo idFa el que tiene que llenar
        let splited0 = splited0Bar[1].split(".");
        idFaInitial = this.genericService.findFAWithTableField(splited0[0], splited0[1], splited0[2], structure["id_functional_parent_initial_dsb"], actualNgForId, false);
        valToChange = splited0Bar[0];
      }
      if (idFaInitial['form_field'] == 1 && idFaInitial["tmp_value"]) {
        let val = idFaInitial["tmp_value"];
        if (val['value']) val = val['value'];
        let splited = valToChange.split(".");
        let idFA = this.genericService.findFAWithTableField(splited[0], splited[1], splited[2], idFaInitial["id_functional_parent_initial_dsb"], actualNgForId, false);
        if (!idFA) {
          console.error('"NO SE HA ENCONTRADO EL FA CON EL CAMPO "', splited[0], splited[1], splited[2]);
          console.log(idFA, "DATOS: ", idFaInitial["id_functional_parent_initial_dsb"], actualNgForId);
          // If my element is not in ngFor, check if it is in an ngFor anyway
          this.addFieldToAnotherField_NormalToNgFor(splited, idFaInitial, val);
        } else {
          this.addFieldToAnotherFieldPush(idFA, val);
        }
      }
    }
  }

  addFieldToAnotherField_NormalToNgFor(splited, idFaInitial, val) {
    let idFAs = this.genericService.findFAWithTableField(splited[0], splited[1], splited[2], idFaInitial["id_functional_parent_initial_dsb"], -1, false, false, true);
    if(idFAs) {
      for(let i in idFAs) {
        this.addFieldToAnotherFieldPush(idFAs[i], val);
      }
    }
  }

  addFieldToAnotherFieldPush(idFA, val) {
    if (idFA['id_functional_type'] == 15) {
      val = !(val == '0' || val == "'0'" || val == 'false');
    }
    console.log(idFA, val, "VALOR A ASIGNAR")
    this.assignValueFunctionalArea(idFA, val);
  }


  concatFields(structure) {
    let res = "";
    let actualNgForId = this.getNgForId(structure);
    let idFaInitial = this.cloneVariable(structure);
    // El primer valor de la barra "/" es el campo que se busca para rellenar
    // El segundo valor de la barra "/" es el campo del cual se coje el valor. Si no hay barra es porque el valor se coje del mismo idFa, ya que es un formField
    let splitedBar = idFaInitial['internal_routing'].split("/");
    
    // Cogemos la info del campo destino
    let splitedBarDestination = splitedBar[0].split(".");
    let idFAToSave = this.genericService.findFAWithTableField(splitedBarDestination[0], splitedBarDestination[1], splitedBarDestination[2], idFaInitial["id_functional_parent_initial_dsb"], actualNgForId, false);
    
    //Cogemos la info de los campos a concatenar
    let fieldsToConcat = splitedBar[1].split(",");
    for (let i in fieldsToConcat) {
      let splitedToConcat = fieldsToConcat[i].split(".");
      let idFAToConcat = this.genericService.findFAWithTableField(splitedToConcat[0], splitedToConcat[1], splitedToConcat[2], idFaInitial["id_functional_parent_initial_dsb"], actualNgForId, false);
      if(res == "") res = idFAToConcat["tmp_value"];
      else res = res + " " + idFAToConcat["tmp_value"];
    }
    console.log(idFAToSave, res.toString());
    this.assignValueFunctionalArea(idFAToSave, res.toString());
  }

  assignValueFunctionalArea(idFA, value) {
    this.genericService.assignValueFunctionalArea(idFA, value);
  }

  changeHide0(fa) {
    if (fa['hide'] == 0 && fa['child'] && fa['child'].length > 0) {
      for (let i in fa['child']) {
        fa['child'][i]['hide'] = fa['child'][i]['hide_initial'];
        this.changeHide0(fa['child'][i]);
      }
    }
  }

  saveInsertedValuesAndRouting(actionPost, structure, param, finished, refresh = 0, type = null, publicar = null) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
          let inserts = data["response"];
          for (let i in inserts) {
            if (inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
              if (actionPost == 1) this.addWindowParam(inserts[i]['responseId'], inserts[i]['database'], inserts[i]['bd_table'], inserts[i]['bd_field'], structure['id_functional_parent_initial'], false, 'output');
              else this.addWindowParam(inserts[i]['responseId'], inserts[i]['database'], inserts[i]['bd_table'], inserts[i]['bd_field'], structure['id_functional_parent_initial'], false, 'input');
            }
          }
          if (actionPost == 1) {
            this.route = structure["internal_routing"];
            this.id_route = structure["internal_routing_id_functional_area"];
            this.routingService.previousModule = structure["id_functional_parent_initial"];
            this.goRouting(this.route, false);
          } else {
            this.genericService.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
            this.genericService.refreshStructure();
          }
        } else {
          if (data["errorMessage"] !== null && data["errorMessage"] !== "") this.genericService.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          this.genericService.finishFunction(finished);
        }
      });
    }
  }

  getPaymentData(structure, param) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let paymentData = { id_payment: null, importe: null, id_empresa: this.authService.getIdEmpresa() };
    if (validReq) {
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_reservas" && values[i]["field"] == "id_forma_pago") {
          paymentData.id_payment = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_actividad_precio" && values[i]["field"] == "precio") {
          paymentData.importe = values[i]["value"];
        }
      }
    }
    return paymentData;
  }

  genericFunctionRoutingStatic(structure, param, valiadteForm = false, executeSameWindow = false, firstParams = false) {
    let initialParams = "&&0&&0";
    if(firstParams) initialParams = "";
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = true;
    if (valiadteForm) validReq = this.formsArray(structure, param);
    let fields = [];
    if (structure['internal_routing']) fields = structure['internal_routing'].split(',');
    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + structure['internal_routing_id_functional_area'] + "/sth/" + idEmpresa + "-" + this.authService.userId + initialParams;

    if (validReq) {
      for (let i in fields) {
        let field = fields[i].split('.');
        let found = false;
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == field[0] && intern_data[i]["bd_table"] == field[1] && intern_data[i]["bd_field"] == field[2] && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            url += "&&" + intern_data[i]["value"];
            found = true;
            break;
          }
        }
        if (!found) url += "&&0";
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
      if (executeSameWindow) window.location.href = url;
      else window.open(url);
    }
  }

  valueToInteger(value) {
    if (!value) return 0;
    value = value.toString().replace(',', '.');
    value = +value;
    if (value && value > 0) {
      return value;
    } else {
      return 0;
    }
  }

  selectActivities(structure, finished) {
    let paramIntern = [];
    let intern_data = this.genericService.getInternParam(structure, paramIntern);

    let actividad = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "hotel_actividades" && intern_data[i]["bd_field"] == "id_actividad") {
        actividad = intern_data[i]["value"];
        break;
      }
    }

    if (actividad != null) {
      let idFA = this.genericService.findFAWithTableField(1, "hotel_actividades", "id_actividad", structure["id_functional_parent_initial_dsb"]);
      let actualVal = idFA["form"].get([idFA["id_functional_area"] + "-" + idFA["bd_field"]])["value"];
      let founded = false;
      if (actualVal && actualVal != '' && actualVal != undefined && actualVal.length != undefined) {
        for (let i in actualVal) {
          if (actualVal[i] == actividad) {
            founded = true;
            actualVal.splice(i, 1);
            this.genericService.openSnackBar("¡Actividad eliminada de tu reserva!", 5000, ["red-snackbar"]);
            break;
          }
        }
        if (!founded) {
          actualVal.push(actividad);
          this.genericService.openSnackBar("¡Actividad añadida de tu reserva!", 5000, ["green-snackbar"]);
        }
      } else {
        actualVal = [actividad];
        this.genericService.openSnackBar("¡Habitación añadida a tu pedido!", 5000, ["green-snackbar"]);
      }
      this.assignValueFunctionalArea(idFA, actualVal);
    }
    this.genericService.finishFunction(finished);
  }

  saveTextInmueble(structure, param, finished, type) {
    let idCompany = this.authService.empresaId;
    let language = null;
    let idProperty = null;
    let validReq = this.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));

    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        language = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idProperty = values[i]["value"];
      }
    }

    let valueInsert = -1;
    if (type == 1) {
      valueInsert = this.genericService.findFAWithTableField(1, "whatsapp_cliente_comodin", "mensaje", structure["id_functional_parent_initial_dsb"])["tmp_value"];
    } else if (type == 2) {
      valueInsert = this.genericService.findFAWithTableField(1, "send_buffer_email", "content", structure["id_functional_parent_initial_dsb"])["tmp_value"];
      //valueInsert = this.genericService.findFAWithTableField(1, "email_comodin", "mensaje", structure["id_functional_parent_initial_dsb"])["tmp_value"];
    } else if (type == 3) {
      valueInsert = this.genericService.findFAWithTableField(1, "send_buffer_email", "subject", structure["id_functional_parent_initial_dsb"])["tmp_value"];
      //valueInsert = this.genericService.findFAWithTableField(1, "email_comodin", "asunto", structure["id_functional_parent_initial_dsb"])["tmp_value"];
    }

    let value = -1;
    if (type == 1 || type == 2 || type == 3) {
      value = idProperty;
    }

    this.endpointService
      .saveTextInmueble(value, language, type, valueInsert)
      .subscribe((data) => {
        this.genericService.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
        finished = true;
      });
  }

  async downloadPDF(from) {
    this.downloadingStaticPage = true;
    this.downloadPDFfunction(from);
  }

  downloadPDFfunction(from) {
    setTimeout(() => {
      const pageSizeMM = { // Dimensiones del papel A4 en milímetros
        width: 210,
        height: 297
      };
      const dpi = 300; // Resolución en puntos por pulgada
      const marginMM = 7; // Márgenes en milímetros
      const margin = marginMM * dpi / 25.4; // Convertir milímetros a puntos

      // Calcular el tamaño de la página del PDF en puntos
      let pageSize = {
        width: pageSizeMM.width * dpi / 25.4,
        height: (pageSizeMM.height * dpi / 25.4) - margin
      };
      console.log(pageSize, "pageSizepageSize")
      // Cal que document.getElementsByClassName("generic-type-0")[0] tingui width: 595px; (cas de DINA4)
      // per tal que html2canvas el converteixi en una imatge amb la mateixa amplada del PDF que volem generar
      // per tal que es pugui tallar verticalmente molt fàcilmente sense despproporcionar-lo horitzontalment
      const docDefinition: any = {
        content: [],
        pageSize: pageSize,
        pageOrientation: 'portrait',
        pageMargins: {
          top: margin,
          bottom: margin,
          left: 0,
          right: 0
        }
      };
      //let elements = document.getElementsByClassName("generic-type-3"); // classe de les taules per provar
      let allElements = document.getElementsByClassName("printGenericSection");
      // Take only elements with height > 0
      let elements =  [];
      for (let i = 0; i < allElements.length; i++) {
        const element = allElements[i];
        const height = element.getBoundingClientRect().height;    
        if (height > 0) {
          elements.push(element);
        }
      }
    
      let croppedCanvases = [];
      if (elements.length > 0) {
        console.log("Elements:", elements);
        this.createPDFFromElements(0, from, elements, docDefinition, croppedCanvases, margin);
      } else {
        let elements = document.getElementsByClassName("create-pdf-generic");
        let croppedCanvases = [];
        if (elements.length > 0) {
          this.createPDFFromElements(0, from, elements, docDefinition, croppedCanvases, margin);
        }
      }
    }, 500);
  }

  getAllHeightPDF(lastExtraHeight, pageSize, i) {
    if (lastExtraHeight > 300) {
      return lastExtraHeight + (pageSize * (i - 1));
    } else {
      return pageSize * i;
    }
  }

  /// Recursive function
  createPDFFromElements(index, from, elements, docDefinition, croppedCanvases, margin, lastExtraHeight = 0) {
    const element = elements[index];
    const elementWidth = element.offsetWidth;
    const scaleFactor = Math.min(
      docDefinition.pageSize.width / elementWidth
    );
    const options = { scale: scaleFactor, scrollY: element.offsetTop };
    html2canvas(elements[index] as HTMLElement, options).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1);
      const img = new Image();
      img.src = imgData;

      const imgPromise = new Promise<void>((resolve) => {
        img.onload = () => {
          resolve();
        };
      });

      imgPromise.then(() => {
        let i = 0;
        // Joan: considerMargin
        // Sembla que cal aplicar marges (funciona el tall de pàgina) per seccions/imatges (com taules) que ocupen més d'una pàgina
        // Per seccions/imatges que caben en una pàgina no cal aplicar marges
        // Exemple: Perquè no la talli la secció "Datos bancarios" (salti de pàgina) en el cas: http://localhost:4200/es/Rightplace/compromiso-arriendo/sth/2436-0&&16&&null&&null
        //       podem treure margin, per comptes de margin * 2 (o només margin) el PDF surt més compacte i millor, almenys en aquest cas
        //let pageSize = docDefinition.pageSize.height - margin * 2;
        let applyMargin = (img.height > docDefinition.pageSize.height ? 1 : 0);
        let pageSize = docDefinition.pageSize.height - (margin * 2 * applyMargin);
        let extraHeight = 0;
        let addedExtraHeight = false;
        // Joan: Afegeixo allHeightPdf per poder mostrar-ho al console log
        let allHeightPdf = this.getAllHeightPDF(lastExtraHeight, pageSize, i);
        console.log('allHeightPdf=' + allHeightPdf + ' vs img.height=' + img.height);
        while (allHeightPdf < img.height) { // Aquesta iteració es repeteix quan una secció/imatge ocupa més d'una pàgina. Exemple taules llargues.
          if (addedExtraHeight) {
            // Joan
            //pageSize = docDefinition.pageSize.height - margin * 2;
            pageSize = docDefinition.pageSize.height - (margin * 2 * applyMargin);
          } else if (lastExtraHeight > 300) {
            pageSize = lastExtraHeight;
            addedExtraHeight = true;
          }
          let actualPageHeight = this.getAllHeightPDF(lastExtraHeight, pageSize, i);
          let actualPageHeight1 = this.getAllHeightPDF(lastExtraHeight, pageSize, i + 1);
          // Joan: Separació entre "seccions" - Triar el que es prefereixi. Crec que el segon o tercer
          //let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight + margin*2);
          //let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight + margin);//*2);
          let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight + (margin * applyMargin));//*2);
          //let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight);// + margin);//*2);
          const croppedCanvas = document.createElement('canvas');
          croppedCanvas.width = docDefinition.pageSize.width;
          croppedCanvas.height = height;
          const croppedCtx = croppedCanvas.getContext('2d');
          // Joan: Només cal posar a 0 el sisè i setè argument
          //croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, docDefinition.pageMargins.left, docDefinition.pageMargins.top, docDefinition.pageSize.width, height);
          //croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, docDefinition.pageMargins.left, docDefinition.pageMargins.top, docDefinition.pageSize.width, height > img.height ? img.height : height);
          //croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, docDefinition.pageMargins.left, lastExtraHeight > 0 && actualPageHeight1 == pageSize ? 0 : docDefinition.pageMargins.top, docDefinition.pageSize.width, height);
          croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, 0, 0, docDefinition.pageSize.width, height);
          croppedCanvases.push(croppedCanvas);
          extraHeight = pageSize - height;
          console.log('extraHeight', extraHeight, 'height', height, 'actualPageHeight', actualPageHeight, 'actualPageHeight1', actualPageHeight1, 'pageSize', pageSize, 'i', i, 'img.height', img.height, 'lastExtraHeight', lastExtraHeight, 'margin', margin);
          i++;
          allHeightPdf = this.getAllHeightPDF(lastExtraHeight, pageSize, i);
          console.log('allHeightPdf=' + allHeightPdf + ' vs img.height=' + img.height);
        }

        if (index < elements.length - 1 && index < 9) {
          console.log('index[' + index + '] < elements.length[' + elements.length + '] - 1');
          this.createPDFFromElements(index + 1, from, elements, docDefinition, croppedCanvases, margin, extraHeight);
        } else {
          console.log('index[' + index + '] >= elements.length[' + elements.length + '] - 1');
          for (let i = 0; i < croppedCanvases.length; i++) {
            const imageDefinition = {
              image: croppedCanvases[i].toDataURL('image/png', 1)
            };
            docDefinition.content.push(imageDefinition);
          }

          let nameFile = this.genericService.containerFunctionArea["file_name_staticHTML"];
          console.log('nameFile=[' + nameFile + ']');
          const pdfDoc = pdfMake.createPdf(docDefinition);

          if (from == 0) {
            pdfDoc.download(`${nameFile}.pdf`);
          } else {
            pdfDoc.print();
          }
          this.downloadingStaticPage = false;
        }
      });
    }).catch(error => {
      console.log('Error html2canvas', error);
      this.downloadingStaticPage = false;
    }).finally(() => {
    });
  }

  buildURLStaticReport() {
    let idLanguage = null;
    let nombreReport = null;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idReport = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "statichtml_functional_area" &&
        values[i]["field"] == "internal_name"
      ) {
        nombreReport = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "statichtml_functional_area" &&
        values[i]["field"] == "id_functional_area"
      ) {
        idReport = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    if (nombreEmpresa == null && this.authService.companyGenericName) {
      nombreEmpresa = this.authService.companyGenericName;
    }

    return (
      "https://movin.cloud" +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "&&" +
      idReport +
      "&&0"
    );
  }

  public removeTableRelationWhenValue(structure, param) {
    if ((structure['id_functional_status_general'] != 5 || structure['tmp_value'] !== null) && structure['tmp_value'] !== 'null') {
      if (structure['id_table_relations'] !== 0) {
        structure['id_table_relations_init'] = this.cloneVariable(structure['id_table_relations']);
        structure['id_table_relations'] = 0;
      }
    } else {
      if (structure['id_table_relations_init']) structure['id_table_relations'] = this.cloneVariable(structure['id_table_relations_init']);
    }
  }

  productEgoStart($from, product) {
    if (this.authService.userId > 0) {
      this.endpointService
        .insertPlantillaEgoButtonAppGenericProduct(
          product,
          this.authService.userId,
          this.authService.getIdEmpresa(),
          $from
        )
        .subscribe((data) => {
          let egoButtonApp: any;
          if (data["errorCode"] === 0) {
            egoButtonApp = data["response"][0];
            if (egoButtonApp.id_plantilla_ego == 0) {
              this.snackBar.open("Plantilla no disponible", "x", {
                duration: 6000,
                panelClass: ["red-snackbar"],
              });
            }
          } else {
            this.snackBar.open("No se ha encontrado plantilla", "x", {
              duration: 6000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    } else {
      this.endpointService
        .getIdPlantillaEgoButtonAppGenericProduct(product, $from)
        .subscribe((data) => {
          let idPlantillaEgo: any;
          if (data["errorCode"] === 0) {
            idPlantillaEgo = data["response"];
            if (idPlantillaEgo == 0) {
              this.snackBar.open("Plantilla no disponible", "x", {
                duration: 6000,
                panelClass: ["red-snackbar"],
              });
            } else {
              this.openNoti.emit({ id: idPlantillaEgo, button: false });
            }
          } else {
            this.snackBar.open("No se ha encontrado plantilla", "x", {
              duration: 6000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    }
  }

  findProductFromParams(structure, param) {
    let product;
    let intern_data = {};

    if (structure == null) {
      structure = this.genericService.getAllStructures();
    }

    intern_data = this.genericService.getInternParam(structure, param);

    // Para que los coja, todos deben ser params_interns
    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "product_mkt" &&
        intern_data[i]["bd_field"] == "id_product"
      ) {
        product = intern_data[i]["value"];
      }
      /* SI HAY QUE COGER DE OTRO SITIO TAMBIÉN, SE PONE AQUÍ EL IF*/
    }
    return product;
  }

  copyTableField(structure, param) {
    let columnId = structure["internal_routing"];
    let textToCopy = "";
    for (let t in param) {
      if (t.includes(columnId)) {
        textToCopy = param[t];
      }
    }
    this.clipboard.copy(textToCopy);
    this.genericService.openSnackBar("Copiado en el portapapeles", 3000, ["green-snackbar"]);
  }

  public async goPrev(checkRouting, routing = null, validate = true) {
    if(this.genericService.historyIndex) {
      if(!this.genericService.redirectByTools) {
        const prev = this.genericService.getPreviousPage();
        console.log(prev, 'preeeeev')
        if (prev) {
          if (validate && this.genericService.getNumChanges() > 0) {
            const status = await this.genericService.openWarningDialog(1, 1);
            if (status) {
              this.genericService.updateHistoryIndex(-1);
              this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
              this.genericService.go(prev.url, checkRouting);
            }
          } else {
            this.genericService.updateHistoryIndex(-1);
            this.genericService.go(prev.url, checkRouting);
          }
        } else if (routing !== null) {
          this.goRouting(routing, checkRouting, validate);
        } else {
          this.genericService.refreshStructure(0);
        }
      } else {
        this.genericService.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
      }
    }
  }

  public async goNext(checkRouting) {
    if(this.genericService.historyIndex < this.genericService.history.length - 1) {
      if(!this.genericService.redirectByTools) {
        const next = this.genericService.getNextPage();
        if (next) {
          if (this.genericService.getNumChanges() > 0) {
            const status = await this.genericService.openWarningDialog(1, 1);
            if (status) {
              this.genericService.updateHistoryIndex(1);
              this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
              this.genericService.go(next.url, checkRouting);
            }
          } else {
            this.genericService.updateHistoryIndex(1);
            this.genericService.go(next.url, checkRouting);
          }
        }
      } else {
        this.genericService.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
      }
    }
  }

  getNgForId(structure) {
    return structure["duplicate_id_functional_area"];
    let ngForId = "-1";
    if (structure["ngForId"] && structure["ngForId"] != "-1" && typeof structure["ngForId"] === "string" && structure["ngForId"].includes(".")) {
      ngForId = "0";
      let parts = structure["ngForId"].split(".");
      if (parts && parts[1]) ngForId = parts[1];
    }
    return ngForId;
  }

  cloneVariable(varToClone) {
    return this.genericService.cloneVariable(varToClone);
  }

  loadPaymentData(structure) {
    let validReq = this.formsArray(structure, true, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment") {
        return values[i]["value"];
      }
    }
  }

  updatePaymentMethod(structure, paid) {
    let validReq = this.formsArray(structure, true, false);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let id_payment = null;
      let id_payment_methods = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment") {
          id_payment = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment_methods") {
          id_payment_methods = values[i]["value"];
        }
      }

      if (id_payment !== null && id_payment_methods && paid !== null) {
        this.endpointService.updatePaymentMethod(id_payment, id_payment_methods, paid).subscribe((data) => {
        });

      }

    }
  }

  addWindowParam(val, database, bd_table, bd_field, id_functional_parent_initial, clean = true, where = 'intern', newIndex = false, updateParamControl = true) {
    if (!this.genericService.paramControlVariables[id_functional_parent_initial]) {
      this.genericService.paramControlVariables[id_functional_parent_initial] = this.genericService.createNewParamVariable();
    }

    let params = this.genericService.paramControlVariables[id_functional_parent_initial];

    if (newIndex && params && params['indexParam']) {
      params['indexParam'] = params['params'].length;
    };

    let indexParam = params['indexParam'];
    if (clean) {
      if (params && params['params'] && params['params'][indexParam]) {
        params['params'][indexParam][where] = [];
      } else {
        params['params'][indexParam] = this.genericService.initializeParams();
      }
      let obj = { id_db: database, bd_table: bd_table, bd_field: bd_field, value: val }
      params['params'][indexParam][where].push(obj);
    } else {
      if (!params['params'][indexParam][where]) params['params'][indexParam][where] = [];
      let found = false;
      for (let j in params['params'][indexParam][where]) {
        if (params['params'][indexParam][where][j]['bd_table'] == bd_table && params['params'][indexParam][where][j]['id_db'] == database && params['params'][indexParam][where][j]['bd_field'] == bd_field) {
          params['params'][indexParam][where][j]['value'] = val;
          found = true;
          break;
        }
      }
      if (!found) {
        let obj = { id_db: database, bd_table: bd_table, bd_field: bd_field, value: val }
        params['params'][indexParam][where].push(obj);
      }
    }
    if (updateParamControl) this.genericService.updateParamControl();
  }

  public printCommandRest(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let pedidos = [];
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "rest_pedidos" || values[i]["table"] == "rest_pedidos_productos") && values[i]["field"] == "id_pedido" && values[i]["value"] > 0) {
        if (!pedidos.includes(Number(values[i]["value"]))) {
          pedidos.push(Number(values[i]["value"]));
        }
      }
    }
    if (pedidos.length > 0) {
      for (let i in pedidos) {
        this.printTicketService.printCommandRestaurant(pedidos[i]);
        this.dialog.closeAll();
      }
    }
    else this.genericService.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["red-snackbar"]);
  }

  public printCommandCancelRest(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let pedidos = [];
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "rest_pedidos" || values[i]["table"] == "rest_pedidos_productos") && values[i]["field"] == "id_pedido" && values[i]["value"] > 0) {
        if (!pedidos.includes(Number(values[i]["value"]))) {
          pedidos.push(Number(values[i]["value"]));
        }
      }
    }
    if (pedidos.length > 0) {
      for (let i in pedidos) {
        this.printTicketService.printCommandCanceladosRestaurant(pedidos[i]);
        this.dialog.closeAll();
      }
    }
    else this.genericService.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["red-snackbar"]);
  }

  public printRestCierreInserted(data) {
    console.log("data", data);
    for (let i in data) {
      console.log(data[i], data[i]["bd_table"] == "rest_pedidos_cierres_caja", data[i]["bd_field"] == "id_cierre_caja", data[i]["database"] == 1, data[i]["responseId"], data[i]["responseId"] > 0);
      if (data[i]["bd_table"] == "rest_pedidos_cierres_caja" && data[i]["bd_field"] == "id_cierre_caja" && data[i]["database"] == 1 && data[i]["responseId"] && data[i]["responseId"] > 0) {
        this.printTicketService.printCierreCajaRestaurant(+data[i]["responseId"]);
        break;
      }
    }
  }

  public printRestSnapshotInserted(data) {
    for (let i in data) {
      if (data[i]["bd_table"] == "rest_pedidos_cierres_caja_snapshot" && data[i]["bd_field"] == "id_cierre_caja_snapshot" && data[i]["database"] == 1 && data[i]["responseId"] && data[i]["responseId"] > 0) {
        this.printTicketService.printCierreCajaSnapshotRestaurant(+data[i]["responseId"]);
        break;
      }
    }
  }

  public printRestMovementInserted(data) {
    for (let i in data) {
      if (data[i]["bd_table"] == "rest_pedidos_cierres_caja_movimientos" && data[i]["bd_field"] == "id_movimiento" && data[i]["database"] == 1 && data[i]["responseId"] && data[i]["responseId"] > 0) {
        this.printTicketService.printMovementRestaurant(+data[i]["responseId"]);
        break;
      }
    }
  }

  public openBottomSheetMenu(structure, param) {
    this.bottomSheetRefMenu = this._bottomSheet.open(BottomsheetMenuComponent, {
      data: {
        structure: structure,
        param: param
      },
    });
  }

  public deleteAllDocs(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let value = 0;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == structure["id_db"] && intern_data[i]["bd_table"] == structure["bd_table"] && intern_data[i]["bd_field"] == structure["bd_field"] && intern_data[i]["value"]) {
        value = Number(intern_data[i]["value"]);
        break;
      }
    }

    if (structure["id_db"] && structure["bd_table"] && structure["bd_field"] && value > 0) {
      this.endpointService.deleteAllDocs(structure["id_db"], structure["bd_table"], structure["bd_field"], value).subscribe((data) => {
        this.genericService.openSnackBar(
          "Documentos borrados correctamente",
          7000,
          ["green-snackbar"]
        );
        this.genericService.refreshStructure(0);
      });
    }
  }

  public async downloadAllPictures(structure, param) {
    let path = structure["id_functional_url"]["url"];
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    let idInmueble = null;

    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let files = [];
      let nombres = [];
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda_marketing_imagenes" && values[i]["field"] == "nombre_fichero" && values[i]["value"]) {
          files.push(path + values[i]["value"]);
          nombres.push(values[i]["value"]);
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"]) {
          idInmueble = values[i]["value"];
        }
      }

      const JSZip = require('jszip');
      const zip = new JSZip();

      // Download each file sequentially
      for (let i = 0; i < files.length; i++) {
        try {
          const response = await fetch(files[i]);
          // const response = await fetch(files[i], { mode: 'same-origin'});
          const blob = await response.blob();
          zip.file(`file${i + 1}.txt`, blob);
        } catch (error) {
          console.error(error);
        }
      }

      // Generate and download the ZIP file
      zip.generateAsync({ type: 'blob' })
        .then((zipBlob) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(zipBlob);
          a.download = `imagenes-inmueble-${idInmueble}.zip`;
          a.click();
        })
        .catch((error) => {
          console.error('Error during ZIP file generation:', error);
        });
    }
  }

  public openFile(structure, param, download) {
    let fileName = null;
    if (param !== null) {
      for (let e in param) {
        if (e.split("-")[1] == structure["id_functional_url"]["bd_field"] || e == structure["id_functional_url"]["bd_field"]) {
          fileName = param[e];
        }
      }
    } else {
      fileName = this.genericService.findFAWithTableField(structure["id_functional_url"]["id_db"], structure["id_functional_url"]["bd_table"], structure["id_functional_url"]["bd_field"], structure["id_functional_parent_initial_dsb"])["tmp_value"];
    }
    if (fileName !== null && structure["id_functional_url"]["url"] !== null) {
      if (download) {
        this.endpointService.getFileFromUrl(structure["id_functional_url"]["url"] + fileName, structure["id_functional_url"]["path"] + fileName).subscribe((data) => {
          console.log(data, "data")
          saveAs(new Blob([data]), fileName);
        });
      } else window.open(structure["id_functional_url"]["url"] + fileName);
    } else
      this.genericService.openSnackBar("No se ha encontrado este fichero", 5000, [
        "red-snackbar",
      ]);
  }

  public runClientScriptCron() {
    let id_client_script = this.findFAWithTableField(3, "client_scripts", "id_client_script", 0)['tmp_value'];
      let name = this.findFAWithTableField(3, "client_scripts", "name", 0)['tmp_value'];
  
      this.endpointService.runClientScriptCron(id_client_script, name).subscribe(() => {
        this.openSnackBar(
          "Script corriendo!",
          7000,
          ["green-snackbar"]
        );
        this.refreshStructure(0);
      });
  }

  public vincularGoogleMail(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      this.endpointService.vincularGoogleGmail(values).subscribe((data) => {
        if (data["errorCode"] == 1) window.open(data["response"], "_self");
        this.finishFunction(finished);
        this.dialog.closeAll();
      });
    } else {
      this.finishFunction(finished);
    }
  }

  public getPinWaiter(structure, param, finished, pin, userId) {
    this.endpointService
      .getPinWaiter(pin, userId)
      .subscribe((data) => {
        let isCorrect = 0;
        isCorrect = data["response"][0]["res"];
        if (isCorrect == 1) {
          this.insertUpdateForm(structure, param, finished, 1, false, false);
        }
        else {
          this.openSnackBar("Pin incorrecto", 5000, ["red-snackbar"]);
        }
      });
  }

  public reloadTable(allData, finished) {
    this.endpointService.reloadTable(allData).subscribe((data) => {
      if (data["response"] != null && data["response"] != "") {
        //Refrescamos el service de queries
        for (let key in data["response"]) {
          this.genericService.selectOptions[data["response"][key]["idQuery"]] = data["response"][key]["result"];
          this.genericService.selectOptionsHeader[data["response"][key]["idQuery"]] = data["response"][key]["result"];
          this.genericService.selectOptionsFooter[data["response"][key]["idQuery"]] = data["response"][key]["result"];
          let fake = null;
          this.genericService.selectOptionsChange = !this.genericService.selectOptionsChange;
          this.genericService.selectOptionsChangeEvent.next(this.genericService.selectOptionsChange);
          this.refreshStructure(0);
        }
      }
      this.finishFunction(finished);
    });
  }

  public makePay(structure, id_empresa, amount, value_pk, id_currency) {
    this.endpointService.makePay(id_empresa, amount, structure['id_db'], structure['bd_table'], structure['bd_field'], structure['internal_routing'], value_pk, id_currency).subscribe((data2) => {
      let id_payment = 0;
      if (data2["errorCode"] == 0) { // si s'ha fet l'insert correctament
        id_payment = data2["response"];
        window.open("../" + this.authService.labelLanguage + "/empresa/" + this.checkoutUrl + "/sth/" + String(id_empresa) + "-0&&" + String(id_payment), "_self");
      } else {
        this.openSnackBar(
          "Ha habido un problema al realizar el pago.",
          12000,
          ["red-snackbar"]
        );
      }
    });
  }

  public updateConditionsCustom(finished) {
    this.endpointService
      .updateConditionsCustom(this.arrayConditions)
      .subscribe((data) => {
        this.finishFunction(finished);
        this.openSnackBar("Guardado con éxito", 15000, ["green-snackbar"]);
        if (data["response"] != null && data["response"] != "") {
          let idFA = this.findFAWithTableField(1, "eventos", "consulta_autogenerada_condiciones", 0);
          if (idFA == null || idFA == undefined) idFA = this.findFAWithTableField(1, "eventos_reportes", "consulta_autogenerada_condiciones", 0);
          this.assignValueFunctionalArea(idFA, data["response"]);
        }
      });
  }

  public updatePlantillaElementsCustom(finished) {
    this.endpointService.updatePlantillaElementsCustom(this.templateService.arrayElementsPlantilla, this.templateService.arrayElementsPlantillaSubject, this.templateService.plantillaInfo).subscribe((data) => {
      this.finishFunction(finished);
      if (data["errorMessage"] !== "OK!") {
        this.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
      }
      this.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
    });
  }

  public updateJourneysCreator(cleanElementsJourney, finished) {
    this.endpointService.updateJourneysCreator(cleanElementsJourney).subscribe((data) => {
      this.finishFunction(finished);
      if (data["errorCode"] == 0) {
        this.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
        this.refreshStructure(0);
      } else {
        this.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
      }
    });
  }

  public loadClientsFromFile(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let dataAcc = this.getDataAccount();
      let interns = null;
      interns = this.getInternParam(structure, []);
      this.endpointService.loadClientsFromFile(dataAcc, this.tmpArray, this.removed, this.imageUpload, interns).subscribe((data) => {
        if (data["errorCode"] === 0) {
          this.snackBar.open(
            "Se han importado correctamente todos los clientes.",
            "x",
            { duration: 6000, panelClass: ["green-snackbar"] }
          );
        } else {
          this.snackBar.open(
            "Se han importado todos los usuarios menos los de las filas: " +
            data["errorMessage"] +
            ". Puedes arreglar estas filas y volver a subirlas. Asegura de guardar las filas con error pues este mensaje no lo podrás volver a ver.",
            "x",
            { panelClass: ["red-snackbar"] }
          );
        }
        this.finishFunction(finished);
      });
    } else {
      this.finishFunction(finished);
    }
  }

  duplicatePlantillaEmail(param, finished, structure, from = 0) {
    let validReq = this.formsArray(structure, finished, true);
    if (validReq) {
      let idPlantilla = -1;
      let idNewPlantilla = -1;
      let nombre = null;
      let descripcion = null;
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      for (let i in values) {
        if (from == 0) {
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "id") {
            idPlantilla = values[i]["value"];
          }
        } else {
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails_idiomas" && values[i]["field"] == "id_plantilla") {
            idPlantilla = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "id") {
            idNewPlantilla = values[i]["value"];
          }
        }

        if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "nombre") {
          nombre = values[i]["value"];
        }

        if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "descripcion") {
          descripcion = values[i]["value"];
        }
      }

      this.endpointService.duplicatePlantillaEmail(idPlantilla, this.authService.userId, this.authService.getIdCompany(), idNewPlantilla, nombre, descripcion).subscribe((data) => {
        if (data["errorCode"] === 0) {
          if (from == 0) {
            this.genericService.openSnackBar(
              "Plantilla duplicada correctamente",
              7000,
              ["green-snackbar"]
            );
            this.genericService.refreshStructure(0);
          } else {
            this.genericService.openSnackBar(
              "Plantilla insertada correctamente",
              7000,
              ["green-snackbar"]
            );
            this.genericService.refreshStructure(1);
          }
          finished = true;
          this.genericService.finishFunction(finished);
        }
      });
    }
  }

  public makePayment(structure, param, finished) {
    this.finishFunction(finished);
    let type = null, publicar = null;
    if (structure['internal_routing_id_functional_area'] && structure['internal_routing_id_functional_area'] != "" && structure['internal_routing_id_functional_area'] != 0) this.checkoutUrl = structure['internal_routing_id_functional_area'];
    const v = this.insertFormData(structure, param, finished, type, publicar); // insert bd empresa
    if (v != null) {
      v.subscribe((data) => {
        switch (data["errorCode"]) {
          case 0: // INSERT OK -> hacer insert a payment_empresa
            // Obtenir fields

            //Casos generals generic
            this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

            let id_currency = 1; // TODO is hardcoded
            let id_empresa = this.authService.empresaId;
            let amount = this.genericService.paymentImport;
            let infoInsert = data["response"];
            let value_pk = null;

            for (let i in infoInsert) {
              if (infoInsert[i]['database'] == structure['id_db'] && infoInsert[i]['bd_table'] == structure['bd_table'] && infoInsert[i]['responseId']) {
                value_pk = infoInsert[i]['responseId'];
                break;
              }
            }
            
            //Cridar BE per fer insert a payment_empresa
            if (amount !== null && value_pk !== null) {
              this.makePay(structure, id_empresa, amount, value_pk, id_currency);
            }
            break;
        }
      });
    }
  }

  public deleteAllPictures(structure, param, id_from = 0) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));

    if (validReq) {
      let idInmueble = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idInmueble = values[i]["value"];
          break;
        }
      }
      if (idInmueble && id_from == 0) {
        //Borra totes les imatges
        this.endpointService.deleteAllPictures(idInmueble).subscribe((data) => {
          this.genericService.openSnackBar(
            "Imágenes borradas correctamente",
            7000,
            ["green-snackbar"]
          );
          this.genericService.refreshStructure(0);
        });
      }
      else if (idInmueble && id_from == 1) {
        //Borra només les imatges despublicades
        this.endpointService.deleteUnpublishedPictures(idInmueble).subscribe((data) => {
          this.genericService.openSnackBar(
            "Imágenes borradas correctamente",
            7000,
            ["green-snackbar"]
          );
          this.genericService.refreshStructure(0);
        });
      }
    }
  }

  private extraerVariablesOperation(operation) {
    // Encuentra todas las ocurrencias de palabras entre los operadores
    const regex = /\b\d*\.\b[a-zA-Z_][a-zA-Z0-9_]*(?:\.[a-zA-Z_][a-zA-Z0-9_]*)+\b/g
    let vars = operation.match(regex) || [];
    // Elimina los duplicados
    vars = Array.from(new Set(vars));
    return vars;
  }

  calculateImportAndSetField(structure) {
    let actualNgForId = this.getNgForId(structure);
    let operation = this.cloneVariable(structure['internal_routing']).replaceAll(' ', '');
    let fieldsToOperate = this.extraerVariablesOperation(operation);
    for (let i in fieldsToOperate) {
      let cleanNumber = true;
      let splited = fieldsToOperate[i].split(".");
      let idFA = this.genericService.findFAWithTableField(splited[0], splited[1], splited[2], structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let val: any = 0;
      if (idFA) {
        if (idFA['tmp_value']) {
          val = this.cloneVariable(idFA['tmp_value']);
          if (idFA['id_functional_type'] == 15) {
            val = !(val['value'] == '0' || val['value'] == "'0'" || val['value'] == 'false');
            if (val) val = 1;
            else val = 0;
          } else if (idFA['id_functional_type'] == 12 && splited[3]) {
            let result = this.genericService.getRegsitrySelectionFromFA(idFA);
            if (result[splited[3]]) val = result[splited[3]];
            else val = 0;
          } else if(idFA['id_functional_type'] == 6) {
            if(idFA['type'] == "datepicker" || idFA['type'] == "datepicker-month") {
              val = new Date(this.formatDate(val)).getTime() / (24 * 60 * 60 * 1000); // Num days
            }
          }
        }
      }
      console.log("valval", val, idFA, fieldsToOperate[i]);
      val = val.toString().replaceAll(',', '.');
      val = Math.round((+val + Number.EPSILON) * 100) / 100;
      operation = operation.replaceAll(fieldsToOperate[i], val.toString());      
    }

    operation = operation.toLowerCase();
    operation = operation.toString().replaceAll('ceil', 'Math.ceil');
    operation = operation.toString().replaceAll('round', 'Math.round');
    operation = operation.toString().replaceAll('floor', 'Math.floor');
    operation = operation.toString().replaceAll('abs', 'Math.abs');

    let total = eval(operation);
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    let db = null;
    let table = null;
    let field = null;
    let splited = structure['internal_routing_id_functional_area'].split('.');
    if (splited[0]) db = splited[0];
    if (splited[1]) table = splited[1];
    if (splited[2]) field = splited[2];
    let idFa = this.genericService.findFAWithTableField(db, table, field, structure["id_functional_parent_initial_dsb"], actualNgForId, false);
    this.assignValueFunctionalArea(idFa, total);
  }

  sumRecursiveNgFor(total, db, table, field, idDSB, ngForId, i = 0) {
    let actualNgForId: any = '';
    if (i > 0) actualNgForId = ngForId.substring(0, ngForId.lastIndexOf('.')) + '.' + i;
    let idFa = this.genericService.findFAWithTableField(db, table, field, idDSB, actualNgForId);
    console.log('asdasdasddas', idFa, ngForId, actualNgForId, i, db, table, field, idDSB, actualNgForId)
    if (idFa || i == 0) {
      if (idFa && idFa['tmp_value'] && this.genericService.checkStatus(idFa['id_functional_area'])) total = total + +(idFa['tmp_value'].toString().replaceAll(',', '.'));
      total = this.sumRecursiveNgFor(total, db, table, field, idDSB, ngForId, ++i);
    }
    return total;
  }

  sumAllFieldsAndSetField(structure) {
    let total = this.sumRecursiveNgFor(0, structure['id_db'], structure['bd_table'], structure['bd_field'], structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    let db = null;
    let table = null;
    let field = null;
    let splited = structure['internal_routing_id_functional_area'].split('.');
    if (splited[0]) db = splited[0];
    if (splited[1]) table = splited[1];
    if (splited[2]) field = splited[2];
    let idFa = this.genericService.findFAWithTableField(db, table, field, structure["id_functional_parent_initial_dsb"], -1, false);
    this.assignValueFunctionalArea(idFa, total);
  }

  openInExteranlURL(route) {
    window.open(Values.ROOT_URL + "/" + this.authService.labelLanguage + "/" + route);
  }

  // Getters i setters userService
  public setPassword(param, email) {
    this.userFunctionsService.setPassword(param, email);
  }

  public vincularGoogle() {
    this.userFunctionsService.vincularGoogle();
  }

  public desvincularGoogle() {
    this.userFunctionsService.desvincularGoogle();
  }

  public vincularMicrosoft() {
    this.userFunctionsService.vincularMicrosoft();
  }

  public desvincularMicrosoft() {
    this.userFunctionsService.desvincularMicrosoft();
  }

  // Getters i setters genericService

  public finishFunction(finished) {
    this.genericService.finishFunction(finished);
  }

  public logout(url) {
    this.dialog.closeAll();
    this.authService.logout(url, this.genericService.staticHTML);
  }

  public findFAWithTableField(db, table, field, idDSB, NGForIndex: any = -1, ignoreStatus = true, ignoreFormField = false) {
    return this.genericService.findFAWithTableField(db, table, field, idDSB, NGForIndex, ignoreStatus, ignoreFormField);
  }

  public openSnackBar(msg, duration, panelClass = ["green-snackbar"], hPosition: any = "center", vePosition: any = "bottom") {
    this.genericService.openSnackBar(msg, duration, panelClass, hPosition, vePosition);
  }

  public getInternParam(structure, param, isMassive = 0) {
    return this.genericService.getInternParam(structure, param, isMassive);
  }

  public updateParamControl(newLocal = false) {
    this.genericService.updateParamControl(newLocal);
  }

  public fetchStructureExternalWindow(moduleId: number, refresh = false): any {
    this.genericService.fetchStructureExternalWindow(moduleId, refresh);
  }

  public openAccountMode(callFrom = 0) {
    this.genericService.openAccountMode(callFrom);
  }

  public refreshStructure(type = 0) {
    this.genericService.refreshStructure(type);
  }

  public findInterns(subStructure: any, param, tableParams, idDSB, where = "intern") {
    return this.genericService.findInterns(subStructure, param, tableParams, idDSB, where);
  }

  public findElementWithId(idFA, findParent, findOriginal, returnOnlyChild) {
    return this.genericService.findElementWithId(idFA, findParent, findOriginal, returnOnlyChild);
  }

  public findElementWithIdAndSetStatus(idFA, status) {
    return this.genericService.findElementWithIdAndSetStatus(idFA, status);
  }

  public loadExpansion(row: any, from = 0) {
    this.genericService.loadExpansion(row, from);
  }

  public fillExpansion(row, subStructure) {
    this.genericService.fillExpansion(row, subStructure);
  }

  public getDataAccount() {
    return this.genericService.getDataAccount();
  }

  public updateResults(paramsInterns, id_functional_parent_initial) {
    this.genericService.updateResults(paramsInterns, id_functional_parent_initial);
  }

  public getAllStructures(from = 0, onlyActualWindow = false, acceptDialog = true) {
    return this.genericService.getAllStructures(from, onlyActualWindow, acceptDialog);
  }

  public go(route, checkRouting, id_route = null) {
    this.genericService.go(route, checkRouting, id_route);
  }

  public getHeaderId() {
    return this.genericService.headerId;
  }

  public getHeaderNoLoguedId() {
    return this.genericService.headerNoLoguedId;
  }

  public getFooterId() {
    return this.genericService.footerId;
  }

  public getFooterNoLoguedId() {
    return this.genericService.footerNoLoguedId;
  }

  public getFormsChanged() {
    return this.genericService.formsChanged;
  }

  public getCurrentInitialArea() {
    return this.genericService.currentInitialArea;
  }

  public getParamControlVariables() {
    return this.genericService.paramControlVariables;
  }

  public getSelectOptions() {
    return this.genericService.selectOptions;
  }

  public getSnackbarOpened() {
    return this.genericService.snackbarOpened;
  }

  public getStaticHTML() {
    return this.genericService.staticHTML;
  }

  public getSelectOptionsChange() {
    return this.genericService.selectOptionsChange;
  }

  public getSelectOptionsChangeEvent() {
    return this.genericService.selectOptionsChangeEvent;
  }

  public getSelectOptionsHeader() {
    return this.genericService.selectOptionsHeader;
  }

  public getSelectOptionsFooter() {
    return this.genericService.selectOptionsFooter;
  }

  public getPaymentImport () {
    return this.genericService.paymentImport;
  }
}
