import {Component, Inject, OnInit, ViewChild} from '@angular/core';
//import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {EndpointService} from '../../services/generic/endpoint.service';
import {Strings} from '../../../strings/strings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatStepper} from "@angular/material/stepper";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public firstFormGroup: UntypedFormGroup;
  public secondFormGroup: UntypedFormGroup;
  public thirdFormGroup: UntypedFormGroup;

  @ViewChild('stepper') private stepper: MatStepper;

  public recoverMail: string;
  public code: string;
  public codeChecked: boolean;
  public Strings = Strings.LOGIN;

  public matcher = new MyErrorStateMatcher();
  public firstCtrlEmail = new UntypedFormControl('', [
    Validators.required,
    // Validators.email,
    Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  ]);

  formErrors = {
    'pass1': '',
    'pass2': ''
  };

  validationMessages = {
    'pass1': {
      'required': 'Introduce una contraseña válida. La contraseña tiene que tener al menos 9 caracteres. <br> ',
      'minlength': 'La contraseña tiene que tener al menos 9 caracteres.  <br>',
      'maxlength': 'La contraseña no puede tener más de 16 caracteres. <br>',
      'forbiddenName': 'Someone named "Bob" cannot be a hero.',
      'checkSpecialCharacter': 'Debe tener un carácter especial. <br>',
      'checkLowerCase': 'Debe tener una letra minúscula. <br>',
      'checkUpperCase': 'Debe tener una letra mayúscula . <br>',
      'checkNumber': 'Al menos debe tener un número.  <br>'
    },
    'pass2': {
      'required': 'Introduce una contraseña válida. ',
      'matchPassword': 'Las contraseñas deben ser iguales. '
    }
  };

  edit: boolean;

  constructor(private endpointService: EndpointService,
              private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<ResetPasswordComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.edit = data.edit;
    console.log(this.edit);
    this.recoverMail = data.email;
  }

  ngOnInit() {

    this.codeChecked = false;
    this.firstFormGroup = this.formBuilder.group({
      firstCtrlEmail2: [{value: '', disabled: !this.edit},[Validators.required,
        Validators.email]]
    });

    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      pass1: ['', Validators.required],
      pass2: ['', Validators.required]

//        'pass1': [this.resetPass.password1, [
  //      Validators.required,
    //    Validators.minLength(9),
      //  Validators.maxLength(16),
       // checkNumberValidator(/.*[0-9].*/i),
     // checkSpecialCharacter(/^[\w&.\-]+$/),
      //checkLowerCaseValidator(/[a-z]/),
      //checkUpperCaseValidator(/[A-Z]/)
 /* ]
  ],
    'pass2': [this.resetPass.password2, [
      Validators.required,
      matchPassword(false)
    ]
    ],

  */
    });
  }

  public sendMailToRecoverPassword(): void {
    if (this.firstFormGroup.get('firstCtrlEmail2').hasError('email') || this.firstFormGroup.get('firstCtrlEmail2').hasError('required')) {
      this.snackBar.open('Debe informar una dirección de correo electrónico válida a la que se enviará un código de verificación','X',{
        duration: 6000, panelClass: ['red-snackbar']
      });
      return;
    }
    this.endpointService.sendMailToRecoverPassword(this.recoverMail).subscribe(data => {
      if (data['errorCode'] === 0){
        this.codeChecked = false;
        this.snackBar.open('Se ha enviado un código de verificación a la dirección de correo indicada','X',{
          duration: 6000, panelClass: ['green-snackbar']
        });
        this.stepper.next();
      }else{
        this.snackBar.open('El correo no es válido o no existe','X',{
          duration: 6000, panelClass: ['red-snackbar']
        });
      }
    });
  }

  public checkCode(): void {
    this.endpointService.checkCode(this.recoverMail, this.code).subscribe(
      data => {
        if (data['errorCode'] === 0) {
          this.codeChecked = true;
          this.snackBar.open('Código validado correctamente', 'X',{
            duration: 6000, panelClass: ['green-snackbar']
          });
        }
        else {
          this.snackBar.open('Código incorrecto. Revíselo y vuelva a entrarlo', 'X',{
            duration: 6000, panelClass: ['red-snackbar']
          });
        }
      }
    );
  }

  public submitNewPassword(): void {
    this.endpointService.setNewPassword(this.recoverMail, this.thirdFormGroup.value['pass1']).subscribe(
      data => {
        if (data['errorCode'] !== 0) {
          this.snackBar.open('¡Vaya! Parece que ha habido un problema al actualizar la contraseña', 'X',{
            duration: 6000, panelClass: ['red-snackbar']
          });
        }
      }
    )
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
