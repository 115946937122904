import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {GenericService} from "../../../../services/generic/generic.service";
import { AuthService } from '../../../../services/auth/auth.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-table-views',
  templateUrl: './table-views.component.html',
  styleUrls: ['./table-views.component.scss']
})
export class TableViewsComponent implements OnInit {

  public columns = [];
  public columnsInit = [];
  public loaded = false;
  public nameView = "New view";
  public editingView = null;
  public checkAll = true;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public _bottomSheetRef: MatBottomSheetRef<TableViewsComponent>,
              public genericService: GenericService,
              public authService: AuthService) {
    this.columnsInit = data.columns;
    this.editingView = data.editingView;
  }

  ngOnInit(): void {
    let cols = this.genericService.cloneVariable(this.columnsInit);
    this.nameView = this.editingView ? this.editingView['name'] : "New view";
    for(let i in cols) {
      let obj = {};
      if(cols[i] != "tmp_param" && cols[i] != "param_intern") {
        let column = this.genericService.cloneVariable(cols[i]);
        obj['column'] = column;
        let idFaSplited = this.genericService.cloneVariable(column).split('-');
        let idFa = null;
        if(idFaSplited[0]) idFa = this.genericService.findElementWithId(idFaSplited[0], false, false, true);
        if(idFa['hide_initial'] == 1 && this.authService.checkUserIsDeveloping != 1) continue;
        if(idFa) {
          if(idFa['label']) obj["label"] = idFa['label'];
        }
        if(idFa["type"] == "image") obj["label"] = "--ImageColumn--";
        if(!obj["label"]) continue;
        obj['displayed'] = true;
        if(this.editingView && this.editingView['views']) {
          for(let i in this.editingView['views']) {
            if(this.editingView['views'][i]['column'] == obj['column']) {
              obj['displayed'] = this.editingView['views'][i]['displayed'];
              break;
            }
          }
        }
        if(!obj['displayed']) this.checkAll = false;
        this.columns.push(obj);
      }
    }
    this.loaded = true;
  }

  public closeBottomSheet() {
    this._bottomSheetRef.dismiss({from: 3, columns: this.columns, name: this.nameView});
  }

  public filterTableValues() {
    setTimeout(() => {
      if(this.editingView) {
        this.editingView.views = this.columns;
        this.editingView.active = true;
        this.editingView.name = this.nameView;
        this._bottomSheetRef.dismiss({from: 2, columns: this.columns, name: this.nameView});
      } else {
        this._bottomSheetRef.dismiss({from: 2, columns: this.columns, name: this.nameView});
      }
    });
  }

  public resetFilterTableValues() {
    this._bottomSheetRef.dismiss({from: 1, columns: this.genericService.cloneVariable(this.columnsInit), name: this.nameView});
  }

  updateValue(column, val : boolean) {
    if(val) {
      column['displayed'] = true;
      this.checkIfAllColumnsAreChecked();
    } else {
      column['displayed'] = false;
      this.checkAll = false;
      this.checkIfAllColumnsAreChecked();
    }
  }

  checkIfAllColumnsAreChecked() {
    let allChecked = true;
    for(let i in this.columns) {
      if(!this.columns[i]['displayed']) {
        allChecked = false;
        break;
      }
    }
    this.checkAll = allChecked;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  checkUncheckAll(val: boolean) {
    for(let i in this.columns) {
      this.columns[i]['displayed'] = val;
    }
  }
}
