import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTwoDecimal]'
})
export class TwoDecimalDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp  = new RegExp(/^\d*\,?\d{0,2}$/g);
  //private regex: RegExp = new RegExp(/^\d*(\,\d{0,2})?$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  //private specialKeys: Array<string> = ['Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Del', 'Delete', '-'];
  private specialKeys: Array<string> = ['Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  //private el: ElementRef;  //vs HTMLInputElement;
  private htmlInput: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    //this.el = elementRef; //vs this.el = this.elementRef.nativeElement;
    this.htmlInput = this.elementRef.nativeElement;
  }
  ngOnInit() {
    this.transform(this.elementRef.nativeElement.value);
  }

  transform(value: any) {
    value = value.replace(",", ".");
    if (value && !isNaN(value)) {
      const aux = "" + Math.round(+value * 100);
      //this.elementRef.nativeElement.value = aux.slice(0, -2) + "," + aux.slice(-2); --> No mostra be el 0
      let auxInt = aux.slice(0, -2);
      let auxDec = aux.slice(-2) + "00";
      if (auxInt == "") {
          auxInt = "0";
      }
      auxDec = auxDec.slice(0, 2);
      this.elementRef.nativeElement.value = auxInt + "," + auxDec;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.elementRef.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.elementRef.nativeElement.value;
    const position = this.elementRef.nativeElement.selectionStart;
    const selEnd = this.elementRef.nativeElement.selectionEnd;
    //console.log("position", position);
    let next: string;
    if (event.key == 'Del' || event.key == 'Delete') {
      if (position == selEnd) { // No hi ha mes d'un caracter seleccionat
        next = [current.slice(0, position), current.slice(position+1)].join('');
      }
      else {
        next = [current.slice(0, position), current.slice(selEnd)].join('');
      }
    }
    else if (event.key == 'Backspace') {
      if (position == selEnd) {
        next = [current.slice(0, position-1), current.slice(position)].join('');
      }
      else {
        next = [current.slice(0, position), current.slice(selEnd)].join('');
      }
    }
    else {
      if (position == selEnd) {
        next = [current.slice(0, position), (event.key == 'Decimal') ? ',' : event.key, current.slice(position)].join('');
      }
      else {
        next = [current.slice(0, position), (event.key == 'Decimal') ? ',' : event.key, current.slice(selEnd)].join('');
      }
    }
    //console.log("next", next, position, selEnd);
    //console.log("match", !String(next).match(this.regex));
    if (next && Math.trunc(+next.replace(",", ".")) > (10 ** (+this.htmlInput.maxLength-3)) - 1) {
      event.preventDefault();
    }
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}