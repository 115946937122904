import { Component, OnInit, Input } from '@angular/core';
import { EndpointService } from "app/services/generic/endpoint.service";
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-code-file',
  templateUrl: './code-file.component.html',
  styleUrls: ['./code-file.component.scss']
})
export class CodeFileComponent implements OnInit {
  
  type:string;
  res = null;
  id_client_script: number;
  id_type: number;
  id_controller_model: number;
  name: string;
  index: number;
  struct = null;
  @Input() structure = null;
  code: string = null;
  initCode: string = null;
  public loaded = false;

  editorOptions = {
    theme: 'vs-dark',
    language: 'php',
    automaticLayout: true
  };

  constructor(
    private endpointService: EndpointService,
    public functionsService: FunctionsService,
    public genericService: GenericService,
  ) { }
  
  ngOnInit(): void {    
    for (let i in this.structure) {
      if (this.structure[i]["id_functional_type"] == 157) {
        this.type = this.structure[i]["type"];
      }
    }

    if(this.type == "controller-model") {
      this.id_controller_model = this.genericService.findFAWithTableField(3, "controllers_models", "id_controller_model", 0)['tmp_value'];
      this.id_type = this.genericService.findFAWithTableField(3, "controllers_models", "id_type", 0)['tmp_value'];
      if(this.id_type == 1) this.type = "Controller";
      if(this.id_type == 2) this.type = "Model";
    }
    this.loadCode(this.type);
  }

  loadCode(type: string):void {
    if(type == "client-script") {
      this.id_client_script = this.genericService.findFAWithTableField(3, "client_scripts", "id_client_script", 0)['tmp_value'];
      this.name = this.genericService.findFAWithTableField(3, "client_scripts", "name", 0)['tmp_value'];
      
      this.endpointService.getClientScriptCode(this.id_client_script).subscribe((data) => {
        if (data['response']) {
          this.code = data['response'];
          this.initCode = this.code;
          this.loaded = true;
        }
      });
    }
    
    else if(type == "Controller" || type == "Model") {
      this.endpointService.getControllerModelCode(this.id_controller_model).subscribe((data) => {
        if (data['response']) {
          this.code = data['response'];
          this.initCode = this.code;
          this.loaded = true;
        }
      });
    }
  }

  saveCode(): void {
    if(this.type == "client-script") {
      this.endpointService.saveClientScriptCode(this.id_client_script, this.name, this.code).subscribe(() => {
        this.initCode = this.genericService.cloneVariable(this.code);
        this.genericService.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
      });
    }
    else if(this.type == "Controller" || this.type == "Model") {
      console.log("Aaa: ", this.genericService.findFAWithTableField(3, "controllers_models", "name", 0));
      this.name = this.genericService.findFAWithTableField(3, "controllers_models", "name", 0)['tmp_value'];
      this.endpointService.saveControllerModelCode(this.id_controller_model, this.type, this.code, this.name).subscribe((data) => {
        this.initCode = this.genericService.cloneVariable(this.code);
        this.genericService.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
      });
    }
  }

}
