<span fxLayout="row wrap" fxFlex="100" *ngIf="structure != undefined && structure != null && loaded">
  <span class="generic-creator-add" *ngIf="structure['creatingMode']">
  </span>
  <span *ngIf="structure['child'] != undefined" [ngClass]="{'creating-mode-container' : structure['creatingMode']}"
    [ngStyle]="{'height': structure['height'] ? structure['height'] : 'auto'}"
    [fxLayout]="structure['layout_general'] && structure['layout_general'] == 0 || structure['layout_general'] === null ? 'row_wrap' : structure['layout_general']"
    [fxLayout.xs]="structure['layout_xs'] != 0 ? structure['layout_xs'] : structure['layout_general']"
    [fxLayout.sm]="structure['layout_s'] != 0 ? structure['layout_s'] : structure['layout_general']"
    [fxLayout.md]="structure['layout_general_m'] != 0 ? structure['layout_m'] : structure['layout_general']"
    [fxLayout.lg]="structure['layout_l'] != 0 ? structure['layout_l'] : structure['layout_general']"
    [fxLayout.xl]="structure['layout_xl'] != 0 ? structure['layout_xl'] : structure['layout_general']"
    [fxLayoutAlign]="structure['align_general'] && structure['align_general'] == 0 || structure['align_general'] === null ? 'start' : structure['align_general']"
    [fxLayoutAlign.xs]="structure['align_xs'] != 0 ? structure['align_xs'] : structure['align_general']"
    [fxLayoutAlign.sm]="structure['align_s'] != 0 ? structure['align_s'] : structure['align_general']"
    [fxLayoutAlign.md]="structure['align_m'] != 0 ? structure['align_m'] : structure['align_general']"
    [fxLayoutAlign.lg]="structure['align_l'] != 0 ? structure['align_l'] : structure['align_general']"
    [fxLayoutAlign.xl]="structure['align_xl'] != 0 ? structure['align_xl'] : structure['align_general']" fxFlex="100">
    <ng-container *ngFor="let e of structure['child'] | keyvalue: genericService.returnZero; let i = index;">
      <span
        *ngIf="conditionsClientService.evaluateIfsParams(structure, e.key, this.idTable, this.idRow, this.param) && 
          (structure['child'][e.key]['creatingMode'] || structure['child'][e.key]['id_functional_type'] != 11 || structure['child'][e.key]['id_functional_type'] != 91 || (conditionsClientService.checkChildActiveHideIntialOnly(structure['child'][e.key]) && conditionsClientService.checkChildActive(structure['child'][e.key], false, false))) && 
          structure['child'][e.key]['id_functional_status_general'] !== 2 && structure['child'][e.key] && structure['child'][e.key]['isFloatingElement'] != 1 && !structure['child'][e.key]['showCreator']"
        [fxFlex]="structure['child'][e.key]['width_general'] != 0 ? structure['child'][e.key]['width_general'] : 100"
        [fxFlex.xs]="structure['child'][e.key]['width_xs'] != 0 ? structure['child'][e.key]['width_xs'] : structure['child'][e.key]['width_general']"
        [fxFlex.sm]="structure['child'][e.key]['width_s'] != 0 ? structure['child'][e.key]['width_s'] : structure['child'][e.key]['width_general']"
        [fxFlex.md]="structure['child'][e.key]['width_m'] != 0 ? structure['child'][e.key]['width_m'] : structure['child'][e.key]['width_general']"
        [fxFlex.lg]="structure['child'][e.key]['width_l'] != 0 ? structure['child'][e.key]['width_l'] : structure['child'][e.key]['width_general']"
        [fxFlex.xl]="structure['child'][e.key]['width_xl'] != 0 ? structure['child'][e.key]['width_xl'] : structure['child'][e.key]['width_general']"
        [fxHide]="structure['child'][e.key]['hide'] == 1 && !structure['child'][e.key]['creatingMode']"
        [fxHide.xs]="(structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xs'] == 1) && !structure['child'][e.key]['creatingMode']"
        [fxHide.sm]="(structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_s'] == 1) && !structure['child'][e.key]['creatingMode']"
        [fxHide.md]="(structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_m'] == 1) && !structure['child'][e.key]['creatingMode']"
        [fxHide.lg]="(structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_l'] == 1) && !structure['child'][e.key]['creatingMode']"
        [fxHide.xl]="(structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xl'] == 1) && !structure['child'][e.key]['creatingMode']"
        [ngClass]="(structure['creatingMode']) ? structure['child'][e.key]['class_custom_original'] + ' generic-element ' + structure['child'][e.key]['class_custom'] + ' parentElement generic-form-field-' + structure['child'][e.key]['form_field'] + ' generic-status-' + structure['child'][e.key]['id_functional_status_initial'] + ' generic-is-hide-' + structure['child'][e.key]['hide_initial'] : structure['child'][e.key]['class_custom'] + ' parentElement generic-form-field-' + structure['child'][e.key]['form_field']"
        [ngStyle]="structure['child'][e.key]?.['styleParsed']?.['style']"
        [id]="structure['child'][e.key]['id_functional_area']" [matTooltip]="structure['child'][e.key]['tooltip']"
        (click)="editOption(structure['child'][e.key]); structure['child'][e.key]['creatingMode'] ? $event.stopPropagation() : ''">

        <span *ngIf="this.authService.checkUserIsDeveloping == 1 && !structure['creatingMode'] && this.genericService.devModeGeneric && ((structure['child'][e.key] && conditionsClientService.evaluateIfsParams(structure, e.key, this.idTable, this.idRow, this.param) && 
        (structure['child'][e.key]['creatingMode'] || structure['child'][e.key]['id_functional_type'] != 11 || structure['child'][e.key]['id_functional_type'] != 91 || (conditionsClientService.checkChildActiveHideIntialOnly(structure['child'][e.key]) && conditionsClientService.checkChildActive(structure['child'][e.key], false, false))) && 
        structure['child'][e.key]['id_functional_status_general'] !== 2) || structure['child'][e.key]['showSub'])"
          style="position: relative;">
          <mat-icon [cdkCopyToClipboard]="structure['child'][e.key]['id_functional_area']" class="debbugingIdsIcon"
            (mouseover)="hovered = structure['child'][e.key]['id_functional_area']" (mouseout)="hovered = -1"
            (click)="this.genericService.consoleLogFA(structure['child'][e.key]); $event.stopPropagation()">info</mat-icon>
          <span
            [ngStyle]="hovered == structure['child'][e.key]['id_functional_area'] ? {'display': 'block'} : {'display': 'none'}"
            class="debbugingIds">idFA: {{structure['child'][e.key]['id_functional_area']}}</span>
        </span>

        <span *ngIf="this.genericService.editingModeByCompany && (structure['child'][e.key]['child'] === undefined || structure['child'][e.key]['child'].length == 0 || structure['child'][e.key]['form_field'] == 1) && structure['child'][e.key]['id_functional_status_general'] != 5" style="position: relative;">
          <mat-icon class="debbugingIdsIcon isRemovingUser" *ngIf="structure['child'][e.key]['deletedByIdCompany']"
            (click)="this.genericService.removeIdFaByCompany(structure['child'][e.key]['id_functional_area'], false); $event.stopPropagation()">visibility_off</mat-icon>
            <mat-icon class="debbugingIdsIcon isRemovedUser" *ngIf="!structure['child'][e.key]['deletedByIdCompany']"
            (click)="this.genericService.removeIdFaByCompany(structure['child'][e.key]['id_functional_area']); $event.stopPropagation()">visibility</mat-icon>
        </span>

        <span *ngIf="this.genericService.editingModeByUser && (structure['child'][e.key]['child'] === undefined || structure['child'][e.key]['child'].length == 0 || structure['child'][e.key]['form_field'] == 1) && structure['child'][e.key]['id_functional_status_general'] != 5 && structure['child'][e.key]['id_functional_type'] !== 5" style="position: relative;">
          <mat-icon class="debbugingIdsIcon isRemovingUser" *ngIf="structure['child'][e.key]['deletedByIdUser']"
            (click)="this.genericService.removeIdFaByUser(structure['child'][e.key]['id_functional_area'], false); $event.stopPropagation()">visibility_off</mat-icon>
            <mat-icon class="debbugingIdsIcon isRemovedUser" *ngIf="!structure['child'][e.key]['deletedByIdUser']"
            (click)="this.genericService.removeIdFaByUser(structure['child'][e.key]['id_functional_area']); $event.stopPropagation()">visibility</mat-icon>
        </span>

        <!--  ******************************    EMPIEZAN LOS COMPONENTES GENERIC   ***********************************  -->
        <ng-container>
          <span *ngIf="structure['child'][e.key]['creatingMode'] && structure['child'][e.key]['form_field'] != 1"
            [ngClass]="{'creating-mode-buttons': true, 'activeItem': genericCreatorService.actualSelectedElement?.idTmp === structure['child'][e.key].idTmp}">
            <span class="generic-buttons-blue">
              <button mat-raised-button (click)="genericCreatorService.editOption(structure['child'][e.key])"
                matTooltip="Edit">
                <i class="material-icons">edit</i>
              </button>
            </span>
            <span class="generic-buttons-red">
              <button mat-raised-button (click)="genericCreatorService.deleteElement(structure['child'][e.key])" matTooltip="Delete">
                <i class="material-icons">delete</i>
              </button>
            </span>
            <span class="generic-buttons-black">
              <button mat-raised-button (click)="genericCreatorService.copyElement(structure['child'][e.key])"
                matTooltip="Copy">
                <i class="material-icons">content_copy</i>
              </button>
            </span>
            <span style="width: 42px;" *ngIf="structure['child'].length > 1">
              <span fxFlex="100" fxLayout="row wrap">
                <span class="generic-buttons-black" fxFlex="100" fxLayout="row" *ngIf="i !== 0">
                  <button fxFlex="100"
                    style="height: 13px; line-height: 13px !important; margin-bottom: 0.5px !important;"
                    matTooltip="Move up" matTooltipPosition="above" mat-raised-button
                    (click)="genericCreatorService.moveElements(genericCreatorService.arrayElementsGenericCreator, structure['id_functional_area'], structure['child'][e.key], 'up')">
                    <i style="line-height: 13px;" class="material-icons">keyboard_arrow_up</i>
                  </button>
                </span>
                <span class="generic-buttons-black" fxFlex="100" fxLayout="row"
                  *ngIf="i !== structure['child'].length - 1">
                  <button fxFlex="100"
                    style="height: 13px; line-height: 13px !important; margin-bottom: 0.5px !important;"
                    mat-raised-button matTooltip="Move down" matTooltipPosition="below"
                    (click)="genericCreatorService.moveElements(genericCreatorService.arrayElementsGenericCreator, structure['id_functional_area'], structure['child'][e.key], 'down')">
                    <i style="line-height: 13px;" class="material-icons">keyboard_arrow_down</i>
                  </button>
                </span>
              </span>
            </span>
          </span>

          <ng-container *ngIf="this.genericService.getServiceLanguage(structure['child'][e.key])">
            <!-- PARA CALCULAR LAS VARIABLES DE AUTHSERVICE-->
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 3">
            <ng-container *ngIf="!structure['child'][e.key]['creatingMode'] && this.genericService.tableData[structure['child'][e.key]['id_functional_area']]">
              <app-table class="component-class" [structure]="structure['child']" [index]="e.key"></app-table>
            </ng-container>
            <ng-container *ngIf="structure['child'][e.key]['creatingMode'] && !structure['child'][e.key]['showSubCreator']">
              <app-table-generic-creator class="component-class" [structure]="structure['child']" [index]="e.key"></app-table-generic-creator>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 5 && !structure['child'][e.key]['readMode']">
            <app-button class="component-class" [structure]="structure['child']" [index]="e.key" [param]="param">
            </app-button>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 6">
            <app-input class="component-class" [structure]="structure['child']" [index]="e.key"></app-input>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 8">
            <app-tab [structure]="structure['child']" [index]="e.key"
              [ngClass]="conditionsClientService.checkChildActive(structure['child'][e.key], false, false) ? 'component-class' : 'displayNone component-class'"></app-tab>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 10">
            <app-expansion [structure]="structure['child']" [index]="e.key" [param]="param"
              [ngClass]="conditionsClientService.checkChildActive(structure['child'][e.key], false, false) ? 'component-class' : 'displayNone component-class'"></app-expansion>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 12 && this.genericService.checkSelectionHasResults(structure['child'][e.key])">
            <app-selection class="component-class" [structure]="structure['child']" [index]="e.key"></app-selection>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 13 && conditionsClientService.checkChildActive(structure['child'][e.key], false, false)">
            <app-menu style="display: flex; flex-flow: column; overflow: hidden !important;" class="component-class"
              [structure]="structure['child']" [index]="e.key" [label]="structure['child'][e.key]['label']"
              [param]="param"></app-menu>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 14">
            <app-graphs class="component-class" [structure]="structure['child']" [index]="e.key"></app-graphs>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 15">
            <app-checkbox class="component-class" [structure]="structure['child']" [index]="e.key"></app-checkbox>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 16">
            <app-google-maps class="component-class" [structure]="structure['child']" [index]="e.key" [param]="param">
            </app-google-maps>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 11 && (structure['child'][e.key]['creatingMode'] || structure['child'][e.key]['child'])">
            <app-form class="component-class" [structure]="structure['child'][e.key]"
              [idQuery]="structure['child'][e.key]['id_query']" #child>
            </app-form>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 89">
            <app-divider class="component-class"></app-divider>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 91 || structure['child'][e.key]['id_functional_type'] === 141">
            <ng-container *ngIf="structure['child'][e.key]['tmp_query']">
              <app-box class="component-class" [structure]="structure['child'][e.key]"></app-box>
            </ng-container>
            <ng-container *ngIf="!structure['child'][e.key]['tmp_query']">
              <app-box class="component-class" [structure]="structure['child'][e.key]"></app-box>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 92">
            <app-sidenav class="component-class" [structure]="structure['child'][e.key]">
            </app-sidenav>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 96 && conditionsClientService.checkChildActive(structure['child'][e.key])">
            <app-stepper class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-stepper>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 98 && structure['child'][e.key]['child']">
            <app-bottom-sheet class="component-class" [structure]="structure['child'][e.key]['child']">
            </app-bottom-sheet>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 99">
            <app-list [structure]="structure['child']" [index]="e.key" [param]="param"
              [ngClass]="conditionsClientService.checkChildActive(structure['child'][e.key], false, false) ? 'component-class' : 'displayNone component-class'">
            </app-list>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] == 100">
            <app-card class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-card>
          </ng-container>

          <ng-container
            *ngIf="structure['child'][e.key]['id_functional_type'] === 101 || structure['child'][e.key]['id_functional_type'] === 137 || structure['child'][e.key]['id_functional_type'] === 138">
            <app-text style="display: block" class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-text>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 102">
            <app-youtube class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-youtube>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 106">
            <app-container-defer class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-container-defer>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 113">
            <app-file-upload class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-file-upload>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 117">
            <app-carousel-generic [structure]="structure['child']" [index]="e.key" [param]="param"
              [ngClass]="conditionsClientService.checkChildActive(structure['child'][e.key]) ? 'component-class' : 'displayNone component-class'">
            </app-carousel-generic>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 118">
            <app-canva class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-canva>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 121">
            <app-location class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-location>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 124">
            <app-traffic-light class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-traffic-light>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 146">
            <app-sign class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-sign>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 153">
            <app-calendar class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-calendar>
          </ng-container>
        </ng-container>
        <!--  ******************************    TERMINAN LOS COMPONENTES GENERIC   ***********************************  -->

        <!--  ******************************    EMPIEZAN LOS COMPONENTES CUSTOMS   ***********************************  -->
        <ng-container>
          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 17">
            <app-login class="component-class"></app-login>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 18">
            <app-login-screen class="component-class"></app-login-screen>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 19">
            <app-agenda-custom class="component-class" [struct]="structure['child']" [index]="e.key"
              [structure]="structure['child'][e.key]['child']"></app-agenda-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 41">
            <dynamic-report class="component-class"></dynamic-report>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 45">
            <app-community class="component-class" [structure]="structure['child'][e.key]['child']"></app-community>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 52">
            <app-chat class="component-class"></app-chat>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 79">
            <app-search-portals class="component-class"></app-search-portals>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 108">
            <app-notifications-custom #notisCustom class="component-class" [structure]="e"></app-notifications-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 119">
            <ego class="component-class"></ego>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 120">
            <app-tools-custom class="component-class" [structure]="e"></app-tools-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 123">
            <privacy-dialog class="component-class"></privacy-dialog>
          </ng-container>
          
          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 126">
            <app-flat-images-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-flat-images-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 127">
            <app-events-conditions-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-events-conditions-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 128">
            <app-shopping-cart class="component-class" [structure]="structure['child']" [index]="e.key" [param]="param">
            </app-shopping-cart>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 129">
            <app-buy-movins-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-buy-movins-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 130">
            <app-reports-results-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-reports-results-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 131">
            <app-events-template-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-events-template-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 132">
            <app-landing-social-media class="component-class" [structure]="structure['child']" [index]="e.key"
              [from]="1" [param]="param"></app-landing-social-media>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 133">
            <app-landing-social-media class="component-class" [structure]="structure['child']" [index]="e.key"
              [from]="2" [param]="param"></app-landing-social-media>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 134">
            <app-landing-social-media class="component-class" [structure]="structure['child']" [index]="e.key"
              [from]="3" [param]="param"></app-landing-social-media>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 135">
            <app-landing-social-media class="component-class" [structure]="structure['child']" [index]="e.key"
              [from]="4" [param]="param"></app-landing-social-media>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 136">
            <app-generic-creator-custom class="component-class" [structure]="structure['child']" [index]="e.key" [isStatic]="false">
            </app-generic-creator-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 139">
            <app-journey-creator-custom class="component-class" [structure]="structure['child']" [index]="e.key">
            </app-journey-creator-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 142">
            <app-messages-template-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-messages-template-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 144">
            <app-restaurant-pedido-actual-carta-custom class="component-class" [structure]="structure['child']"
              [index]="e.key" [param]="param"></app-restaurant-pedido-actual-carta-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 145">
            <app-paycomet-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-paycomet-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 147">
            <app-redsys-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-redsys-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 148">
            <app-paypal-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-paypal-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 149">
            <app-stripe-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-stripe-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 150">
            <app-ezpays-custom class="component-class" [structure]="structure['child']" [index]="e.key"
              [param]="param"></app-ezpays-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 151 || structure['child'][e.key]['id_functional_type'] === 152 || structure['child'][e.key]['id_functional_type'] === 154">
            <app-restaurant-pedidos-mesas-custom class="component-class" [structure]="structure['child']" [index]="e.key" [param]="param"></app-restaurant-pedidos-mesas-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 155">
            <app-generic-creator-custom class="component-class" [structure]="structure['child']" [index]="e.key" [isStatic]="true">
            </app-generic-creator-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 156">
            <app-db-schema-custom class="component-class" [structure]="structure['child']" [index]="e.key" [param]="param">
            </app-db-schema-custom>
          </ng-container>

          <ng-container *ngIf="structure['child'][e.key]['id_functional_type'] === 157">
            <app-code-file class="component-class" [structure]="structure['child']">
            </app-code-file>
          </ng-container>

        </ng-container>

        <!--  ******************************    TERMINAN LOS COMPONENTES CUSTOMS   ***********************************  -->
        <span class="no-mkt-permissions" *ngIf="structure['child'][e.key]['id_functional_status_mkt'] != 1">TEXTO NO
          DISPONIBLE EN FUNCIÓN DEL FUNCTIONAL_TYPE Y DE PORQUE NO ESTA DISPONIBLE Y EL IDIOMA</span>
      </span>

      <span
        *ngIf="structure['child'][e.key] && structure['child'][e.key]['showSub'] !== undefined && structure['child'][e.key]['showSub']"
        [id]="structure['child'][e.key]['id_functional_area']"
        [fxFlex]="structure['child'][e.key]['width_general'] != 0 ? structure['child'][e.key]['width_general'] : 100"
        [fxFlex.xs]="structure['child'][e.key]['width_xs'] != 0 ? structure['child'][e.key]['width_xs'] : structure['child'][e.key]['width_general']"
        [fxFlex.sm]="structure['child'][e.key]['width_s'] != 0 ? structure['child'][e.key]['width_s'] : structure['child'][e.key]['width_general']"
        [fxFlex.md]="structure['child'][e.key]['width_m'] != 0 ? structure['child'][e.key]['width_m'] : structure['child'][e.key]['width_general']"
        [fxFlex.lg]="structure['child'][e.key]['width_l'] != 0 ? structure['child'][e.key]['width_l'] : structure['child'][e.key]['width_general']"
        [fxFlex.xl]="structure['child'][e.key]['width_xl'] != 0 ? structure['child'][e.key]['width_xl'] : structure['child'][e.key]['width_general']"
        style="justify-content: center; display: flex; background: #f2f2f2; border-radius: 20px; align-items: center;">
        <mat-spinner></mat-spinner>
      </span>

    </ng-container>
  </span>
  <span class="generic-buttons-blue generic-creator-add" *ngIf="structure['creatingMode']">
    <button mat-raised-button (click)="openGeneralCreatorPopup(); $event.stopPropagation()" matTooltip="Add">
      <i class="material-icons">add</i>
    </button>
    <button mat-raised-button (click)="genericCreatorService.pasteElement(structure['idTmp'])" matTooltip="Paste"
      *ngIf="genericCreatorService.copiedElement && !genericCreatorService.isDSB(genericCreatorService.copiedElement)">
      <i class="material-icons">content_paste</i>
    </button>
  </span>
</span>