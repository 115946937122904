import { Injectable } from '@angular/core';

// Serivce
import { RoutingService } from "../routing/routing.service";
import { EndpointService} from "../generic/endpoint.service";

// Material
import {MatDialog} from "@angular/material/dialog";

// Component
import {AuthService} from "../auth/auth.service";
import {GenericService} from "../generic/generic.service";
import {MessageDialogComponent} from "../../utils/message-dialog/message-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {catchError, map} from "rxjs/operators";
import {HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {of} from "rxjs";
import {Values} from "../../../values/values";
import {ResetPasswordComponent} from "../../login/reset-password/reset-password.component";

@Injectable({
  providedIn: 'root'
})
export class UserFunctionsService {

  public dialogRef = null;

  constructor(
    private routingService : RoutingService,
    public dialog : MatDialog,
    public snackBar: MatSnackBar,
    private endpointService: EndpointService,
    private genericService: GenericService,
    private authService: AuthService) { }

  getFunction(structure, param = null) {

    console.log(structure);
    console.log(param);

    let id = structure['id_function'];

    let value;


    //profile.component
    switch (id) {

      case 250:
        this.enableDisableFromControls(param);
        break;

      case 251:
        this.optionSelectedGestor(param);
        break;
      case 252:
        this.optionSelectedProfile(param)
        break;
      case 253:
        value = this.checkInitDate(param)
        break;
      case 254:
        value = this.saveProfile(param);
        break;
      case 255:
        this.deleteImage(param);
        break;
      case 256:
        this.uploadFile(param);
        break;
      case 257:
        this.uploadFiles(param);
        break;
      case 258:
        this.addImage(param);
        break;
      case 259:
        this.timeChangedStartAttending(param);
        break;
      case 260:
        this.timeChangedEndAttending(param);
        break;
      case 261:
        value = this.checkTime(param);
        break;
      case 262:
        this.setPassword(param);
        break;
      case 263:
        this.adaptDate(param);
        break;
      case 264:
        value = this.dateToStringDBDate(param);
        break;
      case 265:
        this.addVisitAttending(param);
        break;
      case 266:
        this.deleteVista(param);
        break;
      case 267:
        this.vincularGoogle();
        console.log("hola");
        //this.finishFunction(param);
        break;
      case 268:
        this.desvincularGoogle();
        break;

    }

    return value;
  }

  public enableDisableFromControls(param){

    if (param.bool){
      //this.firstFormGroup.get('firstCtrlProfile').enable();
      //this.firstFormGroup.get('firstCtrlGestor').enable();
      param.that.emailFormControl.enable();
      param.that.emailMatchingFormControl.enable();
    } else {
      //this.firstFormGroup.get('firstCtrlProfile').disable();
      //this.firstFormGroup.get('firstCtrlGestor').disable();
      //param.that.emailFormControl.disable();
      param.that.emailFormControl.disable();
      param.that.emailMatchingFormControl.disable();
    }
  }

  public optionSelectedGestor(param){
    param.that.userData.gestor_asignado = param.$event.option.value.id;
  }

  public optionSelectedProfile(param){
    param.that.userData.perfil = param.$event.option.value.id;
  }

  public checkInitDate(param){

    for(let i = 0; i < param.that.scheduleArray.length; i++){
      if (param.that.scheduleArray[i].fecha_inicio === null){
        if (param.that.scheduleArray[i].tipo === 0){
          this.snackBar.open('Es necesario introducir fecha de inicio en HORARIOS VISITAS PORTAL 4DMOVIN PRESENCIALES', 'X', {
            duration: 4000,
            panelClass: ['red-snackbar']
          });
        } else if (param.that.scheduleArray[i].tipo === 1) {
          this.snackBar.open('Es necesario introducir fecha de inicio en HORARIOS VISITAS PORTAL 4DMOVIN STREAMING', 'X', {
            duration: 4000,
            panelClass: ['red-snackbar']
          });
        }
        return false;
      }
    }
    return true;
  }

  public saveProfile(param) {

    if (!param.that.checkInitDate()) return;

    if (!param.that.emailFormControl.value){
      param.that.ckeckerField.nativeElement.focus();
      param.that.ckeckerField.nativeElement.blur();
      //this.ckeckerFieldEmailMatching.nativeElement.focus();
      //this.ckeckerFieldEmailMatching.nativeElement.blur();
      this.snackBar.open('Necesario introducir emails', 'X', {
        duration: 6000,
        panelClass: ['red-snackbar']
      });
      return;
    } else if (!param.that.checkTime(param.that.inmoData.hora_inicio_presencial, param.that.inmoData.hora_fin_presencial, true,false)){
      this.snackBar.open('Franja horaria de presencial incorrecta', 'X', {
        duration: 3000, panelClass: ['red-snackbar']
      });
      return;
    } else if (!param.that.checkTime(param.that.inmoData.hora_inicio_streaming, param.that.inmoData.hora_fin_streaming, false,false)){
      this.snackBar.open('Franja horaria de streaming incorrecta', 'X', {
        duration: 3000, panelClass: ['red-snackbar']
      });
      return;
    }
    else {
      for (let n = 0; n < param.that.scheduleArray.length; n++){
        let ty = param.that.scheduleArray[n].tipo === 1 ? false : true;
        if (!param.that.checkTime(param.that.scheduleArray[n].hora_inicio, param.that.scheduleArray[n].hora_fin, ty,false)){
          if (param.that.scheduleArray[n].tipo === 1){
            this.snackBar.open('Franja horaria de streaming incorrecta', 'X', {
              duration: 3000, panelClass: ['red-snackbar']
            });
          } else if (param.that.scheduleArray[n].tipo === 0){
            this.snackBar.open('Franja horaria de presencial incorrecta', 'X', {
              duration: 3000, panelClass: ['red-snackbar']
            });
          }
          return;
        }
      }
      this.endpointService.updateProfileUser(param.that.idUser, param.that.userData).subscribe(
        data => {
          if (data['errorCode'] === 0){
            param.that.editable = false;
            param.that.enableDisableFromControls(false);
            this.snackBar.open('Guardado', 'X', {
              duration: 6000,
              panelClass: ['green-snackbar']
            });
          } else {
            this.snackBar.open('Error', 'X', {
              duration: 6000,
              panelClass: ['red-snackbar']
            });
          }
        }
      );
    }
    //afagir els dies de la setmana en format json o string
    let daysJSON = '';
    for (let j = 0; j < param.that.scheduleArray.length; j++){
      daysJSON = '';
      if (param.that.scheduleArray[j].dias_semana !== null && (param.that.scheduleArray[j].dias_semana).length > 0){
        daysJSON += '{';
        for (let i = 0; i < (param.that.scheduleArray[j].dias_semana).length; i++){

          daysJSON += '"' +param.that.scheduleArray[j].dias_semana[i] + '": true,';
        }
        daysJSON = daysJSON.substring(0, daysJSON.length - 1);
        daysJSON += '}';
      } else {
        daysJSON = '{"Lunes": true, "Martes": true, "Miercoles": true, "Jueves": true, "Viernes": true, "Sábado": true, "Domingo": true}';
      }
      param.that.scheduleArray[j].dias_semana = daysJSON;
    }
    //

    this.endpointService.insertScheduleView(param.that.scheduleArray, param.that.idUser).subscribe(data => {
      param.that.scheduleArray = param.that.scheduleArray.map( (data, index) => {
        if (data.dias_semana !== null){
          let array = [];
          let obj = JSON.parse(data.dias_semana);
          Object.entries(obj).forEach(
            ([key, value]) => array.push(key)
          );
          data.dias_semana = [];
          data.dias_semana =  array;
          return data;
        }
      });
    });

  }

  public deleteImage(param) {
    param.that.userImageInView = null;
    param.that.userImageNameComponent = null;
    let split = param.that.profilePicture.split("/");

    this.endpointService.deleteImageProfile(split[split.length - 1], param.that.userData.id, param.that.idInmobiliaria).subscribe(data => {
      if (data['errorCode'] === 0){
        param.that.profilePicture = null;
      }
    });
  }

  uploadFile(param) {
    const formData = new FormData();
    formData.append('file', param.file.data);
    param.file.inProgress = true;
    this.endpointService.uploadUserProfilePicture(formData, param.that.userData.id, param.that.userData.foto).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            param.file.progress = Math.round(event.loaded + 0.000000001 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
        //this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)

      }),
      catchError((error: HttpErrorResponse) => {
        param.file.inProgress = false;
        return of(`${param.file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        const subs = event.body.response.split('vhosts/');
        const subsubs = subs[1].split('movin.cloud/');
        //const subsubs2 = subsubs[2].split('erp/');

        param.that.profilePicture = Values.ROOT_URL + '/' + subsubs[2];
      }
    });
  }

  private uploadFiles(param) {

    param.that.fileUpload.nativeElement.value = '';

    this.uploadFile(param.that.files[param.that.files.length - 1]);

    /*this.files.forEach(file => {
      this.uploadFile(file);
    });*/
  }

  addImage(param) {

    const fileUpload = param.that.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++)
      {
        const file = fileUpload.files[index];
        param.that.files.push({ data: file, inProgress: false, progress: 0});
      }
      param.that.uploadFiles();
    };
    fileUpload.click();
  }

  timeChangedStartAttending(event){

    //param.that.inmoData.hora_inicio_presencial = param.event;
  }

  timeChangedEndAttending(param){

    param.that.checkTime(param.event.hora_inicio, param.event.hora_fin, true,true);
  }

  public checkTime(param){

    const initDate1 = param.init.split(':');  //separar les hores dels minuts
    const initDate2 = param.end.split(':');  //separar les hores dels minuts

    const minsTotalInit = (parseInt(initDate1[0],10)*60) + parseInt(initDate1[1],10); //convertir les hores a minuts de hora_inicio
    const minsTotalFin = (parseInt(initDate2[0],10)*60) + parseInt(initDate2[1],10); //convertir les hores a minuts de hora_end

    let minsTotal2 = 0;

    if (param.attending){
      let half1 = param.that.attendentHalfHour ? 1 : 0; //si hi han 30 minuts o no
      minsTotal2 = (param.that.attendentHours*60) + (half1*30);  //passar les hores a minuts i després sumar-li els minuts extres (30 mins si en té)
    }else {
      let half1 = param.that.streamingHalfHour ? 1 : 0; //si hi han 30 minuts o no
      minsTotal2 = (param.that.streamingHours*60) + (half1*30);  //passar les hores a minuts i després sumar-li els minuts extres (30 mins si en té)
    }

    const tempsReal = minsTotalFin - minsTotalInit; //el real

    if (tempsReal >= 0){
      if (minsTotal2 > tempsReal){
        if (param.showSnackBars)
          this.snackBar.open('El tiempo introducido no es suficiente', 'X', {
            duration: 2000, panelClass: ['red-snackbar']
          });
        return false;
      }
    } else {
      if (param.showSnackBars)
        this.snackBar.open('és necessario introducir una hora más alta que la de inicio', 'X', {
          duration: 2000, panelClass: ['red-snackbar']
        });
      return false;
    }
    return true;
  }

  public setPassword(param, email= null): void {
    let data = null;
    if(email != null) data = email;
    else data = param.that.userData.email;

    /*const dialogRef = this.dialog.open(SetPasswordComponent, {
      width: 'auto',
      data: {user: this.userData.email, type : typeNum}
    });

    dialogRef.afterClosed().subscribe(data => {});*/
    console.log(param);

    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: 'auto',
      data: {email: data,  edit: false}
    });

    dialogRef.afterClosed().subscribe(data => {});
  }

  public adaptDate(param){
    let correctDate = param.that.dateToStringDBDate(param.value);

    switch (param.picker){
      case 1:
        param.that.scheduleArray[param.i].fecha_inicio = correctDate; break;
      case 2:
        param.that.scheduleArray[param.i].fecha_fin = correctDate; break;
    }
  }

  public dateToStringDBDate(param): string {

    const dt = new Date(param.date);
    return (
      dt.getFullYear()
      + '-' +  ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1)
      + '-' +  (dt.getDate() < 10 ? '0' : '') + dt.getDate());
  }

  public addVisitAttending(param){
    param.that.stramingEmpty = param.that.scheduleArray.filter(t => t.tipo === 0).length <= 0;
    let currentDate = new Date();
    let initDate = this.dateToStringDBDate(currentDate);
    let object = {id_comercial : param.that.idUser, dias_semana : [], fecha_inicio : initDate, fecha_fin : null,  hora_inicio : "09:00",
      hora_fin : "19:00", tipo : 0};
    param.that.scheduleArray.push(object);
  }

  public deleteVista(param){

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: '¿Seguro que quiere eliminar esta visita?',
        labelButton1: 'Cancelar',
        labelButton2: 'Eliminar'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {
        param.that.scheduleArray = param.that.scheduleArray.filter(t => t !== param.id);
        param.that.stramingEmpty = param.that.scheduleArray.filter(t => t.tipo === 1).length <= 0;
        if (param.that.scheduleArray.length <= 0){
          param.that.stramingEmpty = false;
        }
      }
    });
  }

  vincularGoogle(){
    console.log(this.authService.userId);
    this.endpointService.vincularGoogleCalendar(this.authService.userId, this.authService.empresaId).subscribe(data=>{
      if(data['errorCode'] == 1) window.open(data['response'],"_self");
    });
  }

  desvincularGoogle(){
    this.endpointService.desvincularGoogleCalendar(this.authService.userId).subscribe(data=>{
      if(data['errorCode'] == 0) {
        this.authService.googleToken = null;
        this.snackBar.open('Desvinculado correctamente', 'X', {
          duration: 6000,
          panelClass: ['green-snackbar']
        });
        this.genericService.refreshStructure();
      }
    });
  }

  vincularMicrosoft(){
    console.log(this.authService.userId);
    this.endpointService.vincularMicrosoftCalendar(this.authService.userId, this.authService.empresaId).subscribe(data=>{
      if(data['errorCode'] == 1) window.open(data['response'],"_self");
    });
  }

  desvincularMicrosoft(){
    this.endpointService.desvincularMicrosoftCalendar(this.authService.userId).subscribe(data=>{
      if(data['errorCode'] == 0) {
        this.authService.microsoftToken = null;
        this.snackBar.open('Desvinculado correctamente', 'X', {
          duration: 6000,
          panelClass: ['green-snackbar']
        });
        this.genericService.refreshStructure();
      }
    });
  }
}
